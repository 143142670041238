import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { AppointmentDetailsResponse, getAppointmentDetails } from 'services';

export type State = ApiState<AppointmentDetailsResponse, SerializedError>;

export const fetchAppointmentDetails = createAsyncThunk(
  'get/appointmentDetails',
  async (configPayLoad: Record<string, string>) => await getAppointmentDetails({ ...configPayLoad })
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const appointmentDetailsSlice = createSlice({
  name: 'appointmentDetails',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAppointmentDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchAppointmentDetails.fulfilled, (state, action: PayloadAction<AppointmentDetailsResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchAppointmentDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const appointmentStateSelector_ = (state: { appointmentDetails: State }): State => state.appointmentDetails;

export const appointmentDetails_ = createSelector(appointmentStateSelector_, (state) => state.data);

export default appointmentDetailsSlice.reducer;
