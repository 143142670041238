import { ConsentFormElement, EcheckinEHRForm, EcheckinFormIO, PatientQuestionnaireAnswerType } from 'models';
import { ConsentForm } from 'services/consent-form/consent-form.api';
import { KIOSK_GET_APPOINTMENTS, KIOSK_SAVE_APPOINTMENTS } from 'services/constants';
import { tokenAuthApiInstance } from 'utils/auth';

export type KioskAppointments = {
  PatientID: string;
  PracticeCalendarID: string;
  FromDate: string;
  ToDate: string;
  ProviderID: string;
  PatientStatus: string;
  PatientName: string;
  ProviderName: string;
  TypeOfVisit: string;
  PatientPreferredContact: string;
  PatientEmail: string;
  LocationName: string;
  LocationID: string;
  PracticeID: string;
  CheckInTime: string;
  CheckOutTime: string;
};

export type KioskAppointmentsDetails = {
  todaysAppointments: KioskAppointments[];
  otherAppointments: KioskAppointments[];
  checked_InAppointments: KioskAppointments[];
};

export type ECheckInPatientQuestionnaires = {
  PatientQuestionnaireTypes: Array<EcheckinEHRForm | EcheckinFormIO>;
  QuestionnaireAnswers: Array<PatientQuestionnaireAnswerType>;
  ConsentForms: Array<ConsentForm>;
  ConsentFormElements: Array<ConsentFormElement>;
};

export type KioskAppointmentsResponse = KioskAppointmentsDetails;

export const getKioskAppointments = async (patientId: string): Promise<KioskAppointmentsResponse> => {
  return await (await tokenAuthApiInstance.authAxios([patientId]).get(KIOSK_GET_APPOINTMENTS)).data;
};

export const SaveKioskAppointmentCheckIn = async (
  isFormIO: boolean,
  queryParams: Record<string, string>,
  payload: ECheckInPatientQuestionnaires
): Promise<boolean> => {
  const { practiceCalendarID, userID } = queryParams;
  const response = await tokenAuthApiInstance
    .authAxios([practiceCalendarID, userID])
    .post(KIOSK_SAVE_APPOINTMENTS(isFormIO), payload);
  if (response?.status == 200) {
    return true;
  } else return false;
};
