import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'reducers';

export type proxyRequestDocumentsType = {
  documents: string[];
};

export const initialState = {
  documents: [],
} as proxyRequestDocumentsType;

//https://redux-toolkit.js.org/api/createSlice
const proxyDocumentsSlice = createSlice({
  name: 'proxyRequestDocuments',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    setDocuments: (state, action: PayloadAction<string[]>) => {
      state.documents = action.payload as string[];
    },
    clearDocuments: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: {
  proxyRequestDocuments: proxyRequestDocumentsType;
}): proxyRequestDocumentsType => state.proxyRequestDocuments;

export const proxyDocuments_ = createSelector(stateSelector_, (state) => state.documents);

export const { setDocuments, clearDocuments } = proxyDocumentsSlice.actions;

export default proxyDocumentsSlice.reducer;
