import { tokenAuthApiInstance } from 'utils/auth';

import {
  FUTURE_APPOINTMENTS_QUESTIONNAIRES,
  PAST_APPOINTMENTS_QUESTIONNAIRES,
  PAST_APPOINTMENTS_QUESTIONNAIRES_COUNT_ENDPOINT,
} from '../constants';

export type QuestionnaireResponseType = {
  PatientID: string;
  PracticeCalendarID: string;
  FromDate: string;
  ToDate: string;
  ProviderID: string;
  PatientStatus: string;
  PatientName: string;
  ProviderName: string;
  TypeOfVisit: string;
  rowcount: number;
  QuestionnaireType: string;
  FormUrl: string;
  FormName: string;
  FormTitle: string;
  FormID: string;
  CompletedDate: string;
  PatientQuestionnaireID?: string;
  FormTemplateID?: string;
};

export type QuestionnaireResponse = QuestionnaireResponseType[];

export const getPastAppointmentQuestionnaires = async (
  Payload: Record<string, string>
): Promise<QuestionnaireResponse> => {
  const { rowsPerPage, noofMinutesForFutureAppointments } = Payload;
  const response = await tokenAuthApiInstance
    .authAxios([rowsPerPage, noofMinutesForFutureAppointments])
    .get(PAST_APPOINTMENTS_QUESTIONNAIRES);
  return response.data;
};

export const getFutureAppointmentQuestionnaires = async (
  Payload: Record<string, string>
): Promise<QuestionnaireResponse> => {
  const { rowsPerPage, noofMinutesForFutureAppointments } = Payload;
  const response = await tokenAuthApiInstance
    .authAxios([rowsPerPage, noofMinutesForFutureAppointments])
    .get(FUTURE_APPOINTMENTS_QUESTIONNAIRES);
  return response.data;
};

export const getPastAppointmentQuestionnairesCount = async (): Promise<number> => {
  const response = await tokenAuthApiInstance.authAxios().get(PAST_APPOINTMENTS_QUESTIONNAIRES_COUNT_ENDPOINT);
  return response.data;
};
