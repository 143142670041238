import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getScheduleLocations, ScheduleLocation } from 'services';

export type State = ApiState<ScheduleLocation[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchScheduleLocations = createAsyncThunk(
  'get/scheduleLocations',
  async () => await getScheduleLocations()
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const scheduleLocationsSlice = createSlice({
  name: 'scheduleLocations',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    unmountScheduleLocations: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScheduleLocations.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchScheduleLocations.fulfilled, (state, action: PayloadAction<ScheduleLocation[]>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchScheduleLocations.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { scheduleLocations: State }): State => state.scheduleLocations;

export const scheduleLocations_ = createSelector(stateSelector_, (state) => state.data);

export const scheduleLocationsIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const scheduleLocationsHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const { unmountScheduleLocations } = scheduleLocationsSlice.actions;

export default scheduleLocationsSlice.reducer;
