import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState, EcheckinPayload } from 'models';
import { postEcheckinOnComplete } from 'services';

import { EcheckinInitialState } from './eCheckinInitialState';

export type State = ApiState<Partial<EcheckinPayload>, SerializedError>;

export type EcheckinPayloadAction = {
  data: Partial<EcheckinPayload> & Record<string, unknown>;
};

export const postEcheckin = createAsyncThunk(
  'post/eCheckin',
  async (payload: Partial<EcheckinPayload> & Record<string, unknown>) => await postEcheckinOnComplete(payload)
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: EcheckinInitialState,
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const eCheckinPayloadSlice = createSlice({
  name: 'eCheckinPayload',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    resetEcheckinPayload: () => {
      return initialState;
    },
    updateEcheckinPayload: (state, action: PayloadAction<EcheckinPayloadAction>) => {
      state.data = { ...state.data, ...action.payload.data };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postEcheckin.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(postEcheckin.fulfilled, (state) => {
      state.isLoading = false;
      state.hasLoaded = true;
    });

    builder.addCase(postEcheckin.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { eCheckinPayload: State }): State => state.eCheckinPayload;

export const eCheckinPayload_ = createSelector(stateSelector_, (state) => {
  return state.data;
});

export const eCheckinSaveHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);
export const eCheckinSaveIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const { resetEcheckinPayload, updateEcheckinPayload } = eCheckinPayloadSlice.actions;
export default eCheckinPayloadSlice.reducer;
