import { tokenAuthApiInstance } from 'utils/auth';
import { Logger } from 'utils/logger';

import { PROXY_ACCESS_CONFIG } from './constants';

export type ProxyAccessConfigType = {
  criteriaToShowMenu: Array<BaseOnAgeItems>;
  proxyDefinitionText: Array<LanguageTextItems>;
};

export type BaseOnAgeItems = {
  basedOnAge: boolean;
  age: number;
  secondAge?: number | undefined;
  operand: string;
};

export type LanguageTextItems = {
  language?: string;
  locale: string;
  text: string;
};

export type ProxyRequestConfigType = {
  proxyDefinitionText: Array<LanguageTextItems>;
};

export type ProxyAccessConfigResponse = ProxyAccessConfigType;
export type ProxyRequestConfigResponse = ProxyRequestConfigType;

export const getProxyAccessConfig = async (): Promise<ProxyAccessConfigResponse> => {
  try {
    const response = await tokenAuthApiInstance.authAxios().get(PROXY_ACCESS_CONFIG);
    const { proxyAccessItems } = response.data;
    return proxyAccessItems;
  } catch (error) {
    Logger.error(error);
    throw error;
  }
};

// tempDef is coming from translation file.. in the future this will come from the admin portal config
export const getProxyRequestConfig = async (tempDef: string): Promise<ProxyRequestConfigResponse> => {
  try {
    // for future, get the text from the admin settings
    const proxyRequestItems = {
      proxyDefinitionText: [
        {
          language: 'English',
          locale: 'en',
          text: tempDef,
        },
        {
          language: 'Spanish',
          locale: 'es',
          text: tempDef,
        },
      ],
    };
    return proxyRequestItems;
  } catch (error) {
    Logger.error(error);
    throw error;
  }
};
