import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import _ from 'lodash';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { FormDetails, getFormDetailsForSingleOrMultipleForms } from 'services';

export type State = ApiState<Array<FormDetails> | undefined, SerializedError | number>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchFormDetailsForSingleOrMultipleForms = createAsyncThunk(
  'get/formDetails',
  async (formName: string | string[]) => await getFormDetailsForSingleOrMultipleForms(formName)
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const formDetailsSlice = createSlice({
  name: 'formDetails',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    clearFormStates: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFormDetailsForSingleOrMultipleForms.pending, (state) => {
      state.isLoading = true;
      state.hasLoaded = false;
    });

    builder.addCase(
      fetchFormDetailsForSingleOrMultipleForms.fulfilled,
      (state, action: PayloadAction<FormDetails | undefined>) => {
        state.isLoading = false;
        const { data } = state;
        const { payload } = action;
        if (state && data && payload) {
          if (Array.isArray(payload)) {
            state.data = payload;
          } else {
            const { formName }: { formName: string } = payload;
            if (!_.find(data, { formName: formName })) {
              data.push(payload);
            }
          }
        }
        state.hasLoaded = true;
        state.error = null;
      }
    );

    builder.addCase(fetchFormDetailsForSingleOrMultipleForms.rejected, (state) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.error = 404;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { formDetails: State }): State => state.formDetails;

export const formDetails_ = createSelector(stateSelector_, (state) => state.data);

export const formDetailsIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const formDetailsHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const formDetailsErrorCode_ = createSelector(stateSelector_, (state) => state.error);

export const { clearFormStates } = formDetailsSlice.actions;

export default formDetailsSlice.reducer;
