import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { AppointmentBookingSlotPayload, saveAppointmentBookingSlot } from 'services';

export type State = ApiState<AppointmentBookingSlotPayload, SerializedError>;

export type BookingSlotPayloadAction = {
  data: Partial<AppointmentBookingSlotPayload> & Record<string, unknown>;
};

export const appointmentSlotInitialState = {
  payload: {
    AppointmentBookingSlotID: '',
    SchResourceID: '',
    SchResourceName: '',
    TypeOfVisit: '',
    LocationID: '',
    LocationName: '',
    FromDate: '',
    ToDate: '',
    BookingStatus: '',
    IsDeleted: false,
  },
  userID: '',
};

export const saveBookingSlot = createAsyncThunk(
  'post/appointmentBookingSlots',
  async (payload: AppointmentBookingSlotPayload) => await saveAppointmentBookingSlot(payload)
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: appointmentSlotInitialState,
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const bookingSlotPayloadSlice = createSlice({
  name: 'bookingSlotPayload',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    resetBookingSlotPayload: () => {
      return initialState;
    },
    updatebookingSlotPayload: (state, action: PayloadAction<BookingSlotPayloadAction>) => {
      state.data = { ...state.data, ...action.payload.data };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(saveBookingSlot.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(saveBookingSlot.fulfilled, (state) => {
      state.isLoading = false;
      state.hasLoaded = true;
    });

    builder.addCase(saveBookingSlot.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { bookingSlotPayload: State }): State => state.bookingSlotPayload;

export const bookingSlotPayload_ = createSelector(stateSelector_, (state) => {
  return state.data;
});
export const bookingSlotSaveHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);
export const bookingSlotSaveIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const { resetBookingSlotPayload, updatebookingSlotPayload } = bookingSlotPayloadSlice.actions;
export default bookingSlotPayloadSlice.reducer;
