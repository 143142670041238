import { tokenAuthApiInstance } from 'utils/auth';

import { HEALTH_INFO_ENDPOINT, SAVE_HEALTH_INFO_ENDPOINT } from '../constants';

export type HealthInfo = {
  PatientHealthInfoID?: string;
  PatientID: string;
  UserID?: string;
  HealthInfo: string;
  InfoDate: string;
  ExternalLink?: string;
  Comments?: string;
  IsDeleted?: boolean;
  rowcount?: number;
  RowNumber?: number;
};

export type HealthInfoResponse = Array<HealthInfo>;

export const getHealthInfo = async (rowsPerPage: number): Promise<HealthInfoResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(HEALTH_INFO_ENDPOINT(rowsPerPage));
  return response.data;
};

export const saveHealthInfo = async (payload: HealthInfo): Promise<string> => {
  const response = await tokenAuthApiInstance.authAxios().post(SAVE_HEALTH_INFO_ENDPOINT, payload);
  return response.data;
};
