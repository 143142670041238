import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getkioskLogin, kioskPersonalDetails } from 'services/Kiosk/Kiosk.api';

export type State = ApiState<kioskPersonalDetails, SerializedError>;

export const fetchKioskLoginDetails = createAsyncThunk(
  'get/kioskLogin',
  async (configPayLoad: Record<string, string>) => await getkioskLogin({ ...configPayLoad })
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const kioskPersonalDetailsSlice = createSlice({
  name: 'kioskPersonalDetails',
  initialState,
  reducers: {
    unmountKioskLogin: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchKioskLoginDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchKioskLoginDetails.fulfilled, (state, action: PayloadAction<kioskPersonalDetails[]>) => {
      //if Data of Birth and Last Name is same for more than one patient and all of them have appiontments
      //then it will return all patient data as response
      //if response length is more than one we need to ask for First Name to find out the specific patient details
      //if response length is one then we are updating the kioskPersonalDetails redux with that response object

      if (action.payload?.length === 0) {
        state.error = 'contactFrontDesk';
      } else if (action.payload?.length > 1) {
        state.error = 'fistNameRequired';
      } else {
        state.isLoading = false;
        state.data = action.payload[0];
        state.hasLoaded = true;
        state.error = '';
      }
    });
    builder.addCase(fetchKioskLoginDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const kioskPersonalDetailsStateSelector_ = (state: { kioskPersonalDetails: State }): State =>
  state.kioskPersonalDetails;

export const kioskPersonalDetails_ = createSelector(kioskPersonalDetailsStateSelector_, (state) => state.data);

export const kioskPersonalDetailsIsLoading_ = createSelector(
  kioskPersonalDetailsStateSelector_,
  (state) => state.isLoading
);

export const kioskPersonalDetailsHasLoaded_ = createSelector(
  kioskPersonalDetailsStateSelector_,
  (state) => state.hasLoaded
);
export const { unmountKioskLogin } = kioskPersonalDetailsSlice.actions;
export const kioskPersonalDetailsError_ = createSelector(kioskPersonalDetailsStateSelector_, (state) => state.error);
export default kioskPersonalDetailsSlice.reducer;
