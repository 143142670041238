import { tokenAuthApiInstance } from 'utils/auth';

import { INSURANCE_ENDPOINT, insuranceWithPracticeCalendarIDEndPoint } from '../constants';

/* eslint-disable @typescript-eslint/no-explicit-any*/
export type InsuranceDetails = {
  Comments: string;
  GroupNo: string;
  InsuranceCardFront?: string;
  InsuranceCardBack?: string;
  InsAddress1: string;
  InsAddress2: string;
  InsCity: string;
  InsDOB: string;
  InsFirstName: string;
  InsGender: string;
  InsHomePhone: string;
  InsLastName: string;
  InsMiddleName: string;
  InsSSN: string;
  InsState: string;
  InsWorkPhone: string;
  InsZip: string;
  InsurancePlanID: string;
  InsurancePlanName?: string;
  InsuranceTypeID: string;
  InsuranceTypeName: string;
  PatInsID?: string;
  PatInsProfileID?: string;
  PatientAction: 'Add' | 'Edit' | 'Delete';
  PolicyNo: string;
  Position: number;
  Relationship: string;
};

export const getInsuranceDetails = async (): Promise<InsuranceDetails[]> => {
  const response = await tokenAuthApiInstance.authAxios().get(INSURANCE_ENDPOINT);
  return response.data;
};

export const getInsuranceDetailsWithPracticeCalendarID = async (
  practiceCalendarID: string
): Promise<InsuranceDetails[]> => {
  const response = await tokenAuthApiInstance
    .authAxios()
    .get(insuranceWithPracticeCalendarIDEndPoint(practiceCalendarID));
  return response.data;
};
