import { tokenAuthApiInstance } from 'utils/auth';

import { MEDICATION_CONFIG } from '../constants';

export type MedicationItemsConfig = {
  medicationItems: {
    showView: 'Inventory' | 'Non-inventory';
  };
};

export type MedicationConfigResponse = MedicationItemsConfig;

export const medicationConfig = async (): Promise<MedicationConfigResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(MEDICATION_CONFIG);
  return response.data;
};
