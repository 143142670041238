import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AddToHomeScreenState = { show: boolean; deferredAndroidEvent?: BeforeInstallPromptEvent };

export const initialState = {
  show: false,
  deferredAndroidEvent: undefined,
} as AddToHomeScreenState;

export const AddToHomeScreenSlice = createSlice({
  name: 'addToHomeScreen',
  initialState,
  reducers: {
    showInstructions: (state, action: PayloadAction<boolean>) => {
      if (state) {
        state.show = action.payload;
      }
    },
    clearAddToHomeScreenState: () => {
      return initialState;
    },
    storeInstallPrompt: (state, action: PayloadAction<Event>) => {
      if (state) {
        state.deferredAndroidEvent = action.payload as BeforeInstallPromptEvent;
      }
    },
  },
});

export const stateSelector_ = (state: { addToHomeScreen: AddToHomeScreenState }): AddToHomeScreenState =>
  state.addToHomeScreen;

export const showAddToHomeScreen_ = createSelector(stateSelector_, (state) => state.show);

export const addToHomeScreenEvent_ = createSelector(stateSelector_, (state) => state.deferredAndroidEvent);

export const { showInstructions, clearAddToHomeScreenState, storeInstallPrompt } = AddToHomeScreenSlice.actions;

export default AddToHomeScreenSlice.reducer;
