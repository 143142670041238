import './backdrop-progress.component.scss';

import { Backdrop, LinearProgress } from '@material-ui/core';
import React, { FunctionComponent, useRef } from 'react';

// const HEADER_HEIGHT = 75;

export type BackgroundProgressComponentProps = {
  open: boolean;
};

export const BackgroundProgressComponent: FunctionComponent<BackgroundProgressComponentProps> = ({ open = true }) => {
  const backdropProgress = useRef<HTMLDivElement | null>(null);

  return (
    <Backdrop className="backdrop-progress" open={open}>
      <LinearProgress ref={backdropProgress} className="linear-progress" />
    </Backdrop>
  );
};
