import { tokenAuthApiInstance } from 'utils/auth';

import { KIOSK_LOGIN } from '../constants';

export type kioskPersonalDetails = {
  PatientID: string;
  DateOfBirth: string;
  LastName: string;
  FirstName: string;
  Gender: string;
  MiddleName: string;
  EmailAddress: string;
  HomePhoneNumber: string;
  MobileNumber: string;
  PatientUserID: string;
};

export const getkioskLogin = async (Payload: Record<string, string>): Promise<kioskPersonalDetails[]> => {
  const { dob, lastName, firstName } = Payload;
  const response = await tokenAuthApiInstance
    .authAxios(
      [
        Buffer.from(dob).toString('base64'),
        Buffer.from(lastName).toString('base64'),
        Buffer.from(firstName).toString('base64'),
      ],
      'v0'
    )
    .get(KIOSK_LOGIN);
  return response.data;
};
