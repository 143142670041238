import * as winston from 'winston';
import { winstonAzureBlob } from 'winston-azure-blob';

import { AZURE_BLOB, HOST_ENV_KEY_STR } from './constants';
import { HostEnv } from './environment';

export const Logger = winston.createLogger({
  format: winston.format.combine(winston.format.timestamp(), winston.format.json()),
});

const logLevel = process.env.LOGGING_LEVEL || 'debug';

if (process.env.NODE_ENV === 'production') {
  let fileType = HOST_ENV_KEY_STR.LOCAL;
  if (HostEnv.isTest()) fileType = HOST_ENV_KEY_STR.TEST;
  if (HostEnv.isStage()) fileType = HOST_ENV_KEY_STR.STAGE;
  if (HostEnv.isProd()) fileType = HOST_ENV_KEY_STR.PROD;

  Logger.add(
    new winstonAzureBlob({
      account: {
        name: AZURE_BLOB.ACCOUNT_NAME,
        key: AZURE_BLOB.ACCOUNT_KEY,
      },
      containerName: AZURE_BLOB.CONTAINER_NAME,
      blobName: `cpx-ui-${fileType}.log`,
      level: logLevel,
      bufferLogSize: 1,
      syncTimeout: 0,
      rotatePeriod: 'YYYY-MM-DD',
      eol: '\n',
    })
  );
} else {
  Logger.add(
    new winston.transports.Console({
      level: logLevel,
    })
  );
}
