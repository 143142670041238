import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getReScheduleAppointmentDetailsByPhone, ReScheduleAppointmentDetailsResponse } from 'services';

export type State = ApiState<ReScheduleAppointmentDetailsResponse, SerializedError>;

export const fetchReScedAppointmentDetailsByPhone = createAsyncThunk(
  'get/reScheduledAppointments',
  async () => await getReScheduleAppointmentDetailsByPhone()
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const reSchedAppointmentDetailsByPhoneSlice = createSlice({
  name: 'reSchedAppointmentDetailsByPhone',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReScedAppointmentDetailsByPhone.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchReScedAppointmentDetailsByPhone.fulfilled,
      (state, action: PayloadAction<ReScheduleAppointmentDetailsResponse>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.hasLoaded = true;
      }
    );

    builder.addCase(fetchReScedAppointmentDetailsByPhone.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const reSchedAppointmentStateSelector_ = (state: { reSchedAppointmentDetailsByPhone: State }): State =>
  state.reSchedAppointmentDetailsByPhone;

export const reSchedAppointmentDetailsByPhone_ = createSelector(
  reSchedAppointmentStateSelector_,
  (state) => state.data
);

export default reSchedAppointmentDetailsByPhoneSlice.reducer;
