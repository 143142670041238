import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getFutureAppointments, UpcomingVisits } from 'services';

export type State = ApiState<UpcomingVisits[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchFutureAppointments = createAsyncThunk(
  'get/futureappointments',
  async (configPayLoad: Record<string, string>) => await getFutureAppointments({ ...configPayLoad })
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const futureAppointmentsSlice = createSlice({
  name: 'futureAppointments',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFutureAppointments.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchFutureAppointments.fulfilled, (state, action: PayloadAction<UpcomingVisits[]>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchFutureAppointments.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { futureAppointments: State }): State => state.futureAppointments;

export const futureAppointments_ = createSelector(stateSelector_, (state) => state.data);

export default futureAppointmentsSlice.reducer;
