import { RequestActivationPayload } from 'models';
import { tokenAuthApiInstance } from 'utils/auth';

import { SUBMIT_REQUEST_ACTIVATION } from '../constants';

export const postSubmitActivationRequest = async (
  payload: Partial<RequestActivationPayload> & Record<string, unknown>
): Promise<boolean> => {
  const practiceID = payload?.PracticeID;
  const response = await tokenAuthApiInstance
    .authAxios(undefined, 'v5')
    .post(SUBMIT_REQUEST_ACTIVATION + `?practiceID=${practiceID}`, payload);
  return response.data;
};
