/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { FilterFieldOption } from 'components/common/form';
import { ApiState } from 'models';
import { getLookups, LookupResponse } from 'services';

export type LookupReduxState = {
  GENDER: FilterFieldOption[];
  RACE: FilterFieldOption[];
  MARITALSTATUS: FilterFieldOption[];
  RELATIONSHIP: FilterFieldOption[];
  ETHNICGROUP: FilterFieldOption[];
  SEXUALORIENTATION: FilterFieldOption[];
  GENDERIDENTITY: FilterFieldOption[];
};

export type State = ApiState<LookupReduxState | Record<string, unknown>, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchLookups = createAsyncThunk('get/lookups', async () => await getLookups());

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const lookupsSlice = createSlice({
  name: 'lookups',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLookups.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchLookups.fulfilled, (state, action: PayloadAction<LookupResponse>) => {
      state.isLoading = false;
      const lookupsData: LookupReduxState | Record<string, unknown> = {};
      const data = action.payload;
      const keys = Object.keys(data) as Array<keyof LookupResponse>;
      keys.forEach((key) => {
        lookupsData[key] = data[key].map(({ LookupCode, LookupDesc }) => ({ id: LookupCode, label: LookupDesc }));
      });
      state.data = lookupsData;
      state.hasLoaded = true;
    });

    builder.addCase(fetchLookups.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { lookups: State }): State => state.lookups;

export const lookups_ = createSelector(stateSelector_, (state) => state.data);

export const lookupsIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const lookupsHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export default lookupsSlice.reducer;
