import { tokenAuthApiInstance } from 'utils/auth';

import { EHR_CONSENT_FORMS } from '../constants';

export type ConsentForm = {
  Content: string;
  DateSigned: string;
  FormCategory: string;
  InfoReleaseTarget: string;
  InterpreterDisplayName: string;
  InterpreterLanguage: string;
  InterpreterSignatureImage: string;
  InterpreterSignDate: string;
  InsertedBy: string;
  InsertedDate: string;
  isArchived: number;
  IsCurrent: number;
  IsDeleted: number;
  Language: string;
  LastEditedBy: string;
  LastEditedDate: string;
  PatientDisplayName: string;
  PatientFormID: string;
  PatientFormTemplateID: string;
  PatientID: string;
  PatientRelationship: string;
  PatientSignatureImage: string;
  PatientSignDate: string;
  RevokedDate: string;
  Status: string;
  Title: string;
  Type: string;
  Version: string;
  WitnessDisplayName: string;
  WitnessSignatureImage: string;
  WitnessSignDate: string;
  WitnessUserID: string;
};

export type ConsentFormResponse = Array<ConsentForm>;

export const getEHRConsentForms = async (patientFormTemplateIDs: string): Promise<ConsentFormResponse> => {
  const response = await tokenAuthApiInstance.authAxios([patientFormTemplateIDs]).get(EHR_CONSENT_FORMS);
  return response.data;
};
