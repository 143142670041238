import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getVitalsSummaries, VitalsSummaryResponse } from 'services';

export type State = ApiState<VitalsSummaryResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchVitalsSummaries = createAsyncThunk('get/vitalsSummaries', async () => await getVitalsSummaries());

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const vitalsSummarySlice = createSlice({
  name: 'vitalsSummary',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVitalsSummaries.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchVitalsSummaries.fulfilled, (state, action: PayloadAction<VitalsSummaryResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchVitalsSummaries.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { vitalsSummary: State }): State => state.vitalsSummary;

export const vitalsSummaries_ = createSelector(stateSelector_, (state) => state.data);

export const vitalsSummariesHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const vitalsSummariesIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export default vitalsSummarySlice.reducer;
