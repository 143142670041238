import { tokenAuthApiInstance } from 'utils/auth';

import { ECHECK_IN_ITEMS_CONFIG } from './constants';

export type FormListType = {
  id: number;
  formName: string;
  formTitle: string;
  formType: string;
};

type FormNameItem = {
  formName: string;
  formTitle: string;
};

export type AppointmentScheduleItemsType = {
  appointmentType: string;
  questionnaireForm: FormNameItem[];
  consentFormNameList: FormListType[];
  questionnaireFormNameList: FormListType[];
  visible: boolean;
};

export type Echeckin_Obj = {
  eCheckinItems: {
    appointmentScheduleItems: AppointmentScheduleItemsType[];
    timeBeforeAppointmentInHours: number;
    timeAfterAppointmentInHours: number;
    availabilitySMSText: string;
    availabilityEmailText: string;
    confirmationSMSText: string;
    confirmationEmailText: string;
    isQRCodeEnabled: boolean;
  };
};

export const getEcheckinConfig = async (): Promise<Echeckin_Obj> => {
  const response = await tokenAuthApiInstance.authAxios().get(ECHECK_IN_ITEMS_CONFIG);
  return response.data;
};
