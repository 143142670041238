export const selfSchedulePayloadInitialState = {
  AppointmentRequestID: '',
  CommunicatorEmail: '',
  eCheckInAvailabilityHours: 0,
  IsSchedulerResource: false,
  FromDate: '',
  LocationID: '',
  LocationName: '',
  PatientEmail: '',
  PatientID: '',
  PatientName: '',
  PatientStatus: '',
  ProviderID: '',
  ProviderName: '',
  SendEmailReminder: false,
  SendSMSReminder: false,
  SendSpanishSMSReminder: false,
  SendSpanishVoiceReminder: false,
  SendVoiceReminder: false,
  SMSPhoneNumber: '',
  ToDate: '',
  TypeOfVisit: '',
  UserID: '',
  VoicemailPhoneNumber: '',
  IsRescheduleAppointment: false,
  ReschedulePracticeCalendarID: '',
  IsSelfScheduleAppointment: false,
  MaxSlots: 0,
  IsTelehealthV2Session: false,
  TelehealthAppointment: false,
  TelehealthAppointmentObj: {
    TelehealthAppointmentID: '',
    FromDate: '',
    ToDate: '',
    Duration: 0,
    TelehealthLink: '',
    TelehealthLinkCopied: false,
    TelehealthLinkShared: false,
    AddReminderLater: false,
    PatientSMS: '',
    EmailSelected: false,
    SMSSelected: false,
    SMSSpaishSelected: false,
    SessionStatus: '',
    TelehealthGroupSessionID: '',
  },
};
