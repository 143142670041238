import { tokenAuthApiInstance } from 'utils/auth';

import { EHR_CONSENT_FORM_LIST } from '../constants';

export type EHRFormInfo = {
  Language: string;
  PatientFormTemplateID: string;
  Title: string;
  Version: string;
};

export type EHRFormResponse = Array<EHRFormInfo>;

export const getEHRConsentFormList = async (): Promise<EHRFormResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(EHR_CONSENT_FORM_LIST);
  return response.data;
};
