import { tokenAuthApiInstance } from 'utils/auth';
import { Logger } from 'utils/logger';

import { APPOINTMENT_SCHEDULER_CONFIGURATION_ENDPOINT } from '../constants';

export type LanguageTextItem = {
  locale: string;
  text: string;
};

export type AppointmentSchedulerConfiguration = {
  textOnAppointmentTypeScreen: Array<LanguageTextItem>;
  requestedConfirmationMessage: Array<LanguageTextItem>;
  appointmentScheduleItems: Array<appointmentScheduleItems>;
};

export type appointmentScheduleItems = {
  appointmentType: string;
  questionnaireFormName: string;
  locationIDs: Array<string>;
  questionnaireFormTitle?: string;
  allowTelehealth?: boolean;
  visible?: boolean;
};

export const getAppointmentSchedulerConfiguration = async (): Promise<AppointmentSchedulerConfiguration> => {
  try {
    const response = await tokenAuthApiInstance.authAxios().get(APPOINTMENT_SCHEDULER_CONFIGURATION_ENDPOINT);
    return response.data;
    /* eslint-disable @typescript-eslint/no-explicit-any */
  } catch (error) {
    if (error) {
      const message = `Got error in getting the Appointment scheduler configuration ${JSON.parse(error.toString())}`;
      Logger.error(message);
    }
    throw error;
  }
};
