import './index.scss';
import './i18n';

import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { AppContainer } from 'components/app/app.container';
import { createBrowserHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import store from 'reducers';
import theme from 'theme';
import { Device } from 'utils/browser';
import { DEVICE_TYPES } from 'utils/constants';

import AuthProvider from './context/authContext';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { ai } from './TelemetryService';

const history = createBrowserHistory();

ai.initialize({ history });

const deviceType = Device.get();

if (deviceType === DEVICE_TYPES.ANDROID || deviceType === DEVICE_TYPES.DESKTOP) {
  serviceWorkerRegistration.register({ store: store });
}

ReactDOM.render(
  <React.StrictMode>
    <AppInsightsContext.Provider value={ai.getReactPlugin()}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Provider store={store}>
            <AuthProvider>
              <Router basename={process.env.PUBLIC_URL}>
                <AppContainer />
              </Router>
            </AuthProvider>
          </Provider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </AppInsightsContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
