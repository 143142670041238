import { tokenAuthApiInstance } from 'utils/auth';

import { LOOKUPS } from '../constants';

export type LookUpObj = {
  LookupCode: string;
  LookupDesc: string;
};

export type LookupResponse = {
  GENDER: LookUpObj[];
  RACE: LookUpObj[];
  MARITALSTATUS: LookUpObj[];
  RELATIONSHIP: LookUpObj[];
  ETHNICGROUP: LookUpObj[];
  SEXUALORIENTATION: LookUpObj[];
  GENDERIDENTITY: LookUpObj[];
};

export const getLookups = async (): Promise<LookupResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(LOOKUPS);
  return response.data;
};
