import {
  Avatar,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from '@material-ui/core';
import { CameraAlt, Person } from '@material-ui/icons';
import Alert from '@material-ui/lab/Alert';
import React, { FunctionComponent } from 'react';

import Header from '../InnerHeader';

const GetMedicalAdvice: FunctionComponent = () => {
  const [checked, setChecked] = React.useState([1]);
  const [open, setOpen] = React.useState(false);
  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className="wrapper">
      <Header className="header" heading="Get Medical Advice" />
      <main className="pad10">
        <Alert severity="warning">
          Send a non-urgent message to your clinic. Expect a response within 3 business days.
        </Alert>
        <form noValidate autoComplete="off">
          <TextField id="mail-regarding" label="Regarding" fullWidth className="marB10" />
          <TextField id="mail-to" label="To" fullWidth className="marB10" onClick={handleClickOpen} />
          <TextField id="mail-Subject" label="Subject" fullWidth multiline rowsMax={4} />
          <Box className="upload">
            <CameraAlt className="upload-icon" /> Add an attachment(upto 4)
          </Box>
          <InputBase
            fullWidth
            multiline
            rowsMax={30}
            id="enter-a-message"
            defaultValue="Enter a message"
            inputProps={{ 'aria-label': 'enter-a-message' }}
            className="iconFadeOut"
          />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="choose-a-recipient"
            aria-describedby="choose-a-recipient-description"
          >
            <DialogTitle>
              <Typography variant="h4" color="secondary">
                Choose a recipient
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <List dense>
                  {[0, 1, 2, 3].map((value) => {
                    const labelId = `recipient-${value}`;
                    return (
                      <ListItem key={value} button>
                        <ListItemAvatar>
                          <Avatar className="msg-icon" color="secondary">
                            <Person />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText id={labelId} primary={`recipient ${value + 1}`} />
                        <ListItemSecondaryAction>
                          <Checkbox
                            edge="end"
                            onChange={handleToggle(value)}
                            checked={checked.indexOf(value) !== -1}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })}
                </List>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </form>
      </main>
    </div>
  );
};

export default GetMedicalAdvice;
