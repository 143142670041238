import './InnerHeader-component.styles.scss';

import { AppBar, Box, IconButton, Menu, MenuItem, Toolbar } from '@material-ui/core';
import { Home, MoreVert } from '@material-ui/icons';
import { CpxUser } from 'components/cpx-header/cpx-header-user';
import Toast from 'components/toast';
import { AuthContext } from 'context/authContext';
import { TokenContext } from 'context/tokenContext';
import { isEmpty } from 'lodash';
import _ from 'lodash';
import React, { FunctionComponent, useContext, useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  fetchHealthDeptDetails,
  healthDeptDetails_,
  healthDeptHasLoaded_,
  healthDeptIsLoading_,
} from 'reducers/Department.slice';
import { featureDetails_, fetchFeatureDetails } from 'reducers/feature-details.slice';
import {
  fetchFormIOTranslations,
  formIOTranslations_,
  formIOTranslationsHasLoaded_,
  formIOTranslationsIsLoading_,
} from 'reducers/formio/formio-translations.slice';
import { CpxPortalEventTracker } from 'utils/patient-portal-audit-log';
type Props = {
  heading: string;
  className?: string;
};

const InnerHeader: FunctionComponent<Props> = ({ className, heading }) => {
  const dispatch = useDispatch();
  const healthDeptDetails = useSelector(healthDeptDetails_);
  const healthDeptHasLoaded = useSelector(healthDeptHasLoaded_);
  const healthDeptIsLoading = useSelector(healthDeptIsLoading_);

  const formIOTranslations = useSelector(formIOTranslations_);
  const formIOTranslationsIsLoading = useSelector(formIOTranslationsIsLoading_);
  const formIOTranslationsHasLoaded = useSelector(formIOTranslationsHasLoaded_);

  const { token } = useContext(TokenContext);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const { account, onSignOut } = useContext(AuthContext);
  const { t } = useTranslation();
  const featureDetails = useSelector(featureDetails_);

  // when user logout, CpxPortalEventTracker function triggeres and save the audit data in to db
  const onSignOutButton = () => {
    if (onSignOut && account) {
      CpxPortalEventTracker('Logout', 'Portal Logout');
      onSignOut(account, true);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const goDashboard = () => {
    history.push('/');
  };

  /** fetch health department details on load if it's empty  */
  useEffect(() => {
    if (token && isEmpty(healthDeptDetails) && healthDeptIsLoading && !healthDeptHasLoaded) {
      dispatch(fetchHealthDeptDetails());
    }
  }, [token, healthDeptDetails, healthDeptHasLoaded, healthDeptIsLoading]);

  //fetch feature configurations if it's empty
  useEffect(() => {
    if (token && _.isEmpty(featureDetails)) {
      dispatch(fetchFeatureDetails());
    }
  }, [token, featureDetails]);

  // fetch formio translations on load if it's empty
  useEffect(() => {
    //to read the FormIO is enabled or disabled
    const isFormIODisabled =
      featureDetails &&
      featureDetails.find((obj) => {
        return obj.Key === 'Form.IO';
      })?.Hidden;
    //fetch formio translations only if formio is enabled
    if (
      token &&
      isFormIODisabled === false &&
      !_.isEmpty(featureDetails) &&
      isEmpty(formIOTranslations) &&
      formIOTranslationsIsLoading &&
      !formIOTranslationsHasLoaded
    ) {
      dispatch(fetchFormIOTranslations('en,es'));
    }
  }, [token, featureDetails, formIOTranslations, formIOTranslationsIsLoading, formIOTranslationsHasLoaded]);

  return (
    <AppBar position="sticky" className={className ? 'justifyCenter' : `${className} justifyCenter`}>
      <Toast />
      <Toolbar variant="dense" disableGutters>
        <Box className="fullWidth" display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" className="fullWidth">
            {/* <img src="/images/logo.png" alt="Logo" className="marR10" /> */}
            <CpxUser />
            <h3 className="pageTitle">{heading}</h3>
          </Box>
          {/* TODO Feature based on requirement will make it render dynamically using props */}
          {/* <IconButton edge="end" color="inherit" aria-label="menu">
            <FontAwesomeIcon icon={['far', 'envelope']} />
          </IconButton>
          <IconButton edge="end" color="inherit" aria-label="menu">
            <FontAwesomeIcon icon={['fas', 'sliders-h']} rotation={90} />
          </IconButton> */}
          <Box display="flex">
            <IconButton edge="start" color="inherit" aria-label="Home" onClick={goDashboard}>
              <Home />
            </IconButton>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={handleClick}>
              <MoreVert />
            </IconButton>
            <Menu
              id="menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              className="side-menu z11000"
              PaperProps={{
                style: {
                  width: '15ch',
                },
              }}
            >
              <MenuItem key="dashboard" onClick={() => onSignOutButton()}>
                {t('headerMenu.logout')}
              </MenuItem>
            </Menu>
          </Box>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default InnerHeader;
