import _ from 'lodash';
import { useSelector } from 'react-redux';
import { featureDetails_ } from 'reducers/feature-details.slice';

export const FeatureManager = {
  isEnabled: (key: string): boolean => {
    const features = useSelector(featureDetails_);
    const f = _.find(features, ['Key', key]);
    if (f) {
      const { Hidden } = f;
      return !Hidden;
    }
    return false;
  },
};
