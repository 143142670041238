import { tokenAuthApiInstance } from 'utils/auth';

import {
  DELETE_UPLOADED_DOCUMENT_ENDPOINT,
  GET_HEALTH_INFO_DOCUMENT_ENDPOINT,
  HEALTH_INFO_DOCUMENTS_ENDPOINT,
} from '../constants';

export type UploadedDocument = {
  DocumentDate: string;
  DocumentName: string;
  IsDeleted: boolean;
  PatientID: string;
  DocumentDesc?: string;
  DocumentFormat?: string;
  FileSize?: number;
  PatientHealthInfoDocID?: string;
  rowcount?: number;
  RowNumber?: number;
  UserID?: string;
};

export type DocumentInfo = {
  DocumentName: string;
  DocumentFormat?: string;
};

export type UploadedDocumentResponse = Array<UploadedDocument>;

export const getHealthInfoDocuments = async (rowsPerPage: number): Promise<UploadedDocumentResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(HEALTH_INFO_DOCUMENTS_ENDPOINT(rowsPerPage));
  return response.data;
};

export const getHealthInfoDocument = async (payload: DocumentInfo): Promise<string> => {
  const response = await tokenAuthApiInstance.authAxios().post(GET_HEALTH_INFO_DOCUMENT_ENDPOINT, payload);
  return response.data;
};

//to delete uploaded document
export const deleteUploadedDocument = async (patientHealthInfoDocID: string, userID: string): Promise<boolean> => {
  const response = await tokenAuthApiInstance
    .authAxios([patientHealthInfoDocID, userID])
    .get(DELETE_UPLOADED_DOCUMENT_ENDPOINT);
  return response.data;
};
