import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getInsuranceDetails, getInsuranceDetailsWithPracticeCalendarID, InsuranceDetails } from 'services';

export type State = ApiState<InsuranceDetails[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchInsuranceDetails = createAsyncThunk('get/InsuranceDetails', async () => await getInsuranceDetails());

export const fetchInsuranceDetailsWithPracticeCalendarId = createAsyncThunk(
  'get/InsuranceDetails',
  async (practiceCalendarId: string) => await getInsuranceDetailsWithPracticeCalendarID(practiceCalendarId)
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const insuranceDetailsSlice = createSlice({
  name: 'insuranceDetails',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    unmountInsuranceDetails: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchInsuranceDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchInsuranceDetails.fulfilled, (state, action: PayloadAction<InsuranceDetails[]>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchInsuranceDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { insuranceDetails: State }): State => state.insuranceDetails;

export const insuranceDetails_ = createSelector(stateSelector_, (state) => state.data);

export const insuranceDetailsIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const insuranceDetailsHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const { unmountInsuranceDetails } = insuranceDetailsSlice.actions;

export default insuranceDetailsSlice.reducer;
