// login error
export const ERROR_SIGNIN = 'Error in signing in';

// Current host info
export const HOST_TEST_ENV = process.env.UI_HOST_TEST_ENV || 'test.phcpx.com';
export const HOST_STAGE_ENV = process.env.UI_HOST_STAGE_ENV || 'staging.phcpx.com';
export const HOST_PROD_ENV = process.env.UI_HOST_PROD_ENV || 'phcpx.com';

// Admin portal / feature toggle
export const FEATURES = {
  'Accounts Options Menu': 'Accounts Options Menu',
  Activities: 'Activities',
  Alerts: 'Alerts',
  Appointments: 'Appointments',
  Billing: 'Billing',
  'Care Team': 'Care Team',
  Dependents: 'Dependents',
  Documents: 'Documents',
  'eCheck-In ToDo': 'eCheck-In ToDo',
  'Form.IO': 'Form.IO',
  'Health Summary': 'Health Summary',
  'Highlighted Features': 'Highlighted Features',
  Medications: 'Medications',
  Messages: 'Messages',
  'Menu - Account Settings': 'Menu - Account Settings',
  'Menu - Personalize': 'Menu - Personalize',
  'Menu - Preferences': 'Menu - Preferences',
  'Menu - Ask a Question': 'Menu - Ask a Question',
  'Menu - Paperless Billing': 'Menu - Paperless Billing',
  'Menu - View Medications': 'Menu - View Medications',
  'Menu - Review Health Summary': 'Menu - Review Health Summary',
  'Menu - Schedule an Appointment': 'Menu - Schedule an Appointment',
  'Menu - Signup for Text Reminder': 'Menu - Signup for Text Reminder',
  'Menu - SMS Text Reminders': 'Menu - SMS Text Reminders',
  'Menu - View Billing Summary': 'Menu - View Billing Summary',
  'Menu - View Test Results': 'Menu - View Test Results',
  'Menu - Virtual Urgent Care': 'Menu - Virtual Urgent Care',
  'Menu - What"s New': 'Menu - What"s New',
  'Pay Balance ToDo': 'Pay Balance ToDo',
  'Proxy Access': 'Proxy Access',
  Questionnaire: 'Questionnaire',
  'Quick Links Menu': 'Quick Links Menu',
  'Test Results': 'Test Results',
  ToDos: 'ToDos',
  'Visit Records': 'Visit Records',
  'View Proxy Access': 'View Proxy Access',
};

// Azure B2C custom attributes
export const CUSTOM_ATTRIBUTE_PRACTICE_ID = 'extension_practiceID';
export const CUSTOM_ATTRIBUTE_PATIENT_ID = 'extension_patientID';
export const CUSTOM_ATTRIBUTE_ENVIRONMENT = 'environment';
export const CUSTOM_ATTRIBUTE_ENVIRONMENT_TEST = 'test';
export const CUSTOM_ATTRIBUTE_ENVIRONMENT_STAGE = 'stage';
export const CUSTOM_ATTRIBUTE_ENVIRONMENT_PROD = 'production';

// Azure blob key for logging purposes
export const AZURE_BLOB = {
  ACCOUNT_NAME: process.env.AZURE_BLOB_ACCOUNT_NAME || 'cpxlogs',
  ACCOUNT_KEY:
    process.env.AZURE_BLOB_ACCOUNT_KEY ||
    'cCM8eLb5yToXcbzIDQvsVafIZUbZn3lfU/DDRb8TjP+7dh+ngWvQHHZynU32lJZ31GOwA3WTipOj5uh/0NsjbQ==',
  CONTAINER_NAME: process.env.AZURE_BLOB_CONTAINER_NAME || 'cpx-ui-logs',
};

// Environment strings
export const HOST_ENV_KEY_STR = {
  LOCAL: 'local',
  TEST: 'test',
  STAGE: 'stage',
  PROD: 'prod',
};

export const BROWSER_TYPES = {
  FIREFOX: {
    KEY: 'Firefox',
    VALUE: 'Mozilla Firefox',
  },
  SAMSUNG: {
    KEY: 'SamsungBrowser',
    VALUE: 'Samsung Internet',
  },
  OPERA: {
    KEY: 'OPERA',
    VALUE: 'Opera',
  },
  OPR: {
    KEY: 'OPR',
    VALUE: 'Opera',
  },
  TRIDENT: {
    KEY: 'Trident',
    VALUE: 'Microsoft Internet Explorer',
  },
  EDGE: {
    KEY: 'Edge',
    VALUE: 'Microsoft Edge',
  },
  CHROME: {
    KEY: 'Chrome',
    VALUE: 'Google Chrome or Chromium',
  },
  SAFARI: {
    KEY: 'Safari',
    VALUE: 'Apple Safari',
  },
};

export const DEVICE_TYPES = {
  ANDROID: 'Android',
  IPHONE: 'iPhone',
  IPAD: 'iPad',
  IPOD: 'iPod',
  BLACKBERRY: 'BlackBerry',
  OPERA: 'Opera Mini',
  WINDOWSPHONE: 'IEMobile',
  DESKTOP: 'Desktop',
};

export const NOT_ENTERED = 'Not Entered';
export const ECHECKIN_COMPLETE_STATUS = ['scheduled', 'confirmed'];
export const APPOINTMENT_REQUEST_STATUS = ['requested', 'reschedule requested'];
export const ECHECKIN_STATUS = ['echeck-in by patient', 'echeck-in verified'];
export const STATUS = 'Status';
export const ARRIVE_BY = 'Arrive By';
export const STARTS_AT = 'Starts At';
export const PREFERRED_TIME_2 = 'Preferred Date 2';
export const PREFERRED_TIME_3 = 'Preferred Date 3';

export const TOAST_ALERT_SEVERITY = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success',
};
export const YY_MM_DD = 'YYYY.MM.DD';

export const TELEMETRY_SERVICE = {
  STAGE: process.env.REACT_APP_TELEMETRY_STAGE || '9db58676-86f9-4e32-871c-009ed7486205',
  PROD: process.env.REACT_APP_TELEMETRY_PROD || '',
};

// ALLOWED TOKENS IN THE SCHEDULE APPOINTMENT TYPE TEXT MESSAGE
export const SCHEDULE_APPOINTMENT_MESSAGE_TOKENS = [
  '[Appointment Type]',
  '[Practice Name]',
  '[Practice Address]',
  '[Practice Contact]',
];

// ALLOWED TOKENS IN THE SCHEDULE APPOINTMENT TYPE CONFIRMATION MESSAGE
export const SCHEDULE_APPOINTMENT_CONFIRMATION_TOKENS = [
  '[Patient Name]',
  '[Practice Location]',
  '[Appointment Type]',
  '[Location Address]',
  '[Location Contact]',
];

// local storage key for the Add To Home Screen bubble
export const ADD_TO_HOME_SCREEN_STORAGE_KEY = 'addToHomeScreenShownCount';

// name to store in session storage
export const SESSION_TOKEN_NAME = 'kiosk_token';

// link validation in Documents widget
export const SHARE_LINKS = {
  LINK_REGEX:
    '^(http|https):\\/\\/(www.)?[-a-zA-Z0-9@:%._+~#=]{1,256}.[a-zA-Z0-9()]{1,6}\\b([-a-zA-Z0-9()@:%_+.~#?&\\/\\/=]*)',
};

// ALLOWED TOKENS IN THE PROXY ACCESS/REQUEST TOKENS
export const PROXY_ACCESS_TOKENS = [
  '[Patient Name]',
  '[Practice Name]',
  '[Practice Address]',
  '[Practice Contact]',
  '[Location Address]',
  '[Location Contact]',
];
