import { AuthContext } from 'context/authContext';
import KioskAppComponent from 'kiosk/kiosk-app.component';
import React, { FunctionComponent, useContext } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { isLoading_ } from 'reducers/general/loader.slice';

import { AppComponent } from './app.component';

export const AppContainer: FunctionComponent = () => {
  const { isAuthenticated, byPassAuth } = useContext(AuthContext);
  const { pathname } = useLocation();
  const isKioskPath = pathname.includes('kiosk');

  const isLoading = useSelector(isLoading_);
  return !isKioskPath ? (
    <AppComponent isAuthenticated={isAuthenticated} isLoading={isLoading} byPassAuth={byPassAuth} />
  ) : (
    <KioskAppComponent isAuthenticated={isAuthenticated} isLoading={isLoading} byPassAuth={byPassAuth} />
  );
};
