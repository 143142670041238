import { tokenAuthApiInstance } from 'utils/auth';
import { tokenAuthApiInstanceWithToken } from 'utils/auth';

import { PRACTICES_LIST_ENDPOINT, REQUEST_ACTIVATION_CODE_PRACTICES_LIST_ENDPOINT } from '../constants';
import { HealthDeptResponse } from '../project-details.api';

export const getPracticesList = async (): Promise<HealthDeptResponse[]> => {
  const response = await tokenAuthApiInstance.authAxios().get(PRACTICES_LIST_ENDPOINT);
  return response.data;
};

export const requestActivationGetPracticesList = async (token: string): Promise<HealthDeptResponse[]> => {
  const response = await tokenAuthApiInstanceWithToken(token)
    .authAxios(undefined, 'v5')
    .get(REQUEST_ACTIVATION_CODE_PRACTICES_LIST_ENDPOINT);
  return response.data;
};
