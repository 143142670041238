import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getPastAppointmentQuestionnaires } from 'services';

import { QuestionnaireResponse } from '../../services/questionnaire-wizard/questionnaire-list.api';

export type State = ApiState<QuestionnaireResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchPastAppointmentQuestionnaire = createAsyncThunk(
  'get/pastAppointmentQuestionnaire',
  async (Payload: Record<string, string>) => await getPastAppointmentQuestionnaires({ ...Payload })
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const pastAppointmentQuestionnaireSlice = createSlice({
  name: 'scheduleLocations',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    unmountPastAppointmentQuestionnaire: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPastAppointmentQuestionnaire.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchPastAppointmentQuestionnaire.fulfilled,
      (state, action: PayloadAction<QuestionnaireResponse>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.hasLoaded = true;
      }
    );

    builder.addCase(fetchPastAppointmentQuestionnaire.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { pastAppointmentQuestionnaire: State }): State =>
  state.pastAppointmentQuestionnaire;

export const pastAppointmentQuestionnaire_ = createSelector(stateSelector_, (state) => state.data);

export const pastAppointmentQuestionnaireLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const pastAppointmentQuestionnaireHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const { unmountPastAppointmentQuestionnaire } = pastAppointmentQuestionnaireSlice.actions;

export default pastAppointmentQuestionnaireSlice.reducer;
