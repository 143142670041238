import { tokenAuthApiInstance } from 'utils/auth';

import { FORMIO_TRANSLATIONS_ENDPOINT } from '../constants';

export type FormIOLanguageResource = {
  i18n: Record<string, unknown>;
};

export const getFormIOTranslations = async (language: string): Promise<FormIOLanguageResource> => {
  const response = await tokenAuthApiInstance.authAxios([language]).get(FORMIO_TRANSLATIONS_ENDPOINT);
  return response.data;
};
