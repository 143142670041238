import { AccountInfo, AuthenticationResult, EndSessionRequest } from '@azure/msal-browser';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import _ from 'lodash';
import QueryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { getCCAToken } from 'services';
import { postUserActivatedRequest } from 'services';
import { KIOSK_AUTHENTICATION_ENDPOINT } from 'services/constants';
import {
  HEALTH_CHECK_ROUTE,
  KIOSK_CONFIRMED_APPOINTMENT_ROUTE,
  KIOSK_ECHECKIN_APPOINTMENT_ROUTE,
  KIOSK_ECHECKIN_ROUTE,
  KIOSK_LOGIN_ROUTE,
  KIOSK_MAIN_ROUTE,
  KIOSK_MENU_ROUTE,
  REQUEST_ACTIVE_CODE,
} from 'services/routes';
import { getStorageSessionToken, JWTHelper, setJwtToken } from 'utils';
import { tokenAuthApiInstance } from 'utils/auth';
import { b2cPolicies } from 'utils/b2c/policies';
import {
  CUSTOM_ATTRIBUTE_ENVIRONMENT_PROD,
  CUSTOM_ATTRIBUTE_ENVIRONMENT_STAGE,
  CUSTOM_ATTRIBUTE_ENVIRONMENT_TEST,
  ERROR_SIGNIN,
} from 'utils/constants';
import { HostEnv } from 'utils/environment';
import { Logger } from 'utils/logger';
import { aiHelper } from 'utils/metrics';

import { AUTH_REQUESTS, AUTH_SCOPES, isInteractionInProgress, msalApp } from '../services/auth-utils.api';

// Interface for Azure Authentication Error
interface IError {
  error: string | null;
}

// Azure Authentication Context Type
/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/no-unused-vars */
export type AuthContextType = {
  isAuthenticated: boolean;
  accessToken: string;
  idToken: string;
  tokenExpired: boolean;
  account?: AccountInfo | undefined;
  error?: IError | undefined;
  onSignIn?: (redirect: boolean) => any;
  onSignOut?: (account: AccountInfo, redirect: boolean) => void;
  setTokenExpired?: (tokenExpired: boolean) => void;
  resetCredentials?: () => void;
  byPassAuth: boolean; // bypass the authentication for requesting activation code
};

// Create empty context
/* eslint-disable  @typescript-eslint/no-empty-function */
export const AuthContext = React.createContext<AuthContextType>({
  isAuthenticated: false,
  tokenExpired: false,
  accessToken: '',
  idToken: '',
  byPassAuth: false,
});

const AuthProvider: React.FC = ({ children }) => {
  const [account, setAccount] = useState<AccountInfo>();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [tokenExpired, setTokenExpired] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [idToken, setIdToken] = useState('');
  const [error, setError] = useState<IError>({ error: null });
  const [byPassAuth, setByPassAuth] = useState(false);
  const appInsights = useAppInsightsContext();

  // for our case use redirect
  const useRedirectFlow = true;

  // check if user is authenticated
  useEffect(() => {
    (async () => {
      try {
        const accts = msalApp.getAllAccounts();
        const redirectFlag = sessionStorage.getItem('StartRedirectLogin');
        const isRedirectInProcess = redirectFlag === 'true';
        const interactionInProgress = isInteractionInProgress();
        const currentLocation = window.location.href;
        // check for health check path, bypass auth
        if (currentLocation.includes(HEALTH_CHECK_ROUTE)) {
          setByPassAuth(true);
          return;
        }
        // Activation page
        if (currentLocation.includes(REQUEST_ACTIVE_CODE)) {
          setByPassAuth(true);
          // get an app token here and set it
          const ccaToken = await getCCAToken();
          if (!_.isEmpty(ccaToken)) {
            const { accessToken } = ccaToken;
            setAccessToken(accessToken);
          }
          return;
        }
        // for kiosk mode
        if (
          currentLocation.includes(KIOSK_LOGIN_ROUTE) ||
          currentLocation.includes(KIOSK_MAIN_ROUTE) ||
          currentLocation.includes(KIOSK_MENU_ROUTE) ||
          currentLocation.includes(KIOSK_ECHECKIN_ROUTE) ||
          currentLocation.includes(KIOSK_ECHECKIN_APPOINTMENT_ROUTE) ||
          currentLocation.includes(KIOSK_CONFIRMED_APPOINTMENT_ROUTE)
        ) {
          const token = getStorageSessionToken();
          // if the token found just return
          if (token) {
            Logger.debug('found storage session token');
            setAccessToken(token);
            setIdToken(token);
            setIsAuthenticated(true);
            return;
          }

          // change query parameter to kiosk/l?c={token}
          const { c: code } = getUrlQuery();
          await authenticateWithPHIdentity(code);

          return;
        }
        // if user has not authenticated, account logged in is empty, and not in a redirect process,
        // prompt the user to sign in
        if (!isAuthenticated && accts.length === 0 && !isRedirectInProcess && !interactionInProgress && !tokenExpired) {
          onSignIn(useRedirectFlow);
        }
        /* eslint-disable @typescript-eslint/no-explicit-any */
      } catch (err) {
        Logger.error(`got error: ${JSON.stringify(err)}`);
      }
    })();
  }, [isAuthenticated]);

  useEffect(() => {
    (async () => {
      try {
        if (account === undefined || accessToken === undefined) {
          const activeAcct = getAccount();
          // if the redirect login finished the active account will be set,
          // getAccountToken will silently acquire user logged in tokens
          if (activeAcct) {
            await getAccountToken({
              scopes: AUTH_REQUESTS.TOKEN_REQUEST.scopes,
              account: activeAcct,
            });
          }
        }
        /* eslint-disable @typescript-eslint/no-explicit-any */
      } catch (err) {
        Logger.error(`Got error in checking account and access token ${JSON.stringify(err)}`);
      }
    })();
  }, [account, accessToken]);

  useEffect(() => {
    if (tokenExpired) {
      const activeAccount = getAccount();
      if (activeAccount) {
        setAccessToken('');
        setIsAuthenticated(false);
        onSignOut(activeAccount, useRedirectFlow);
      }
    }
  }, [tokenExpired]);

  // user signs out
  const onSignOut = (account: AccountInfo, redirect: boolean): void => {
    try {
      // track event metric
      if (idToken && idToken != '') {
        const jwt = JWTHelper.parse(idToken);
        const { extension_practiceID } = jwt;
        // we only know about the practice id, not the practice name yet
        aiHelper.trackEvent(appInsights, 'User Logout', { PracticeID: extension_practiceID });
      }
      const logOutRequest: EndSessionRequest = {
        account,
      };
      // log user out
      if (redirect) {
        msalApp.logoutRedirect(logOutRequest);
      } else {
        msalApp.logoutPopup(logOutRequest);
      }
      /* eslint-disable @typescript-eslint/no-explicit-any */
    } catch (error) {
      Logger.error(`Failed to sign out ${JSON.stringify(error)}`);
    }
  };

  const getAccountToken = async (request: any): Promise<any> => {
    return msalApp
      .acquireTokenSilent(request)
      .then((response: AuthenticationResult) => {
        handleResponse(response);
        return new Promise<boolean>((resolve) => resolve(true));
      })
      .catch(async (error) => {
        console.error('Non-interactive error:', error.errorCode);
        return new Promise<boolean>((resolve) => resolve(false));
      });
  };

  // check if user logged in is in the right environment
  const isUserAccountInThisEnv = (jwt: string): boolean => {
    if (jwt && jwt.length > 0) {
      const j = JWTHelper.parse(jwt);
      const { extension_environment } = j;
      const env = extension_environment.toLowerCase();
      if (env === CUSTOM_ATTRIBUTE_ENVIRONMENT_TEST && (HostEnv.isTest() || HostEnv.isLocal())) {
        return true;
      } else if (env === CUSTOM_ATTRIBUTE_ENVIRONMENT_STAGE && HostEnv.isStage()) {
        return true;
      } else if (env === CUSTOM_ATTRIBUTE_ENVIRONMENT_PROD && HostEnv.isProd()) {
        return true;
      }
    }
    return false;
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleResponse = async (response: AuthenticationResult): Promise<any> => {
    const { account, accessToken, idToken } = response;
    if (account && accessToken) {
      const jwt = JWTHelper.parse(accessToken);
      const { isForgotPassword, newUser } = jwt;
      // if the user reset their password, sign them out
      if (isForgotPassword) {
        // track event metric
        if (idToken && idToken != '') {
          const jwt = JWTHelper.parse(idToken);
          const { extension_practiceID } = jwt;
          // we only know about the practice id, not the practice name yet
          aiHelper.trackEvent(appInsights, 'User Reset Password', { PracticeID: extension_practiceID });
        }
        setIsAuthenticated(false);
        onSignOut(account, useRedirectFlow);
        return;
      } else if (newUser) {
        // if new user, set the user is activated
        try {
          const result = await postUserActivatedRequest(accessToken);
          if (result) {
            Logger.info(`Activated user successfully with result: ${JSON.stringify(result)}`);
          }
          /* eslint-disable @typescript-eslint/no-explicit-any */
        } catch (error) {
          if (error) {
            Logger.error(`Error in setting the activation flag for this user: ${JSON.stringify(error)}`);
          }
        }
        // log the user out
        setIsAuthenticated(false);
        onSignOut(account, useRedirectFlow);
        return;
      } else if (!isUserAccountInThisEnv(idToken)) {
        // if user is in the wrong environment log them out
        setIsAuthenticated(false);
        onSignOut(account, useRedirectFlow);
        return;
      } else {
        // track event metric
        if (idToken && idToken != '') {
          const jwt = JWTHelper.parse(idToken);
          const { extension_practiceID } = jwt;
          // we only know about the practice id, not the practice name yet
          aiHelper.trackEvent(appInsights, 'User Signed In', { PracticeID: extension_practiceID });
        }
        // set the id token first
        if (idToken) {
          setIdToken(idToken);
        }
        // then the rest
        setAccount(account);
        setAccessToken(accessToken);
        setIsAuthenticated(true);
        setError({ error: null });
        setTokenExpired(false);
      }
    }
  };

  // user signs in
  /* eslint-disable @typescript-eslint/no-explicit-any */
  const onSignIn = (redirect: boolean): any => {
    try {
      if (redirect) {
        return msalApp.loginRedirect({
          scopes: [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE, AUTH_SCOPES.USERREAD],
          extraQueryParameters: {
            ui_locales: localStorage.getItem('language') ?? 'en',
          },
        });
      }

      msalApp
        .loginPopup({
          scopes: [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE, AUTH_SCOPES.USERREAD],
          extraQueryParameters: {
            ui_locales: localStorage.getItem('language') ?? 'en',
          },
        })
        .then((resp: AuthenticationResult) => {
          handleResponse(resp);
        })
        .catch((error: any) => {
          Logger.error(`${ERROR_SIGNIN} ${JSON.stringify(error)}`);
        });
      /* eslint-disable @typescript-eslint/no-explicit-any */
    } catch (error) {
      Logger.error(`Failed onSignIn: ${JSON.stringify(error)}`);
    }
  };

  // Get current user account logged in
  const getAccount = (): AccountInfo | undefined => {
    const currentAccounts = msalApp.getAllAccounts();
    if (currentAccounts === null) {
      return undefined;
    }

    if (currentAccounts.length > 1) {
      return currentAccounts[0];
    } else if (currentAccounts.length === 1) {
      return currentAccounts[0];
    }
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const resetCredentials = async (): Promise<any> => {
    try {
      const activeAcct = getAccount();
      if (activeAcct) {
        const { username } = activeAcct;
        // sign out the current user
        await onSignOut(activeAcct, true);

        // redirect user to the password reset
        await msalApp.loginRedirect({
          scopes: AUTH_REQUESTS.LOGIN.scopes,
          authority: b2cPolicies.authorities.forgotPassword.authority,
          loginHint: username,
        });
      }
      /* eslint-disable @typescript-eslint/no-explicit-any */
    } catch (err) {
      Logger.error(`Failed open reset credentials dialog ${JSON.stringify(err)}`);
    }
  };

  const authenticateWithPHIdentity = async (code: string): Promise<any> => {
    try {
      tokenAuthApiInstance.token = code;

      const { status } = await tokenAuthApiInstance.authAxios(undefined, 'v0').get(KIOSK_AUTHENTICATION_ENDPOINT);
      if (status >= 200 && status < 300) {
        //Set the token in session storage
        const success = setJwtToken(code);
        if (success) {
          setIdToken(code);
          setAccessToken(code);
          Logger.debug('user is authenticated');
          setIsAuthenticated(true);
        } else {
          Logger.error('token expired');
          setIsAuthenticated(false);
        }
      } else {
        // redirect to not authorized page
        Logger.error('Could not authenticate user');
        setIsAuthenticated(false);
      }
    } catch (error) {
      Logger.error(
        `Authentication Error. Could not validate PH Identity token. Token: ${code} Error: ${JSON.stringify(error)}`
      );
    }
  };

  const getUrlQuery = (): any => {
    return QueryString.parse(window.location.search);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        accessToken,
        idToken,
        tokenExpired,
        account,
        error,
        onSignIn,
        onSignOut,
        setTokenExpired,
        resetCredentials,
        byPassAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
