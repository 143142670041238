import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { MedicationDetailsResponse, medicationsDetails } from 'services';

export type State = ApiState<MedicationDetailsResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchMedicationDetails = createAsyncThunk(
  'get/medicationDetails',
  async (patientMedicationID: string) => await medicationsDetails(patientMedicationID)
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const medicationDetailsSlice = createSlice({
  name: 'medicationDetails',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    unMountMedicationDetails: () => {
      return { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchMedicationDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchMedicationDetails.fulfilled, (state, action: PayloadAction<MedicationDetailsResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchMedicationDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { medicationDetails: State }): State => state.medicationDetails;

export const medicationDetails_ = createSelector(stateSelector_, (state) => state.data);

export const { unMountMedicationDetails } = medicationDetailsSlice.actions;
export default medicationDetailsSlice.reducer;
