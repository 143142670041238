import { Snackbar, SnackbarCloseReason } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { AuthContext } from 'context/authContext';
import React, { FunctionComponent, SyntheticEvent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { clearMessages, toastMessages_ } from 'reducers/general/toast.slice';
import { hasTokenExpired_ } from 'reducers/general/token.slice';
import { TOAST_ALERT_SEVERITY } from 'utils/constants';

// materials-ui alerts color type
export type Color = 'error' | 'info' | 'success' | 'warning';

export const Toast: FunctionComponent = () => {
  const { setTokenExpired } = useContext(AuthContext);
  const messages = useSelector(toastMessages_);
  const hasTokenExpired = useSelector(hasTokenExpired_);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  // init with a generic message with an error alert,
  const { t } = useTranslation();
  const genericMsg = t('error.generic');
  const tokenExpiredMsg = t('error.tokenExpired');
  const [messageTranslated, setMessageTranslated] = useState(genericMsg);
  const [msgSeverity, setMsgSeverity] = useState<Color>('error');

  useEffect(() => {
    const openState = messages && messages?.length > 0;
    if (openState) {
      const { severity } = messages[0];
      // set the message if the token expired
      if (hasTokenExpired) {
        setMessageTranslated(tokenExpiredMsg);
      }
      // avoiding the type script error between string and Color type
      setSeverityColor(severity);
    }
    // set the state for the toast to open or not
    setOpen(openState);
  }, [messages]);

  const setSeverityColor = (severity: string) => {
    if (severity) {
      const sev: { c: Color } = { c: 'error' };
      // couldn't use the constant here, TOAST_ALERT_SEVERITY.. has to be a string
      if (severity === TOAST_ALERT_SEVERITY.INFO) {
        sev.c = 'info';
      } else if (severity === TOAST_ALERT_SEVERITY.WARNING) {
        sev.c = 'warning';
      } else if (severity === TOAST_ALERT_SEVERITY.SUCCESS) {
        sev.c = 'success';
      }
      setMsgSeverity(sev.c);
    }
  };

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleClose = (event: SyntheticEvent<Element, Event>, reason?: SnackbarCloseReason) => {
    if (reason !== 'clickaway') {
      setOpen(false);
    }
    dispatch(clearMessages());
    // if token has expired set the token has expired flag to log user out
    if (hasTokenExpired && setTokenExpired) {
      setTokenExpired(true);
    }
  };

  return (
    <div className="toast-style">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert severity={msgSeverity} onClose={handleClose}>
          {messageTranslated}
        </Alert>
      </Snackbar>
    </div>
  );
};
