import { DialogAction } from 'components/common/dialogue/dialogue.component';
import { Dispatch, SetStateAction, useState } from 'react';

/* eslint-disable  @typescript-eslint/no-unused-vars */
export type dialogueHookReturnType = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onDialogAction: (action: DialogAction['action'], index?: number) => void;
  actionType: DialogAction['action'] | undefined;
  setActionType: Dispatch<SetStateAction<DialogAction['action'] | undefined>>;
};

const dialogueHook = (callBack?: () => void): dialogueHookReturnType => {
  const [open, setOpen] = useState(false);
  const [actionType, setActionType] = useState<DialogAction['action']>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDialogAction = (action: DialogAction['action'], index?: number) => {
    if (action === 'Cancel') {
      setOpen(false);
      setActionType('Cancel');
    }
    if (action === 'Update') {
      setActionType('Update');
      callBack && callBack();
    }
  };

  return {
    open,
    setOpen,
    onDialogAction,
    actionType,
    setActionType,
  };
};

export default dialogueHook;
