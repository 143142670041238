import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getKioskAppointments, KioskAppointmentsResponse } from 'services';

export type State = ApiState<KioskAppointmentsResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchKioskAppointments = createAsyncThunk(
  'get/projectDetails',
  async (patientId: string) => await getKioskAppointments(patientId)
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const kioskAppointmentsSlice = createSlice({
  name: 'kioskAppointments',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    unmountKioskAppointmentsDetails: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchKioskAppointments.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchKioskAppointments.fulfilled, (state, action: PayloadAction<KioskAppointmentsResponse>) => {
      const { otherAppointments, todaysAppointments, checked_InAppointments } = action.payload;
      let removeDuplicatesFromOther;
      if (todaysAppointments[0]?.PracticeCalendarID) {
        removeDuplicatesFromOther = otherAppointments?.filter((obj) => {
          return obj.PracticeCalendarID !== todaysAppointments[0].PracticeCalendarID;
        });
      }
      const filteredOtherAppointments = todaysAppointments[0]?.PracticeCalendarID
        ? removeDuplicatesFromOther
        : otherAppointments;
      state.isLoading = false;
      state.data = {
        todaysAppointments,
        otherAppointments: filteredOtherAppointments ? filteredOtherAppointments : [],
        checked_InAppointments,
      };
      state.hasLoaded = true;
    });

    builder.addCase(fetchKioskAppointments.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { kioskAppointments: State }): State => state.kioskAppointments;

export const kioskAppointments_ = createSelector(stateSelector_, (state) => state.data);
export const { unmountKioskAppointmentsDetails } = kioskAppointmentsSlice.actions;

export default kioskAppointmentsSlice.reducer;
