import _ from 'lodash';
import moment from 'moment';
import { ScheduleQuestionResponseObject } from 'services';
import { SESSION_TOKEN_NAME } from 'utils/constants';
import { Logger } from 'utils/logger';

import DOMObjectsJson from './DOMObjects.json';

// this is to replace strings with the value, used in url constants
export const Strings = {
  substitute: (str = '', values: string[]): string => {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    return str.replace(/{{(\d+)}}/g, (match: string, index: number): any => {
      if (match) {
        return values[index];
      }
    });
  },
};

// transform JWT string token to JSON
export const JWTHelper = {
  /* eslint-disable @typescript-eslint/no-explicit-any */
  parse: (str = ''): any => {
    return JSON.parse(atob(str.split('.')[1]));
  },
};

// use for the JWT passed in from the EHR
export const setJwtToken = (token: string): boolean => {
  try {
    if (token) {
      // set expiration time
      const jwt = JWTHelper.parse(token);
      const { exp } = jwt;
      // calculate how long do we have toke expires
      const millisecs = exp * 1000;
      const expires = exp ? millisecs - Date.now() : 3600;
      // set the storage session token if it hasn't expired
      if (expires > 0) {
        setStorageSessionToken(token, millisecs);
        return true;
      } else {
        clearStorageSessionToken();
      }
    }
  } catch (error) {
    Logger.error(`Error in setting the Jwt in the session storage ${JSON.stringify(error)}`);
  }
  return false;
};

// set token in storage session
export const setStorageSessionToken = (token: string, expires: number): void => {
  // set when the token expires
  const token_expire_time = expires;
  sessionStorage.setItem(SESSION_TOKEN_NAME, token);
  sessionStorage.setItem(`${SESSION_TOKEN_NAME}_expires`, token_expire_time.toString());
};

// get token in storage session
export const getStorageSessionToken = (): string | null => {
  try {
    // in milliseconds since epoch time
    const now = Date.now();
    // get token expiration time
    const expireStr = sessionStorage.getItem(`${SESSION_TOKEN_NAME}_expires`);
    const expires = _.isEmpty(expireStr) ? 0 : Number(expireStr);

    // check if the token has expired
    if (expires < now) {
      sessionStorage.removeItem(SESSION_TOKEN_NAME);
      sessionStorage.removeItem(`${SESSION_TOKEN_NAME}_expires`);
      return null;
    }
    // return the session storage value
    return sessionStorage.getItem(SESSION_TOKEN_NAME);
  } catch (error) {
    Logger.error(`Error in getting the token session storage ${JSON.stringify(error)}`);
    return null;
  }
};

// clear storage session
export const clearStorageSessionToken = (): void => {
  try {
    sessionStorage.removeItem(SESSION_TOKEN_NAME);
    sessionStorage.removeItem(`${SESSION_TOKEN_NAME}_expires`);
  } catch (error) {
    Logger.error(`Error in clearing the session storage ${JSON.stringify(error)}`);
  }
};

export const AppVersion = {
  getVersion: (): string => {
    if (document) {
      const metadata = document.querySelector('meta[name="build-version"]');
      if (metadata) {
        const value = metadata.getAttribute('content');
        return value ? value : '';
      }
    }
    return '';
  },
};

export const Browser = {
  get: (): string => {
    let sBrowser;
    const sUsrAg = window.navigator.userAgent;
    if (sUsrAg.indexOf('Firefox') > -1) {
      sBrowser = 'Mozilla Firefox';
      // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
    } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
      sBrowser = 'Samsung Internet';
      // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
    } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
      sBrowser = 'Opera';
      // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
    } else if (sUsrAg.indexOf('Trident') > -1) {
      sBrowser = 'Microsoft Internet Explorer';
      // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
    } else if (sUsrAg.indexOf('Edge') > -1) {
      sBrowser = 'Microsoft Edge';
      // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
    } else if (sUsrAg.indexOf('Chrome') > -1) {
      sBrowser = 'Google Chrome or Chromium';
      // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
    } else if (sUsrAg.indexOf('Safari') > -1) {
      sBrowser = 'Apple Safari';
      // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
    } else {
      sBrowser = 'unknown';
    }
    return sBrowser;
  },
};

//TO Get phone number format on typing of TextField (XXX)XXX-XXXX
export const formatPhoneNumber = (value: string | undefined): string | undefined => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (value.length) {
    if (cvLength < 3) return `${currentValue}`;
    if (cvLength < 7) return `${currentValue.slice(0, 3)}${currentValue.slice(3)}`;

    return `(${currentValue.slice(0, 3)})${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};

export const setResetTime = (date: Date, isEndOfDay = false): Date => {
  const resetTimeEpochs = isEndOfDay ? date?.setHours(23, 59, 59) : date?.setHours(0, 0, 0);
  return resetTimeEpochs ? new Date(resetTimeEpochs) : date;
};

// for checking alert message expiration
export const hasMessageExpired = (expireDate: string): boolean => {
  // return early
  if (!expireDate || expireDate === '') {
    return true;
  }
  // convert to seconds to beginning of time
  const expired = new Date(expireDate).getTime();
  // get todays time
  const now = new Date().getTime();
  // return if the expireDate has passed
  return now > expired;
};

// form.IO helper function
// this is to find all the nested components in form IO and set the label and value pair
// this may not work for all forms.. we'll have to adjust as we get new forms created
/* eslint-disable-next-line */
export const formIOReplaceKeysValuesDeep = (component: any, formData: any): any => {
  return _.reduce(
    component,
    (acc: any, value: any) => {
      const { hidden, input, label, key, components, columns, rows, type, inputType, values } = value;
      // if it's an input field
      if (!hidden && input) {
        let inputValue: any;
        if (formData) {
          inputValue = formData[key];
          // check if the input value is true or false for checkbox only,
          // then change this to something readable like Yes/No
          if (typeof inputValue === 'boolean' && type === 'checkbox') {
            inputValue = inputValue ? 'Yes' : 'No';
          }
          // check if the input type is a selectbox with checkboxes
          if (
            typeof inputValue === 'object' &&
            type === 'selectboxes' &&
            !_.isEmpty(values) &&
            inputType === 'checkbox'
          ) {
            // find all the keys with checkbox selected
            const keys = _.keys(_.pickBy(inputValue, _.identity));
            // find the label for those checkbox keys
            const cbLabels: Array<string> = [];
            keys.forEach((k) => {
              const cb = _.find(values, _.matchesProperty('value', k));
              if (!_.isEmpty(cb)) {
                const { label } = cb;
                cbLabels.push(label);
              }
            });
            inputValue = cbLabels.join(', ');
          }
        }
        return {
          ...acc,
          [label]: inputValue,
        };
      } else if (!input && !_.isEmpty(components)) {
        const newLabel = label ? label : 'items';
        if (newLabel in acc) {
          return {
            [newLabel]: {
              ...acc?.items,
              ...(_.isArray(components) ? formIOReplaceKeysValuesDeep(components, formData) : components),
            },
          };
        } else {
          return {
            ...acc,
            [newLabel]: _.isArray(components) ? formIOReplaceKeysValuesDeep(components, formData) : components,
          };
        }
      } else if (!input && !_.isEmpty(columns)) {
        const newLabel = label ? label : 'items';
        // to fix later, this is getting the last column and not all the columns
        return {
          ...acc,
          [newLabel]: _.isArray(columns) ? formIOReplaceKeysValuesDeep(columns, formData) : columns,
        };
      } else if (!input && !_.isEmpty(rows)) {
        const newLabel = label ? label : 'items';
        return {
          ...acc,
          [newLabel]: _.isArray(rows) ? formIOReplaceKeysValuesDeep(rows, formData) : rows,
        };
      }

      // all else, return accumulative object
      return {
        ...acc,
      };
    },
    {}
  );
};

//this function will filter the consent form list
//based on the language selection and formName
//and return corresponding PatientFormTemplateID
export const getRequiredFormID = (
  currentLanguage: string,
  consentFormList: Record<'Language' | 'PatientFormTemplateID' | 'Title' | 'Version', string>[],
  formNames: string | string[]
): string => {
  let formNamesArr: string[];
  let formId = '';
  const languageArray = [currentLanguage, 'Multi', 'English'];

  if (typeof formNames === 'string') formNamesArr = [formNames];
  else formNamesArr = formNames;

  formNamesArr.reduce((acc, formName) => {
    for (let i = 0; i < languageArray.length; i++) {
      const requiredForm = consentFormList.filter((form) => {
        if (form.Language === languageArray[i] && form.Title === formName) return form;
      });
      //if we get the requiredForm then return PatientFormTemplateID from that
      if (requiredForm && requiredForm.length) {
        formId = acc
          ? `${acc},${requiredForm.map((obj) => obj?.PatientFormTemplateID).join(',')}`
          : requiredForm.map((obj) => obj?.PatientFormTemplateID).join(',');
        return formId;
      }
    }
    return formId;
  }, '');

  return formId;
};

//this function will filter the Questions form list
//based on the language selection and formName
//and return corresponding QuestionnaireTemplateID
export const getRequiredQuestionsFormID = (
  currentLanguage: string,
  questionsFormList: Record<
    'Language' | 'QuestionnaireType' | 'QuestionnaireTemplateID' | 'QuestionnaireTitle' | 'Version',
    string
  >[],
  formNames: string | string[]
): string => {
  let formNamesArr: string[];
  let formId = '';
  const languageArray = [currentLanguage, 'Multi', 'English'];

  if (typeof formNames === 'string') formNamesArr = [formNames];
  else formNamesArr = formNames;

  formNamesArr.reduce((acc, formName) => {
    for (let i = 0; i < languageArray.length; i++) {
      const requiredForm = questionsFormList.filter((form) => {
        if (form.Language === languageArray[i] && form.QuestionnaireTitle === formName) return form;
      });
      //if we get the requiredForm then return PatientFormTemplateID from that
      if (requiredForm && requiredForm.length) {
        formId = acc
          ? `${acc},${requiredForm.map((obj) => obj?.QuestionnaireTemplateID).join(',')}`
          : requiredForm.map((obj) => obj?.QuestionnaireTemplateID).join(',');
        return formId;
      }
    }
    return formId;
  }, '');
  return formId;
};

export const getMandatoryFieldsFromQuestionsPayload = (
  questionsResponse: ScheduleQuestionResponseObject[]
): string[] => {
  return questionsResponse.reduce((acc: string[], arr) => {
    const mandatoryFields = arr.questions
      .filter((ques) => ques.properties?.rules?.required)
      .map((qus) => qus.QuestionnaireTemplateQuestionID);
    return [...acc, ...mandatoryFields];
  }, []);
};

//prepare regular expression to check DOM object
const DOMObjectsRegEx = new RegExp('(?:[<,</](' + DOMObjectsJson.DOMObjects.join('|') + ')[>,\\s])');

//to check whether a string contains DOM object or not
export const IsDOMObjectsExists = (data: string): boolean => {
  return DOMObjectsRegEx.test(data.toLowerCase());
};

export const isAgeWithinRange = (
  dateOfBirth: string,
  firstAge: number,
  secondAge: number | undefined,
  operand: string
): boolean => {
  let result = false;
  try {
    const cAge = moment().diff(moment(dateOfBirth, 'MM/DD/YYYY hh:mm:ss'), 'years');
    if (operand !== 'between') {
      // check if age is within limits
      const op = operand === '=' ? '===' : operand;
      result = eval(cAge + op + firstAge);
    } else {
      // check if date of birth is between these both age values
      if (secondAge) {
        result = eval(firstAge + '<=' + cAge) && eval(cAge + '<=' + secondAge);
      }
    }
  } catch (error) {
    console.log(error);
  }
  return result;
};

// function for replacing the token with actual info in the confirmation message
export const replaceTokenWithInfo = (message: string, token: string, info: string): string => {
  // fix for some browsers replaceAll doesn't exist
  // The Characters [, ] have special meaning in  Regular Expressions
  // So, if token contains square brackets [  ], then extracting the content within those brackets
  // and creating Regular Expression for that content.
  // Ex: if token value is [Practice Name] then constructing the regex value as /\[Practice Name\]/g
  const tokenWithoutBrackets = token.substring(1, token.length - 1);
  const replaceWhat = new RegExp('\\[' + tokenWithoutBrackets + '\\]', 'g');
  return message.replace(replaceWhat, info);
};
