import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getLabResultsConfig, LabResultsConfigResponse } from 'services';

export type State = ApiState<LabResultsConfigResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchLabResultsConfig = createAsyncThunk('get/testResultConfig', async () => await getLabResultsConfig());

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const labResultsConfigSlice = createSlice({
  name: 'labResultsConfig',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLabResultsConfig.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchLabResultsConfig.fulfilled, (state, action: PayloadAction<LabResultsConfigResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchLabResultsConfig.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const labResultsConfigSelctor = (state: { labResultsConfig: State }): State => state.labResultsConfig;

export const labResultsConfig_ = createSelector(labResultsConfigSelctor, (state) => state.data);

export default labResultsConfigSlice.reducer;
