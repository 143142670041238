import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { CareTeamConfig, getCareTeamItems } from 'services/careTeam-config';

export type State = ApiState<CareTeamConfig, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchCareTeamItems = createAsyncThunk('get/careTeamItems', async () => await getCareTeamItems());

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const careTeamItemConfigSlice = createSlice({
  name: 'careTeamItemsConfig',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCareTeamItems.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCareTeamItems.fulfilled, (state, action: PayloadAction<CareTeamConfig>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchCareTeamItems.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const careTeamItemsConfigSelctor = (state: { careTeamItemsConfig: State }): State => state.careTeamItemsConfig;

export const careTeamItemsConfig_ = createSelector(careTeamItemsConfigSelctor, (state) => state.data);

export default careTeamItemConfigSlice.reducer;
