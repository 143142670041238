import { SchedulePayload } from 'models';
import { tokenAuthApiInstance } from 'utils/auth';

import { ADD_SCHEDULE_APPOINTMENT } from '../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createScheduleAppointment = async (scheduleAppPayload: SchedulePayload): Promise<any> => {
  const response = await tokenAuthApiInstance.authAxios().post(ADD_SCHEDULE_APPOINTMENT, scheduleAppPayload);
  return response.data;
};
