import { tokenAuthApiInstance } from 'utils/auth';
import { Logger } from 'utils/logger';

import { FORM_IO_DATA_GET_ENDPOINT, FORM_IO_GET_ENDPOINT, getMultipleFormsEndpoint } from '../constants';

export type radioValue = {
  label: string;
  value: string;
  shortcut: string;
};

export type FormComponent = {
  autoExpand?: boolean;
  autofocus?: boolean;
  case?: string;
  defaultValue?: string;
  disableOnInvalid?: boolean | undefined;
  hidden?: boolean;
  inline?: boolean | undefined;
  input: boolean;
  key: string;
  label: string;
  optionsLabelPosition?: string | undefined;
  redrawOn?: string;
  saveOnEnter?: boolean;
  showValidations?: boolean;
  tableView?: boolean;
  type: string;
  validate?: {
    required: boolean;
  };
  values?: Array<radioValue> | undefined;
};

export type FormDetails = {
  components: Array<FormComponent>;
  formID: string;
  formName: string;
  formPath: string;
  formTitle: string;
  display: string;
  practiceID: string;
  practiceName: string;
};

export type FormData = {
  formID: string;
  submittedID: string;
  data?: Record<string, unknown>;
};

export const getFormDetailsForSingleOrMultipleForms = async (
  formName: string | string[]
): Promise<FormDetails | undefined> => {
  try {
    if (typeof formName === 'string') {
      const response = await tokenAuthApiInstance.authAxios([formName]).get(FORM_IO_GET_ENDPOINT);
      return response.data;
    }
    if (Array.isArray(formName)) {
      const response = await tokenAuthApiInstance.authAxios([formName.join(',')]).get(getMultipleFormsEndpoint);
      return response.data;
    }
    /* eslint-disable @typescript-eslint/no-explicit-any */
  } catch (error) {
    if (error) {
      const message = `Got error in getting the form.io details ${JSON.stringify(error)}`;
      Logger.error(message);
    }
    throw error;
  }
};
export const getFormData = async (Payload: Record<string, string>): Promise<FormData | undefined> => {
  try {
    const { formName, formID } = Payload;
    const response = await tokenAuthApiInstance.authAxios([formName, formID]).get(FORM_IO_DATA_GET_ENDPOINT);
    return response.data;
    /* eslint-disable @typescript-eslint/no-explicit-any */
  } catch (error) {
    if (error) {
      const message = `Got error in getting the form.io data ${JSON.stringify(error)}`;
      Logger.error(message);
    }
    throw error;
  }
};
