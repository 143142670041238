import { ScheduleAppointmentTypesResponse } from 'services';
import { tokenAuthApiInstance } from 'utils/auth';

import { SELF_SCHEDULE_APPOINTMENT_TYPES } from '../constants';

export const getSelfScheduleAppointmentTypes = async (): Promise<ScheduleAppointmentTypesResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(SELF_SCHEDULE_APPOINTMENT_TYPES);
  if (response && response.data && response.data.AppointmentType) {
    return response.data.AppointmentType;
  }
  return [];
};
