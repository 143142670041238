import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { EHRConfigurationsResponse, getEHRConfigurations } from 'services';

export type State = ApiState<EHRConfigurationsResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchEHRConfigurations = createAsyncThunk('get/configurations', async () => await getEHRConfigurations());

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const EHRConfigurationsSlice = createSlice({
  name: 'EHRConfigurations',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEHRConfigurations.pending, (state) => {
      state.isLoading = true;
      state.hasLoaded = false;
    });

    builder.addCase(fetchEHRConfigurations.fulfilled, (state, action: PayloadAction<EHRConfigurationsResponse>) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.data = action.payload;
    });

    builder.addCase(fetchEHRConfigurations.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { configurations: State }): State => state.configurations;

export const EHRConfigurations_ = createSelector(stateSelector_, (state) => state.data);

export const EHRConfigurationsHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const EHRConfigurationsIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export default EHRConfigurationsSlice.reducer;
