import { tokenAuthApiInstance } from 'utils/auth';

import { QUESTIONNAIRE_LIST } from './constants';

export type QuestionnaireListType = {
  Language: string;
  QuestionnaireTemplateID: string;
  QuestionnaireTitle: string;
  QuestionnaireType: string;
  Version: string;
};
export const getQuestionnaireList = async (): Promise<QuestionnaireListType[]> => {
  const response = await tokenAuthApiInstance.authAxios().get(QUESTIONNAIRE_LIST);
  return response.data;
};
