export const schedulePayloadInitialState = {
  AppointmentRequestID: '',
  PatientID: '',
  AppointmentType: '',
  LocationName: '',
  LocationID: '',
  PreferredDateTime1: '',
  PreferredDateTime2: '',
  PreferredDateTime3: '',
  FirstName: '',
  MiddleName: '',
  LastName: '',
  Suffix: '',
  AddressLine1: '',
  AddressLine2: '',
  City: '',
  State: '',
  County: '',
  Zipcode: '',
  MobilePhone: '',
  HomePhone: '',
  EmailAddress: '',
  Gender: '',
  SexAssignedAtBirth: '',
  MaritalStatus: '',
  MaritalStatusID: 0,
  GenderIdentity: '',
  GenderIdentityID: 0,
  SexualOrientation: '',
  SexualOrientationID: 0,
  Religion: '',
  IsDeleted: false,
  RequestStatus: '',
  AppointmentQuestions: [],
  IsAppointmentReschedule: false,
  FormIO: [],
  ReasonForReschedule: '',
  IsSelfSchedule: false,
  IsDemographicsChanged: false,
};
