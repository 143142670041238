import { HOST_PROD_ENV, HOST_STAGE_ENV, HOST_TEST_ENV } from './constants';

const host = window.location.hostname;

export const HostEnv = {
  isLocal: (): boolean => {
    return host.includes('localhost');
  },
  isTest: (): boolean => {
    return host.includes(HOST_TEST_ENV);
  },
  isStage: (): boolean => {
    return host.includes(HOST_STAGE_ENV);
  },
  isProd: (): boolean => {
    // for prod it's exactly https://phcpx.com
    const href = window.location.href;
    return href.includes(`//${HOST_PROD_ENV}`);
  },
};
