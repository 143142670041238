import { tokenAuthApiInstance } from 'utils/auth';
import { tokenAuthApiInstanceWithToken } from 'utils/auth';

import { REQUEST_ACTIVATION_CODE_STATES_ENDPOINT, STATES_ENDPOINT } from '../constants';

export type StatesObjType = {
  StateCode: string;
  StateName: string;
};

export type StatesResponse = StatesObjType[];

export const getStates = async (): Promise<StatesResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(STATES_ENDPOINT);
  return response.data;
};

export const requestActivation_GetStates = async (token: string): Promise<StatesResponse> => {
  const response = await tokenAuthApiInstanceWithToken(token)
    .authAxios(undefined, 'v5')
    .get(REQUEST_ACTIVATION_CODE_STATES_ENDPOINT);
  return response.data;
};
