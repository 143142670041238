import { tokenAuthApiInstance } from 'utils/auth';

import { VITALS_SUMMARY } from '../../constants';

export type VitalsSummary = {
  MeasuredDate: string;
  PatientEncounterID: string;
  PatientID: string;
  rowcount: number;
  VitalsDate: string;
  Temperature: number;
  Weight: number;
};

export type VitalsSummaryResponse = VitalsSummary[];

export const getVitalsSummaries = async (): Promise<VitalsSummaryResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(VITALS_SUMMARY);
  return response.data;
};
