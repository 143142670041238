import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getHealthDepartmentLogo, HealthLogo } from 'services';

export type State = ApiState<HealthLogo, SerializedError>;

export const fetchHealthDeptLogo = createAsyncThunk('get/healthDeptLogo', async () => await getHealthDepartmentLogo());

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: '',
  error: null,
} as State;

const healthDeptLogo = createSlice({
  name: 'healthDeptLogo',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHealthDeptLogo.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchHealthDeptLogo.fulfilled, (state, action: PayloadAction<HealthLogo>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });
    builder.addCase(fetchHealthDeptLogo.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { healthDeptLogo: State }): State => state.healthDeptLogo;

export const healthDeptLogo_ = createSelector(stateSelector_, (state) => state.data);

export default healthDeptLogo.reducer;
