import { colors } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';

import typography from './typography';

const theme = createTheme({
  palette: {
    background: {
      default: '#F4F6F8',
      paper: colors.common.white,
    },
    primary: {
      contrastText: '#ffffff',
      main: '#2c3376',
    },
    secondary: {
      main: '#71aa42',
      contrastText: '#ffffff',
    },
    info: {
      main: colors.grey[600],
    },
  },
  typography,
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
      },
    },
  },
});

export default theme;
