import { tokenAuthApiInstance } from 'utils/auth';

import { REQUEST_PROXY_ACCESS, REVOKE_PROXY_ACCESS } from './constants';

export const submitProxyAccessRequest = async (payload: FormData): Promise<string> => {
  const response = await tokenAuthApiInstance.authAxios(undefined, 'v0').post(REQUEST_PROXY_ACCESS, payload);
  return response.data;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export const revokeProxyAccess = async (payload: any, practiceId: string, userId: string): Promise<string> => {
  const response = await tokenAuthApiInstance.authAxios([practiceId, userId]).post(REVOKE_PROXY_ACCESS, payload);
  return response.data;
};
