import axios from 'axios';
import { tokenAuthApiInstance } from 'utils/auth';

import {
  HEALTH_DEPT_LOGO,
  PRACTICE_INFO,
  PRACTICE_WORKING_HOURS_ENDPOINT,
  PROJECT_DETAILS_ENDPOINT,
} from './constants';

export type ProjectDetailsResponse = {
  id: number;
  title: string;
  author: string;
};
export type HealthDeptResponse = {
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  City: string;
  FaxNumber: string;
  LogoFileName: string;
  PracticeID: string;
  PracticeName: string;
  PrimaryPhoneNumber: string;
  State: string;
  Zipcode: string;
};

export type HealthLogo = string;

export type WorkingHoursResponse = {
  StartTime: string;
  EndTime: string;
  WorkDays: string;
};

export const getProjectDetails = async (): Promise<ProjectDetailsResponse> => {
  const details = await axios.get(PROJECT_DETAILS_ENDPOINT);
  return details.data;
};

export const getHealthDepartmentName = async (): Promise<HealthDeptResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(PRACTICE_INFO);
  return response.data;
};

export const getHealthDepartmentLogo = async (): Promise<HealthLogo> => {
  const response = await tokenAuthApiInstance.authAxios().get(HEALTH_DEPT_LOGO);
  return response.data;
};

export const getPracticeWorkingHours = async (): Promise<WorkingHoursResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(PRACTICE_WORKING_HOURS_ENDPOINT);
  return response.data;
};
