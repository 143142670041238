import { tokenAuthApiInstance } from 'utils/auth';
import { Logger } from 'utils/logger';

import { VISIT_RECORDS_CONFIG } from './constants';

export type VisitRecordsConfigType = {
  displaySummary: Array<string>;
};

export type VisitRecordsConfigResponse = VisitRecordsConfigType;

export const getVisitRecordsConfig = async (): Promise<VisitRecordsConfigResponse> => {
  try {
    const response = await tokenAuthApiInstance.authAxios().get(VISIT_RECORDS_CONFIG);
    const { visitRecordItems } = response.data;
    // add id field for data-grid
    return visitRecordItems;
  } catch (error) {
    Logger.error(error);
    throw error;
  }
};
