import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Card, CardActions, CardContent, IconButton, Typography } from '@material-ui/core';
import { Event, NearMe } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function CheckInComplete(): any {
  const history = useHistory();
  const { t } = useTranslation();
  function backToDashboard() {
    history.push('/eCheckIn');
  }
  return (
    <React.Fragment>
      <br />
      <Typography variant="h5" gutterBottom>
        eCheck-In Complete
      </Typography>
      <Card variant="outlined">
        <CardContent className="padB0">
          Thanks for using eCheck-In! <br />
          <small>The information you&lsquo;ve submitted is now on file.</small>
          <Box display="flex">
            <Box className="padR10">
              <p>Show the following QR code on the day of your appointment to the front desk staf</p>
            </Box>
            <Box className="pad10">
              <FontAwesomeIcon icon={['fas', 'qrcode']} className="iconSecondary" size="5x" />
            </Box>
          </Box>
        </CardContent>
      </Card>
      <br />
      <Card variant="outlined">
        <CardContent className="padB0">
          <Typography variant="h5"> Office Visit with Dr.xxxxxxx</Typography>
          <Box display="flex" alignContent="center">
            <FontAwesomeIcon icon={['far', 'clock']} className="iconSecondary" size="2x" />
            <Box display="flex" flexDirection="column" className="padL10">
              <span>Tuesday, November 10th, 2020</span>
              <span>1:30 PM EST</span>
              <Button color="secondary" size="small" startIcon={<Event />} className="align-left-btn">
                Add to calendar
              </Button>
            </Box>
          </Box>
          <br />
          <Box display="flex" alignContent="center">
            <FontAwesomeIcon icon={['fas', 'map-marker-alt']} className="iconSecondary" size="2x" />
            <Box display="flex" flexDirection="column" className="padL10">
              <span>Local Health Department</span>
              <span>111 West St</span>
              <Button color="secondary" size="small" startIcon={<NearMe />} className="align-left-btn">
                Directions
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Card>
      <Card variant="outlined" className="cardBlock">
        <CardActions className="card-footerbg-primary">
          <IconButton aria-label="payment">
            <FontAwesomeIcon icon={['fas', 'arrow-left']} className="iconFadeOut" size="xs" />
          </IconButton>
          <Button id="complete-checkin" size="small" fullWidth className="iconFadeOut" onClick={backToDashboard}>
            {t('backHome.backToDashboard')}
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  );
}
