import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getSelfScheduleLocations, SelfScheduleLocation } from 'services';

export type State = ApiState<SelfScheduleLocation[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchSelfScheduleLocations = createAsyncThunk(
  'get/selfScheduleLocations',
  async (appointmentType: string) => await getSelfScheduleLocations(appointmentType)
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const selfScheduleLocationsSlice = createSlice({
  name: 'selfScheduleLocations',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    unmountSelfScheduleLocations: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSelfScheduleLocations.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchSelfScheduleLocations.fulfilled, (state, action: PayloadAction<SelfScheduleLocation[]>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchSelfScheduleLocations.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { selfScheduleLocations: State }): State => state.selfScheduleLocations;

export const selfScheduleLocations_ = createSelector(stateSelector_, (state) => state.data);

export const selfScheduleLocationsIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const selfScheduleLocationsHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const { unmountSelfScheduleLocations } = selfScheduleLocationsSlice.actions;

export default selfScheduleLocationsSlice.reducer;
