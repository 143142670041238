import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { AppointmentCancellationResponse, getCancelledAppointments } from 'services';

export type State = ApiState<AppointmentCancellationResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchCancelledAppointments = createAsyncThunk(
  'get/cancelledAppointments',
  async (rowsPerPage: number) => await getCancelledAppointments(rowsPerPage)
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const cancelledAppointmentsSlice = createSlice({
  name: 'cancelledAppointments',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    unmountCancelledAppointments: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCancelledAppointments.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchCancelledAppointments.fulfilled,
      (state, action: PayloadAction<AppointmentCancellationResponse>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.hasLoaded = true;
      }
    );

    builder.addCase(fetchCancelledAppointments.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { cancelledAppointments: State }): State => state.cancelledAppointments;

export const cancelledAppointments_ = createSelector(stateSelector_, (state) => state.data);

export const cancelledAppointmentsIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const cancelledAppointmentsHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const { unmountCancelledAppointments } = cancelledAppointmentsSlice.actions;

export default cancelledAppointmentsSlice.reducer;
