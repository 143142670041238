import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { AppointmentSchedulerConfiguration, getAppointmentSchedulerConfiguration } from 'services';

export type State = ApiState<AppointmentSchedulerConfiguration, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchScheduleAppointmentConfiguration = createAsyncThunk(
  'scheduleAppointmentConfiguration',
  async () => await getAppointmentSchedulerConfiguration()
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const scheduleAppointmentConfigurationSlice = createSlice({
  name: 'scheduleAppointmentConfiguration',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    unmountScheduleAppointmentConfiguration: () => {
      return initialState;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchScheduleAppointmentConfiguration.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchScheduleAppointmentConfiguration.fulfilled,
      (state, action: PayloadAction<AppointmentSchedulerConfiguration>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.hasLoaded = true;
      }
    );

    builder.addCase(fetchScheduleAppointmentConfiguration.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

const extractAppointmentTypes = (state: State): Array<string> => {
  if (state.hasLoaded) {
    return state.data.appointmentScheduleItems.reduce(function (
      appointmentTypes: Array<string>,
      currentAppointmentItem
    ) {
      const apptTypes = [...appointmentTypes];
      if (currentAppointmentItem.visible) apptTypes.push(currentAppointmentItem.appointmentType);
      return apptTypes;
    },
    []);
  } else {
    return [];
  }
};

export const stateSelector_ = (state: { scheduleAppointmentConfiguration: State }): State =>
  state.scheduleAppointmentConfiguration;

export const scheduleAppointmentConfiguration_ = createSelector(stateSelector_, (state) => state.data);

export const AppointmentTypes_ = createSelector(stateSelector_, (state) => extractAppointmentTypes(state));

export const scheduleAppointmentConfigurationIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const scheduleAppointmentConfigurationHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const { unmountScheduleAppointmentConfiguration } = scheduleAppointmentConfigurationSlice.actions;

export default scheduleAppointmentConfigurationSlice.reducer;
