import { InternalAxiosRequestConfig } from 'axios';
import _ from 'lodash';
import store from 'reducers';
import { loaderSlice } from 'reducers/general/loader.slice';
import { addMessage } from 'reducers/general/toast.slice';
import { setTokenHasExpired } from 'reducers/general/token.slice';
import { hasTokenExpired } from 'services/auth-utils.api';
import { TOKEN_EXPIRED } from 'services/constants';
import { Strings } from 'utils';
import { TOAST_ALERT_SEVERITY } from 'utils/constants';
import { Logger } from 'utils/logger';

const { dispatch } = store;

export const successRequestInterceptor = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  // error message
  let errorMsg = '';
  // substitute url params if applicable, assume no other params are there but these two
  // this won't work if the order of the params is not PracticeID, then PatientID
  const { url, practiceID, patientID, headers, additionalParams } = config;
  if (headers) {
    const { Authorization } = headers;
    if (Authorization && Authorization instanceof String) {
      const token = Authorization.split(' ')[1];
      if (!_.isEmpty(token) && hasTokenExpired(token)) {
        dispatch(setTokenHasExpired());
        errorMsg = TOKEN_EXPIRED;
        Logger.error(errorMsg);
        throw new Error(errorMsg);
      }
    }
  }

  // check if the url has at least one param to substitute
  if (url?.includes('{{0}}')) {
    if (!additionalParams) {
      config.url = Strings.substitute(url, [practiceID, patientID]);
    } else {
      // substitute the url with additional params in the right order
      config.url = Strings.substitute(url, [practiceID, patientID, ...additionalParams]);
    }
  }

  // Somehow we are sending the url with no patientID and practiceID.  Check the token is passed.
  if (url?.includes('{{0}}') && _.isEmpty(practiceID)) {
    errorMsg = `Missing the practiceID in the AuthAxios for url: ${url.replace('{{0}}', 'xxx')}`;
    Logger.error(errorMsg);
    throw new Error(errorMsg);
  }
  if (url?.includes('{{1}}') && _.isEmpty(patientID)) {
    errorMsg = `Missing the patientID in the AuthAxios for url: ${url.replace('{{1}}', 'xxx')}`;
    Logger.error(errorMsg);
    throw new Error(errorMsg);
  }
  // Capture the request made
  Logger.info(`Start request: ${config.method?.toUpperCase()} ${config.url}`);
  // capture starting time
  config.requestStartedAt = new Date().getTime();
  //
  dispatch(loaderSlice.actions.addPendingRequest());
  return config;
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const errorRequestInterceptor = (error: any): Promise<any> => {
  dispatch(loaderSlice.actions.removePendingRequests());
  // capture error
  const { message } = error;
  if (message === TOKEN_EXPIRED) {
    dispatch(addMessage({ message: TOKEN_EXPIRED, severity: TOAST_ALERT_SEVERITY.WARNING }));
  } else {
    const errorMsg = `Request failed with message: ${message}`;
    dispatch(addMessage({ message: errorMsg, severity: TOAST_ALERT_SEVERITY.ERROR }));
  }
  return Promise.reject(error);
};
