import axios, { AxiosInstance } from 'axios';
import _ from 'lodash';
import store from 'reducers';
import { ProxyAccessListItem } from 'services';
import { AXIOS_TIMEOUT, SERVICE_HOST, SERVICE_PORT } from 'services/constants';
import { errorRequestInterceptor, successRequestInterceptor } from 'services/interceptors/request.interceptor';
import { errorResponseInterceptor, successResponseInterceptor } from 'services/interceptors/response.interceptor';
import { JWTHelper } from 'utils';

declare module 'axios' {
  export interface AxiosRequestConfig {
    practiceID: string;
    patientID: string;
    requestStartedAt?: number;
    additionalParams: Array<string> | undefined;
  }
}

class tokenAuthApi {
  private _token: string;
  private _idToken: string;
  private _switchPatientAcct: ProxyAccessListItem | undefined;

  constructor(token = '') {
    this._token = token;
    this._idToken = token;
    this._switchPatientAcct = undefined;
  }

  authAxios(
    additionalParams: Array<string> | undefined = undefined,
    version = 'v0',
    headers: Record<string, unknown> = {}
  ): AxiosInstance {
    // get custom attributes from the idToken and pass to the axios config
    let practiceID = '';
    let patientID = '';

    if (this._idToken && this._idToken.length > 0) {
      const jwt = JWTHelper.parse(this._idToken);

      const { extension_practiceID, extension_patientID, PracticeId } = jwt;
      // check for b2c token
      if (extension_practiceID) {
        practiceID = extension_practiceID;
        // forward requests to currently logged in patient account
        if (extension_patientID && _.isEmpty(this._switchPatientAcct)) {
          patientID = extension_patientID;
        } else if (this._switchPatientAcct) {
          // else switch request to linked patient account
          const { PatientID: pId } = this._switchPatientAcct;
          patientID = pId;
        }
      } else if (PracticeId) {
        const state = store?.getState();
        const { PatientID } = state?.kioskPersonalDetails?.data || {};
        // check for kiosk token
        practiceID = PracticeId;
        patientID = PatientID;
      }
    }

    const axiosInstance = axios.create({
      baseURL: `${SERVICE_HOST}:${SERVICE_PORT}/cpx-service/api/${version}`,
      headers: {
        ...headers,
        Authorization: `Bearer ${this._token}`,
      },
      timeout: AXIOS_TIMEOUT, // set timeout for the service request
      practiceID,
      patientID,
      additionalParams,
    });

    axiosInstance.interceptors.request.use(successRequestInterceptor, errorRequestInterceptor);
    axiosInstance.interceptors.response.use(successResponseInterceptor, errorResponseInterceptor);
    return axiosInstance;
  }

  get token() {
    return this._token;
  }

  set token(value) {
    this._token = value;
  }

  get idToken() {
    return this._idToken;
  }

  set idToken(value) {
    this._idToken = value;
  }

  get switchPatientAcct() {
    return this._switchPatientAcct;
  }

  set switchPatientAcct(value: ProxyAccessListItem | undefined) {
    this._switchPatientAcct = value;
  }
}

export const tokenAuthApiInstance = new tokenAuthApi();

export const tokenAuthApiInstanceWithToken = (token: string): tokenAuthApi => new tokenAuthApi(token);
