import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TermsAgreementType } from 'models';
import { resetAll } from 'reducers';

export const initialState = {
  agreeToTerms: false,
} as TermsAgreementType;

//https://redux-toolkit.js.org/api/createSlice
const proxyTermsSlice = createSlice({
  name: 'proxyRequestTerms',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    setTerms: (state, action: PayloadAction<TermsAgreementType>) => {
      state = action.payload as TermsAgreementType;
    },
    clearTerms: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { proxyRequestTerms: TermsAgreementType }): TermsAgreementType =>
  state.proxyRequestTerms;

export const proxyTerms_ = createSelector(stateSelector_, (state) => state.agreeToTerms);

export const { setTerms, clearTerms } = proxyTermsSlice.actions;

export default proxyTermsSlice.reducer;
