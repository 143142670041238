import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, makeStyles, Step, StepLabel, Stepper } from '@material-ui/core';
import { StepIconProps } from '@material-ui/core/StepIcon';
import clsx from 'clsx';
import React, { FunctionComponent } from 'react';
import theme from 'theme';

import Header from './../InnerHeader';
import CheckInComplete from './CompleteCheckIn';
import Confirm from './Confirm';
import Insurance from './Insurance';
import PersonalInfo from './PersonalInfo';
const steps = ['Personal Info', 'Insurance', 'Confrm'];
const useColorlibStepIconStyles = makeStyles({
  root: {
    zIndex: 1,
    color: '#ccc',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  active: {
    color: theme.palette.primary.main,
  },
  completed: {
    color: theme.palette.secondary.main,
  },
});

function ColorlibStepIcon(props: StepIconProps) {
  const classes = useColorlibStepIconStyles();
  const { active, completed } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <FontAwesomeIcon icon={['fas', 'user-circle']} />,
    2: <FontAwesomeIcon icon={['far', 'address-card']} />,
    3: <FontAwesomeIcon icon={['far', 'check-circle']} />,
  };

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  );
}
function getStepContent(step: number) {
  switch (step) {
    case 0:
      return <PersonalInfo />;
    case 1:
      return <Insurance />;
    case 2:
      return <Confirm />;
    default:
      throw new Error('Unknown step');
  }
}
const eCheck: FunctionComponent = () => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set<number>());
  const isStepOptional = (step: number) => {
    return step === 1;
  };

  // const isStepFailed = (step: number) => {
  //   return step === 1;
  // };

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(skipped.values());
      newSkipped.delete(activeStep);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  return (
    <div className="wrapper">
      <Header className="header" heading="eCheck-In" />
      <main className="echeckin-content-wrapper">
        <Stepper alternativeLabel activeStep={activeStep} className="stepper">
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
            <CheckInComplete />
          ) : (
            <React.Fragment>
              {getStepContent(activeStep)}
              <Box className="stepper-footer" display="flex" flexDirection="row">
                {activeStep !== 0 && (
                  <Button onClick={handleBack} variant="contained" color="primary" fullWidth>
                    Back &nbsp; <FontAwesomeIcon icon={['fas', 'arrow-left']} />
                  </Button>
                )}
                <Button variant="contained" color="primary" onClick={handleSkip} fullWidth>
                  <FontAwesomeIcon icon={['fas', 'undo']} flip="horizontal" /> &nbsp; Finish Later
                </Button>
                <Button variant="contained" color="primary" onClick={handleNext} fullWidth>
                  {activeStep === steps.length - 1 ? 'Complete eCheck-In' : 'Next'} &nbsp;{' '}
                  <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                </Button>
              </Box>
            </React.Fragment>
          )}
        </React.Fragment>
      </main>
    </div>
  );
};

export default eCheck;
