import { tokenAuthApiInstance } from 'utils/auth';

import { LOG_PATIENT_PORTAL_ACCESS, PATIENT_AUDIT_LOG } from '../constants';
export type AuditPayload = {
  userAction: string;
  portalModule: string;
  patientID: string;
  userID: string;
};

export type Audit = {
  UserID: string;
  PatientID: string;
  UserName: string;
  EmailAddress: string;
  UserAction: string;
  PortalModule: string;
  LogTime: string;
  PMRecordNumber: string;
  PATFNAME: string;
  PATMI: string;
  PATLNAME: string;
  Suffix: string;
  PatSex: string;
  USERFNAME: string;
  USERMI: string;
  USERLNAME: string;
  PATDOB: string;
  PatPhone: string;
  PatZip: string;
  PatAdderess1: string;
  PatAdderess2: string;
  PatAdderess3: string;
  PracticeID: string;
  PracticeName: string;
  PracAddress: string;
  PracCity: string;
  PracState: string;
  PracZip: string;
  PracPhone: string;
  PracFax: string;
  RowNumber: number;
  rowcount: number;
};
export type AuditInfoResponse = Array<Audit>;

export const savePatientPortalAccess = async (payload: Partial<AuditPayload>): Promise<string> => {
  const response = await tokenAuthApiInstance.authAxios().post(LOG_PATIENT_PORTAL_ACCESS, payload);
  return response.data;
};

export const getAuditLogInfo = async (Payload: Record<string, string>): Promise<AuditInfoResponse> => {
  const { userID, fromDate, toDate, rowsPerPage, pageNumber } = Payload;
  const response = await tokenAuthApiInstance
    .authAxios([userID, fromDate, toDate, rowsPerPage, pageNumber])
    .get(PATIENT_AUDIT_LOG);
  return response.data;
};
