/* eslint-disable @typescript-eslint/no-explicit-any */
import { EcheckinPayload } from 'models';
import { tokenAuthApiInstance } from 'utils/auth';

import { ECHECKIN_SAVE, KIOSK_ECHECKIN_SAVE } from '../constants';

export const postEcheckinOnComplete = async (
  payload: Partial<EcheckinPayload> & Record<string, unknown>
): Promise<any> => {
  const { EcheckinType = 'CPX' } = payload;
  if (EcheckinType === 'CPX') {
    const response = await tokenAuthApiInstance.authAxios().post(ECHECKIN_SAVE, payload);
    return response.data;
  } else if (EcheckinType === 'KIOSK') {
    const { ECheckInPatientQuestionnaires, PatientID, PracticeCalendarID, IsFormIO = true } = payload;
    const response = await tokenAuthApiInstance
      .authAxios([PracticeCalendarID as string, PatientID as string])
      .post(KIOSK_ECHECKIN_SAVE(IsFormIO), ECheckInPatientQuestionnaires);
    return response.data;
  }
};
