import { tokenAuthApiInstance } from 'utils/auth';

import { KIOSK_HEALTH_DEPT_LOGO } from '../constants';

export type HealthLogo = string;

export const getKioskHealthDepartmentLogo = async (): Promise<HealthLogo> => {
  const response = await tokenAuthApiInstance.authAxios().get(KIOSK_HEALTH_DEPT_LOGO);
  return response.data;
};
