import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Card, CardActions, CardContent, Grid, IconButton, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

const Appointments: FunctionComponent = () => {
  return (
    <main className="contentWrapper">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Upcoming Visits</Typography>
          <Card className="cardBlock" variant="outlined">
            <CardContent>
              <Box display="flex">
                <Box className="visit_date">
                  <h3>JUNE</h3>
                  <h1>3</h1>
                  <h3>2021</h3>
                </Box>
                <Box className="visit_info">
                  <h4 className="visit_title">Office Visit</h4>
                  <h4>Physician Name, MD</h4>
                  <p>Primary Care Location, Primary Care Location</p>
                </Box>
              </Box>
              <Box display="flex">
                <Box className="visit_date">
                  <h2>THU</h2>
                </Box>
                <Box className="visit_info">
                  <p>Arrive By, 10.00 AM</p>
                  <p>Starts At, 10.30 AM</p>
                </Box>
              </Box>
            </CardContent>
            <CardActions className="card-footerbg-primary">
              <Grid container>
                <Grid item xs={12}>
                  <Box display="flex" alignContent="center" className="fullWidth">
                    <IconButton aria-label="eCheckIn" className="btn-top-border btn-bottom-border">
                      <FontAwesomeIcon icon={['far', 'calendar-check']} size="xs" />
                    </IconButton>
                    <Button id="eCheckIn" size="small" fullWidth className="btn-top-border btn-bottom-border">
                      eCheckIn
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" alignContent="center" className="fullWidth">
                    <IconButton aria-label="cancel-visit">
                      <FontAwesomeIcon icon={['far', 'calendar-times']} size="xs" />
                    </IconButton>
                    <Button id="cancel-visit" size="small" fullWidth className="btn-right-border">
                      Cancel
                    </Button>
                    <IconButton aria-label="reschedule-visit">
                      <FontAwesomeIcon icon={['far', 'calendar-alt']} size="xs" />
                    </IconButton>
                    <Button id="reschedule-visit" size="small" fullWidth>
                      Reschedule
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Past Visits</Typography>
          <Card variant="outlined">
            <CardContent>
              <Box display="flex">
                <Box className="visit_date">
                  <h3>JUNE</h3>
                  <h1>3</h1>
                  <h3>2021</h3>
                </Box>
                <Box className="visit_info">
                  <h4 className="visit_title">Office Visit</h4>
                  <h4>Physician Name, MD</h4>
                  <p>Primary Care Location, Primary Care Location</p>
                </Box>
              </Box>
              <Box display="flex">
                <Box className="visit_date">
                  <h2>THU</h2>
                </Box>
                <Box className="visit_info">
                  <p>Arrive By, 10.00 AM</p>
                  <p>Starts At, 10.30 AM</p>
                </Box>
              </Box>
            </CardContent>
          </Card>
          <br />
          <Card variant="outlined">
            <CardContent>
              <Box display="flex">
                <Box className="visit_date">
                  <h3>JUNE</h3>
                  <h1>3</h1>
                  <h3>2021</h3>
                </Box>
                <Box className="visit_info">
                  <h4 className="visit_title">Office Visit</h4>
                  <h4>Physician Name, MD</h4>
                  <p>Primary Care Location, Primary Care Location</p>
                </Box>
              </Box>
              <Box display="flex">
                <Box className="visit_date">
                  <h2>THU</h2>
                </Box>
                <Box className="visit_info">
                  <p>Arrive By, 10.00 AM</p>
                  <p>Starts At, 10.30 AM</p>
                </Box>
              </Box>
            </CardContent>
          </Card>
          <br />
          <Card variant="outlined">
            <CardContent>
              <Box display="flex">
                <Box className="visit_date">
                  <h3>JUNE</h3>
                  <h1>3</h1>
                  <h3>2021</h3>
                </Box>
                <Box className="visit_info">
                  <h4 className="visit_title">Office Visit</h4>
                  <h4>Physician Name, MD</h4>
                  <p>Primary Care Location, Primary Care Location</p>
                </Box>
              </Box>
              <Box display="flex">
                <Box className="visit_date">
                  <h2>THU</h2>
                </Box>
                <Box className="visit_info">
                  <p>Arrive By, 10.00 AM</p>
                  <p>Starts At, 10.30 AM</p>
                </Box>
              </Box>
            </CardContent>
          </Card>
          <br />
        </Grid>
      </Grid>
    </main>
  );
};

export default Appointments;
