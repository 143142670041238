import { tokenAuthApiInstance } from 'utils/auth';

import { VITALS_DETAILS } from '../../constants';

export type VitalsDetails = {
  PatientMeasureID: string;
  MeasureType: string;
  MeasureValue1: string;
  MeasureUnit1: string;
  MeasurePercentile: number;
  MeasureValue2: string;
  MeasureUnit2: string;
};

export type VitalDetailsResponse = VitalsDetails[];

export type vitalsDetailsPyload = {
  patientEncounterID: string;
  vitalsDate: string;
};

export const getVitalsDetails = async (payload: vitalsDetailsPyload): Promise<VitalDetailsResponse> => {
  const { patientEncounterID, vitalsDate } = payload;
  const response = await tokenAuthApiInstance.authAxios([patientEncounterID, vitalsDate]).get(VITALS_DETAILS);
  return response.data;
};
