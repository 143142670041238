import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { FilterFieldOption } from 'components/common/form';
import { ApiState } from 'models';
import { requestActivationGetStatesWithCountryCode, StatesResponse } from 'services';

export type State = ApiState<FilterFieldOption[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchRequestActivationCodeStatesWithCountryCode = createAsyncThunk(
  'get/requestActivationStatesWithCountryCode',
  async (country: string) => await requestActivationGetStatesWithCountryCode(country)
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const statesWithCountryCodeSlice = createSlice({
  name: 'requestActivationStatesWithCountryCode',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRequestActivationCodeStatesWithCountryCode.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchRequestActivationCodeStatesWithCountryCode.fulfilled,
      (state, action: PayloadAction<StatesResponse>) => {
        state.isLoading = false;
        state.data = action.payload.map(({ StateCode, StateName }) => ({ id: StateCode, label: StateName }));
        state.hasLoaded = true;
      }
    );

    builder.addCase(fetchRequestActivationCodeStatesWithCountryCode.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { requestActivationCodestatesWithCountryCode: State }): State =>
  state.requestActivationCodestatesWithCountryCode;

export const requestActivationStatesWithCountryCode_ = createSelector(stateSelector_, (state) => state.data);

export const requestActivationStatesWithCountryCodeIsLoading_ = createSelector(
  stateSelector_,
  (state) => state.isLoading
);

export const requestActivationStatesWithCountryCodeHasLoaded_ = createSelector(
  stateSelector_,
  (state) => state.hasLoaded
);

export default statesWithCountryCodeSlice.reducer;
