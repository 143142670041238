import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getTelehealthLink, TelehealthLinkPayload, TelehealthLinkResponse } from 'services';

export type State = ApiState<TelehealthLinkPayload, SerializedError>;

export type TelehealthLinkPayloadAction = {
  data: Partial<TelehealthLinkPayload> & Record<string, unknown>;
};

export const telehealthLinkInitialState = {
  PatientName: '',
  PatientId: '',
  StartDate: '',
  EndDate: '',
  GroupID: '',
  LocationID: '',
  CreatedByUserName: '',
  IsReactTelehealthAppEnabled: '',
  user_identity: '',
  room_name: '',
  user_type: '',
  room_type: '',
  token: '',
  create_conversation: false,
  isGroupSession: false,
  SessionData: {
    Name: '',
    TwilioRoomSid: '',
    PatientUrl: '',
    IsDisconnected: false,
  },
};

export const fetchTelehealthLink = createAsyncThunk(
  'post/telehealthLink',
  async (payload: TelehealthLinkPayload) => await getTelehealthLink(payload)
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: telehealthLinkInitialState,
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const telehealthLinkSlice = createSlice({
  name: 'telehealthLink',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    resetTelehealthLinkPayload: () => {
      return initialState;
    },
    updateTelehealthLinkPayload: (state, action: PayloadAction<TelehealthLinkPayloadAction>) => {
      state.data = { ...state.data, ...action.payload.data };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTelehealthLink.pending, (state) => {
      state.isLoading = true;
      state.hasLoaded = false;
    });

    builder.addCase(fetchTelehealthLink.fulfilled, (state, action: PayloadAction<TelehealthLinkResponse>) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.data = { ...state.data, SessionData: action.payload };
    });

    builder.addCase(fetchTelehealthLink.rejected, (state, action) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { telehealthLink: State }): State => state.telehealthLink;

export const telehealthLink_ = createSelector(stateSelector_, (state) => {
  return state.data;
});
export const telehealthLinkHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);
export const telehealthLinkIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const { resetTelehealthLinkPayload, updateTelehealthLinkPayload } = telehealthLinkSlice.actions;
export default telehealthLinkSlice.reducer;
