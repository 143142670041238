import { ConsentFormElement } from 'models';
import { tokenAuthApiInstance } from 'utils/auth';

import { EHR_CONSENT_FORMS_DATA_ENDPOINT } from '../constants';
import { ConsentForm } from './consent-form.api';

export type EHRConsentFormData = {
  ConsentFormContents: Array<ConsentForm>;
  ConsentFormElements: Array<ConsentFormElement>;
};

export const getEHRConsentFormsData = async (patientQuestionnaireIDs: string): Promise<EHRConsentFormData> => {
  const response = await tokenAuthApiInstance.authAxios([patientQuestionnaireIDs]).get(EHR_CONSENT_FORMS_DATA_ENDPOINT);
  return response.data;
};
