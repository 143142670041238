/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { FilterFieldOption } from 'components/common/form';
import { ApiState } from 'models';
import { CountiesResponse, CountyPayload, getRequestActivationCounties } from 'services';

export type State = ApiState<FilterFieldOption[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchRequestActivationCounties = createAsyncThunk(
  'get/counties',
  async (payload: CountyPayload) => await getRequestActivationCounties(payload)
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const countiesSlice = createSlice({
  name: 'counties',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    unmountRequestActivationCounties: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRequestActivationCounties.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchRequestActivationCounties.fulfilled, (state, action: PayloadAction<CountiesResponse>) => {
      state.isLoading = false;
      state.data = action.payload.map(({ County }) => ({ id: County, label: County }));
      state.hasLoaded = true;
    });

    builder.addCase(fetchRequestActivationCounties.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { requestActivationCounties: State }): State => state.requestActivationCounties;

export const requestActivationCounties_ = createSelector(stateSelector_, (state) => state.data);

export const { unmountRequestActivationCounties } = countiesSlice.actions;

export default countiesSlice.reducer;
