import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { FilterFieldOption } from 'components/common/form';
import { ApiState } from 'models';
import { requestActivation_GetStates, StatesResponse } from 'services';

export type State = ApiState<FilterFieldOption[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchRequestActivationStates = createAsyncThunk(
  'get/requestActivation/states',
  async (token: string) => await requestActivation_GetStates(token)
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const statesSlice = createSlice({
  name: 'requestActivationStates',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRequestActivationStates.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchRequestActivationStates.fulfilled, (state, action: PayloadAction<StatesResponse>) => {
      state.isLoading = false;
      state.data = action.payload.map(({ StateCode, StateName }) => ({ id: StateCode, label: StateName }));
      state.hasLoaded = true;
    });

    builder.addCase(fetchRequestActivationStates.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const requestActivationStateSelector_ = (state: { requestActivationCodeStates: State }): State =>
  state.requestActivationCodeStates;

export const requestActivationState_ = createSelector(requestActivationStateSelector_, (state) => state.data);

export const requestActivationStateIsLoading_ = createSelector(
  requestActivationStateSelector_,
  (state) => state.isLoading
);

export const requestActivationStateHasLoaded_ = createSelector(
  requestActivationStateSelector_,
  (state) => state.hasLoaded
);

export default statesSlice.reducer;
