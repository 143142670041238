import { tokenAuthApiInstance } from 'utils/auth';

import {
  BILLING_DETAILS_LIST_ENDPOINT,
  ONLINE_TRANSACTION_DETAILS_ENDPOINT,
  ONLINE_TRANSACTION_URL_ENDPOINT,
  PAYMENTS_LIST_ENDPOINT,
} from '../constants';

export type BatchWiseClaimBalanceType = {
  BatchID: number;
  ClaimID: number;
  PatientID: number;
  TotalCharge: number;
  PayerPaid: number;
  PatientPaid: number;
  PayerName: string;
  ProviderName: string;
  FacilityName: string;
  PracticeName: string;
  InsuredID: string;
  InsuredName: string;
  CreatedDate: Date | string;
};

export type BillingDetailsResponse = {
  PatientBalances: {
    AccountNumber: string;
    BalanceDue: number;
    PatientID: number;
  } | null;
  BatchwiseClaimBalances: Array<BatchWiseClaimBalanceType> | null;
};

export type PaymentType = {
  OnlinePatientPaymentID: number;
  PatientID: number;
  PaymentDate: Date | string;
  TotalAmount: number;
  TotalPaid: number;
  OnlineTransactionID: number;
  GatewayTransactionID: string;
  AuthenticationNo: string;
  CardNumber: string;
  ExpMonth: string;
  ExpYear: string;
  PaymentStatus: string;
  InsertedBy: number;
  InsertDate: Date | string;
  LastEditedBy: number;
  LastEditDate: Date | string;
  CardType: string;
  PaymentSource: string;
};

export type TransactionAndInsteMedResponse = {
  OnlineTransactionID: number;
  id: string;
  cancelURL: string;
  confirmURL: string;
  accountID: string;
  securityKey: string;
  ssoAlias: string;
  relayState: string;
  baseURL: string;
};

export type OnlinePaymentURLPayloadType = {
  PhoenixPatientID: number;
  TotalAmount: number;
  TotalPaid: number;
  OnlineTransactionID: number;
  patientFirstName: string;
  patientLastName: string;
  patientEmailAddress: string;
  userName: string;
  userID: string;
  PatientID: number;
};

export type TransactionDetailsResponse = {
  TokenId: string | null;
  PaymentTransactionID: string | null;
  ResponseMessage: string | null;
  PaymentStatus: string | null;
  TransactionDate: string | null;
  CardType: string | null;
};

export const getBillingDetails = async (phoenixPatientID: number): Promise<BillingDetailsResponse> => {
  const response = await tokenAuthApiInstance
    .authAxios([phoenixPatientID?.toString()])
    .get(BILLING_DETAILS_LIST_ENDPOINT);
  if (response.data) {
    return response.data;
  } else {
    return { PatientBalances: null, BatchwiseClaimBalances: [] };
  }
};

export const getPaymentsList = async (phoenixPatientID: number): Promise<Array<PaymentType>> => {
  const response = await tokenAuthApiInstance.authAxios([phoenixPatientID?.toString()]).get(PAYMENTS_LIST_ENDPOINT);
  if (response.data) {
    const { patientOnlinePayments = [] } = response?.data;
    return patientOnlinePayments;
  } else {
    return [];
  }
};

export const getOnlineTransactionURL = async (paymentURLRequest: OnlinePaymentURLPayloadType): Promise<string> => {
  const response = await tokenAuthApiInstance.authAxios().post(ONLINE_TRANSACTION_URL_ENDPOINT, paymentURLRequest);

  return response?.data;
};

export const getTransactionDetails = async (
  transactionID: number,
  isCancelled = false
): Promise<TransactionDetailsResponse> => {
  const response = await tokenAuthApiInstance
    .authAxios([transactionID?.toString(), isCancelled.toString()])
    .get(ONLINE_TRANSACTION_DETAILS_ENDPOINT);

  return response?.data;
};
