import { SEND_MAIL_WITH_ATTACHMENT_ENDPOINT } from 'services/constants';
import { tokenAuthApiInstance } from 'utils/auth';

export type MailPayload = {
  Attachment: string;
  FromEmailAddress: string;
  FromEmailFullName: string;
  Message: string;
  Receivers: string[];
  Subject: string;
  AttachmentPassword?: string;
};

export const sendMailWithAttchment = async (
  payload: MailPayload,
  patientName: string,
  isDirectMail: boolean
): Promise<string> => {
  const response = await tokenAuthApiInstance
    .authAxios([patientName, isDirectMail.toString()])
    .post(SEND_MAIL_WITH_ATTACHMENT_ENDPOINT, payload);
  return response.data;
};
