import _ from 'lodash';

import { BROWSER_TYPES, DEVICE_TYPES } from './constants';

declare global {
  interface Navigator {
    /* eslint-disable @typescript-eslint/no-explicit-any */
    userAgentData?: any;
  }
}

// detect client browser
export const Browser = {
  get: (): string => {
    let sBrowser = 'unknown';
    const { userAgent, userAgentData } = window.navigator;
    if (userAgent) {
      const sUsrAg = userAgent;
      if (sUsrAg.indexOf(BROWSER_TYPES.FIREFOX.KEY) > -1) {
        sBrowser = BROWSER_TYPES.FIREFOX.VALUE;
        // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
      } else if (sUsrAg.indexOf(BROWSER_TYPES.SAMSUNG.KEY) > -1) {
        sBrowser = BROWSER_TYPES.SAMSUNG.VALUE;
        // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36 (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
      } else if (sUsrAg.indexOf(BROWSER_TYPES.OPERA.KEY) > -1 || sUsrAg.indexOf(BROWSER_TYPES.OPR.KEY) > -1) {
        sBrowser = BROWSER_TYPES.OPERA.VALUE;
        // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
      } else if (sUsrAg.indexOf(BROWSER_TYPES.TRIDENT.KEY) > -1) {
        sBrowser = BROWSER_TYPES.TRIDENT.VALUE;
        // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
      } else if (sUsrAg.indexOf(BROWSER_TYPES.EDGE.KEY) > -1) {
        sBrowser = BROWSER_TYPES.EDGE.VALUE;
        // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
      } else if (sUsrAg.indexOf(BROWSER_TYPES.CHROME.KEY) > -1) {
        sBrowser = BROWSER_TYPES.CHROME.VALUE;
        // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
      } else if (sUsrAg.indexOf(BROWSER_TYPES.SAFARI.KEY) > -1 && sUsrAg.indexOf('CriOS/') > -1) {
        sBrowser = BROWSER_TYPES.CHROME.VALUE;
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 14_8 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) CriOS/96.0.4664.116 Mobile/15E148 Safari/604.1"
      } else if (sUsrAg.indexOf(BROWSER_TYPES.SAFARI.KEY) > -1 && sUsrAg.indexOf('Version/') > -1) {
        sBrowser = BROWSER_TYPES.SAFARI.VALUE;
        // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
      } else if (sUsrAg.indexOf(BROWSER_TYPES.SAFARI.KEY) > -1) {
        sBrowser = BROWSER_TYPES.SAFARI.VALUE;
        // emulator - Mozilla/5.0 (iPhone; CPU iPhone OS 13_2_3 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/13.0.3 Mobile/15E148 Safari/604.1
      }
    } else if (userAgentData && !_.isEmpty(userAgentData.brands) && !_.isEmpty(userAgentData.brands[0].brand)) {
      // userAgentData is only supported in Chrome, Edge, Opera, Chrome Android, Opera Android and https
      // for some browsers the brand maybe empty
      /* eslint-disable @typescript-eslint/no-explicit-any */
      userAgentData.brands.forEach((b: any) => {
        const { brand } = b;
        if (brand) {
          if (brand === 'Google Chrome') {
            sBrowser = BROWSER_TYPES.CHROME.VALUE;
          } else if (brand === 'Microsoft Edge') {
            sBrowser = BROWSER_TYPES.EDGE.VALUE;
          } else if (brand.toLocaleLowerCase().includes('opera')) {
            sBrowser = BROWSER_TYPES.OPERA.VALUE;
          }
        }
      });
    }
    return sBrowser;
  },
};

export const Device = {
  get: (): string => {
    let sDevice = 'Desktop';
    const sUsrAg = window.navigator.userAgent;
    if (sUsrAg.match(/Android/i)) {
      sDevice = DEVICE_TYPES.ANDROID;
    } else if (sUsrAg.match(/iPhone/i)) {
      sDevice = DEVICE_TYPES.IPHONE;
    } else if (sUsrAg.match(/iPad/i)) {
      sDevice = DEVICE_TYPES.IPAD;
    } else if (sUsrAg.match(/iPod/i)) {
      sDevice = DEVICE_TYPES.IPOD;
    } else if (sUsrAg.match(/BlackBerry/i)) {
      sDevice = DEVICE_TYPES.BLACKBERRY;
    } else if (sUsrAg.match(/Windows Phone/i)) {
      sDevice = DEVICE_TYPES.WINDOWSPHONE;
    }
    return sDevice;
  },
};
