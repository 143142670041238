import { FieldOption, FormFieldType } from 'components/common/form/dynamic-form/dynamic-form.container';
import { tokenAuthApiInstance } from 'utils/auth';

import { APPOINTMENT_REQUEST_QUESTIONNAIRE, SCHEDULE_QUESTIONS } from '../constants';

export type ScheduleQuestionField = {
  QuestionnaireTemplateQuestionID: string;
  PatientQuestionnaireID: string;
  QuestionName: string;
  DisplayName: string;
  AnswerType: keyof typeof FormFieldType;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties: Record<string, any>;
  AnswerValue: string | number | Record<string, unknown>[] | Record<string, boolean> | string[] | FieldOption[];
  DisplayOrder: number;
  // DefaultAnswerValue: string | number | boolean;
  ToolTip: string;
  IsMandatory?: boolean;
  Answer?: string | boolean;
  /**checkboxgroup */
  checkboxLabels?: Record<string, string>;
};

// Sample object for SubHeader in Form

// const formSubHeaderSmapleObj = {
//   QuestionnaireTemplateQuestionID: '',
//   PatientQuestionnaireID: '',
//   QuestionName: '',
//   DisplayName: 'Sample SubHeader',
//   AnswerType: 'subHeader' as keyof typeof FormFieldType,
//   properties: {
//     headerVariant: 'h5',
//   },
//   AnswerValue: '',
//   DisplayOrder: 10,
//   ToolTip: '',
// };

export type ScheduleQuestionFieldResponse = ScheduleQuestionField[];
export type ScheduleQuestionResponse = {
  questionnairesTemplateID: string;
  PatientQuestionnaireID: string;
  questions: ScheduleQuestionField[];
}[];
export type ScheduleQuestionResponseObject = {
  questionnairesTemplateID: string;
  PatientQuestionnaireID: string;
  questions: ScheduleQuestionField[];
};
export const getScheduleQuestions = async (
  questionnaireTemplateIDs: string
): Promise<ScheduleQuestionResponseObject> => {
  const response: ScheduleQuestionResponse = (
    await tokenAuthApiInstance.authAxios([questionnaireTemplateIDs]).get(SCHEDULE_QUESTIONS)
  ).data;
  return response[0];
};
export const getScheduleQuestionsV2 = async (
  questionnaireTemplateIDs: string
): Promise<ScheduleQuestionResponseObject[]> => {
  const response: ScheduleQuestionResponse = (
    await tokenAuthApiInstance.authAxios([questionnaireTemplateIDs]).get(SCHEDULE_QUESTIONS)
  ).data;
  return response;
};

export const getRequestQuestionnaire = async (
  Payload: Record<string, string>
): Promise<ScheduleQuestionFieldResponse> => {
  const { AppointmentRequestID, PracticeCalendarID } = Payload;
  const url = `${APPOINTMENT_REQUEST_QUESTIONNAIRE}?appointmentRequestID=${AppointmentRequestID}&practiceCalendarID=${PracticeCalendarID}`;
  const response: ScheduleQuestionFieldResponse = (await tokenAuthApiInstance.authAxios().get(url)).data;
  return response;
};
