import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Person } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import Header from '../InnerHeader';

const Msg_Inbox: FunctionComponent = () => {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);

  function handleClickAdvice() {
    history.push('/ux/mobile/GetMedicalAdvice');
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [value, setValue] = React.useState(0);
  return (
    <div className="wrapper">
      <Header className="header" heading="Inbox" />
      <main className="pad10">
        <Box id="Messages-conainer">
          <Box className="msg-row">
            <a className="msg-content" href="#">
              <Avatar className="msg-icon" color="secondary">
                <Person />
              </Avatar>
              <Box className="msg-middle">
                <h4>Nurse</h4>
                <h5>Provider Notes/Schedule a Follo...</h5>
                <p>Thank you for your recent visit to...</p>
              </Box>
            </a>
            <Box>
              <small>12/20/20</small>
            </Box>
          </Box>
          <Box className="msg-row">
            <a className="msg-content" href="#">
              <Avatar className="msg-icon" color="secondary">
                <Person />
              </Avatar>
              <Box className="msg-middle">
                <h4>Nurse</h4>
                <h5>Provider Notes/Schedule a Follo...</h5>
                <p>Thank you for your recent visit to...</p>
              </Box>
            </a>
            <Box>
              <small>12/20/20</small>
            </Box>
          </Box>
        </Box>
      </main>
      <footer className="inbox-footer">
        <Button
          variant="contained"
          color="primary"
          onClick={handleClickOpen}
          startIcon={<FontAwesomeIcon icon={['fas', 'share-square']} className="iconSecondary" />}
          fullWidth
          className="floatBtn"
        >
          Send a Message
        </Button>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="select-msg-type"
          aria-describedby="select-msg-type-description"
        >
          <DialogTitle>
            <Typography variant="h4" color="secondary">
              Select a message type
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <List>
                <ListItem>
                  <ListItemText primary="Medical Advice" onClick={handleClickAdvice} />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Customer Service" />
                </ListItem>
              </List>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              CANCEL
            </Button>
          </DialogActions>
        </Dialog>

        <BottomNavigation
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
        >
          <BottomNavigationAction label="Inbox" icon={<FontAwesomeIcon icon={['fas', 'inbox']} size="lg" />} />
          <BottomNavigationAction label="Sent" icon={<FontAwesomeIcon icon={['fas', 'paper-plane']} size="lg" />} />
        </BottomNavigation>
      </footer>
    </div>
  );
};

export default Msg_Inbox;
