import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProxyPatientInfoType } from 'models';
import { resetAll } from 'reducers';

export const initialState = {
  AddressLine1: '',
  AddressLine2: '',
  City: '',
  DateOfBirth: '',
  FirstName: '',
  Gender: '',
  LastName: '',
  MiddleName: '',
  Relationship: '',
  State: '',
  Zipcode: '',
  sameAddressAsPatient: true,
} as ProxyPatientInfoType;

//https://redux-toolkit.js.org/api/createSlice
const proxyPatientInfoSlice = createSlice({
  name: 'proxyRequestPatientInfo',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    setPatientInfo: (state, action: PayloadAction<ProxyPatientInfoType>) => {
      state = action.payload as ProxyPatientInfoType;
    },
    clearPatientInfo: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { proxyRequestPatientInfo: ProxyPatientInfoType }): ProxyPatientInfoType =>
  state.proxyRequestPatientInfo;

export const proxyPatientInfo_ = createSelector(stateSelector_, (state) => state);

export const { setPatientInfo, clearPatientInfo } = proxyPatientInfoSlice.actions;

export default proxyPatientInfoSlice.reducer;
