import { tokenAuthApiInstance } from 'utils/auth';

import { CARETEAM_ITEMS, getCareTeamConfig } from './constants';

export type CareTeamConfig = {
  careTeamItems: {
    showProviders: string;
  };
};

export type careTeamProvider = {
  ProviderID: string;
  Title: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Suffix: string;
  PhysicianSpeciality: string;
  PhysicianPhotoPath: string;
};

export const getCareTeamItems = async (): Promise<CareTeamConfig> => {
  const response = await tokenAuthApiInstance.authAxios().get(CARETEAM_ITEMS);
  return response.data;
};
export const getCareTeamProviders = async (payLoad: Record<string, string>): Promise<careTeamProvider[]> => {
  const { showProviders, forDashboard } = payLoad;
  const response = await tokenAuthApiInstance.authAxios().get(getCareTeamConfig(showProviders, forDashboard));
  return response.data;
};
