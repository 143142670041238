import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getVitalsDetails, VitalDetailsResponse, vitalsDetailsPyload } from 'services';

export type State = ApiState<VitalDetailsResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchVitalDetails = createAsyncThunk(
  'get/vitalDetails',
  async (payload: vitalsDetailsPyload) => await getVitalsDetails(payload)
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const vitalDetailsSlice = createSlice({
  name: 'vitalDetails',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    unmountVitaDetails: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchVitalDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchVitalDetails.fulfilled, (state, action: PayloadAction<VitalDetailsResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchVitalDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { vitalDetails: State }): State => state.vitalDetails;

export const vitalDetails_ = createSelector(stateSelector_, (state) => state.data);

export const { unmountVitaDetails } = vitalDetailsSlice.actions;

export default vitalDetailsSlice.reducer;
