import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getScheduleAppointmentDetailsByPhone, ScheduleAppointmentDetailsResponse } from 'services';

export type State = ApiState<ScheduleAppointmentDetailsResponse, SerializedError>;

export const fetchScedAppointmentDetailsByPhone = createAsyncThunk(
  'get/schedAppointmentDetailsByPhone',
  async () => await getScheduleAppointmentDetailsByPhone()
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const schedAppointmentDetailsByPhoneSlice = createSlice({
  name: 'schedAppointmentDetailsByPhone',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchScedAppointmentDetailsByPhone.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchScedAppointmentDetailsByPhone.fulfilled,
      (state, action: PayloadAction<ScheduleAppointmentDetailsResponse>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.hasLoaded = true;
      }
    );

    builder.addCase(fetchScedAppointmentDetailsByPhone.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const schedAppointmentStateSelector_ = (state: { schedAppointmentDetailsByPhone: State }): State =>
  state.schedAppointmentDetailsByPhone;

export const schedAppointmentDetailsByPhone_ = createSelector(schedAppointmentStateSelector_, (state) => state.data);

export default schedAppointmentDetailsByPhoneSlice.reducer;
