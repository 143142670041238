import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getScheduleAppointmentTypes, ScheduleAppointmentTypesResponse } from 'services';

export type State = ApiState<ScheduleAppointmentTypesResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchScheduleAppointmentTypes = createAsyncThunk(
  'get/scheduleAppointmentTypes',
  async () => await getScheduleAppointmentTypes()
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const scheduleAppointmentTypesSlice = createSlice({
  name: 'scheduleAppointmentTypes',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    unmountScheduleAppointmentTypes: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScheduleAppointmentTypes.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchScheduleAppointmentTypes.fulfilled,
      (state, action: PayloadAction<ScheduleAppointmentTypesResponse>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.hasLoaded = true;
      }
    );

    builder.addCase(fetchScheduleAppointmentTypes.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { scheduleAppointmentTypes: State }): State => state.scheduleAppointmentTypes;

export const scheduleAppointmentTypes_ = createSelector(stateSelector_, (state) => state.data);

export const scheduleAppointmentTypesIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const scheduleAppointmentTypesHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const { unmountScheduleAppointmentTypes } = scheduleAppointmentTypesSlice.actions;

export default scheduleAppointmentTypesSlice.reducer;
