import store from 'reducers';
import { savePatientPortalAccess } from 'services/documents/patient-portal-audit-log.api';
import { Logger } from 'utils/logger';

// this function is used to trac user actions
// this function takes 2 params userAction : Read/write and portalModule :Widget or component name
// this function will save audit log in to db
export const CpxPortalEventTracker = async (userAction: string, portalModule: string): Promise<void> => {
  try {
    const state = store?.getState();
    const { PatientID, UserID } = state?.personalInfo?.data || {};
    if (UserID) {
      const payload = {
        userAction,
        portalModule,
        patientID: PatientID,
        userID: UserID,
      };
      await savePatientPortalAccess(payload);
    }
  } catch (error) {
    if (error) {
      Logger.error(JSON.stringify(error));
    }
  }
};
