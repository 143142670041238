import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { postReqCancelUpcomingAppointmentId } from 'services';

export type State = ApiState<string, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const postRequestCancelUpcomingAppointment = createAsyncThunk(
  'post/reqCancelUpcomingAppointments',
  async (payLoad: Record<string, string | Record<string, string>>) =>
    await postReqCancelUpcomingAppointmentId({ ...payLoad })
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: '',
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const reqCancelUpcomingAppointmentsSlice = createSlice({
  name: 'reqCancelUpcomingAppointments',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    unmountReqCancelUpcomingAppointments: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(postRequestCancelUpcomingAppointment.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(postRequestCancelUpcomingAppointment.fulfilled, (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(postRequestCancelUpcomingAppointment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const reqCancelUpcomingAppointmentStateSelector_ = (state: { reqCancelUpcomingAppointments: State }): State =>
  state.reqCancelUpcomingAppointments;

export const reqCancelUpcomingAppointment_ = createSelector(
  reqCancelUpcomingAppointmentStateSelector_,
  (state) => state.data
);
export const reqCancelUpcomingAppointmentHasLoaded = createSelector(
  reqCancelUpcomingAppointmentStateSelector_,
  (state) => state.hasLoaded
);

export const { unmountReqCancelUpcomingAppointments } = reqCancelUpcomingAppointmentsSlice.actions;
export default reqCancelUpcomingAppointmentsSlice.reducer;
