export const EcheckinInitialState = {
  PracticeID: '',
  PatientID: '',
  PracticeCalendarID: '',
  ArrivalDateTime: '',
  StartDateTime: '',
  ProviderName: '',
  ProviderSpecialty: '',
  AppointmentType: '',
  LocationName: '',
  LocationID: '',
  eCheckinMobilePhone: '',
  eCheckinEmailAddress: '',
  SendSMSNotification: false,
  SendEmailNotification: false,
  FirstName: '',
  MiddleName: '',
  LastName: '',
  AddressLine1: '',
  AddressLine2: '',
  City: '',
  State: '',
  County: '',
  Zipcode: '',
  MobilePhone: '',
  HomePhone: '',
  EmailAddress: '',
  LegalSex: '',
  SexAssignedAtBirth: '',
  MaritalStatus: '',
  GenderIdentity: '',
  SexualOrientation: '',
  Religion: '',
  AcknowledgeResponsibilityforPayment: false,
  UseInsuranceforThisAppointment: false,
  Insurance: [],
  QRCode: '',
  AcknowledgePersonalInfo: false,
  AcknowledgeInsuranceInfo: false,
  AcknowledgeBillingInfo: false,
  AcknowledgeQuestionnaire: false,
  eCheckinDateTime: null,
  CheckInStatus: '',
  FormIO: [],
  QuestionnaieAnswers: [],
  ConsentForms: [],
  ConsentFomElements: [],
  IsFormIO: false,
};
