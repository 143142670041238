import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AppBar, Box, IconButton, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MoreVert } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';

import componentStyles from './componentStyles';
const useStyles = makeStyles(componentStyles);
type Props = {
  heading: string;
  className?: string;
};
const InnerHeader: FunctionComponent<Props> = ({ heading }) => {
  const classes = useStyles();
  return (
    <AppBar position="fixed">
      <Toolbar variant="dense">
        <Box className={classes.fullWidth} display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center" className={classes.fullWidth}>
            <img src="/images/logo.png" alt="Logo" />
            <h4 className={classes.pageTitle}>{heading}</h4>
          </Box>
          <IconButton edge="end" color="inherit" aria-label="menu">
            <FontAwesomeIcon icon={['fas', 'envelope-square']} />
          </IconButton>
          <IconButton edge="end" color="inherit" aria-label="menu">
            <FontAwesomeIcon icon={['fas', 'sliders-h']} rotation={90} />
          </IconButton>
          <IconButton edge="end" color="inherit" aria-label="menu">
            <MoreVert />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default InnerHeader;
