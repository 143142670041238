import { tokenAuthApiInstance } from 'utils/auth';

import { CANCELLED_APPOINTMENTS_ENDPOINT, REQUESTED_CANCELLATION_APPOINTMENTS_ENDPOINT } from './constants';

export type AppointmentCancellation = {
  CheckInTime: string;
  CheckOutTime: string;
  FormID: string;
  FormName: string;
  FormUrl: string;
  FromDate: string;
  LocationID: string;
  LocationName: string;
  PatientEmail: string;
  PatientID: string;
  PatientName: string;
  PatientPreferredContact: string;
  PatientStatus: string;
  PracticeCalendarID: string;
  PracticeID: string;
  ProviderID: string;
  ProviderName: string;
  rowcount: number;
  ToDate: string;
  TypeOfVisit: string;
};

export type AppointmentCancellationResponse = Array<AppointmentCancellation>;

//fetch cancelled appoointments
export const getCancelledAppointments = async (rowsPerPage: number): Promise<AppointmentCancellationResponse> => {
  const response = await tokenAuthApiInstance.authAxios([rowsPerPage.toString()]).get(CANCELLED_APPOINTMENTS_ENDPOINT);
  return response.data;
};

//fetch requested cancellation appointments
export const getRequestedCancellationAppointments = async (
  rowsPerPage: number
): Promise<AppointmentCancellationResponse> => {
  const response = await tokenAuthApiInstance
    .authAxios([rowsPerPage.toString()])
    .get(REQUESTED_CANCELLATION_APPOINTMENTS_ENDPOINT);
  return response.data;
};
