import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getProxyAccessConfig, ProxyAccessConfigType } from 'services';

export type ProxyAccessConfigState = ApiState<ProxyAccessConfigType, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchProxyAccessConfig = createAsyncThunk(
  'get/proxyAccessConfig',
  async () => await getProxyAccessConfig()
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: {},
  error: null,
} as ProxyAccessConfigState;

//https://redux-toolkit.js.org/api/createSlice
const proxyAccessConfigSlice = createSlice({
  name: 'proxyAccessConfig',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProxyAccessConfig.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchProxyAccessConfig.fulfilled, (state, action: PayloadAction<ProxyAccessConfigType>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchProxyAccessConfig.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { proxyAccessConfig: ProxyAccessConfigState }): ProxyAccessConfigState =>
  state.proxyAccessConfig;

export const proxyAccessConfig_ = createSelector(stateSelector_, (state) => state.data);

export const proxyAccessConfigIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const proxyAccessConfigHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export default proxyAccessConfigSlice.reducer;
