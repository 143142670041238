import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getCareTeamForMessaging } from 'services';
import { careTeamProvider } from 'services/careTeam-config';

export type State = ApiState<careTeamProvider[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchCareTeamForMessaging = createAsyncThunk(
  'get/careTeamForMessaging',
  async (Payload: Record<string, string>) => await getCareTeamForMessaging({ ...Payload })
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const careTeamForMessagingSlice = createSlice({
  name: 'careTeamForMessaging',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCareTeamForMessaging.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCareTeamForMessaging.fulfilled, (state, action: PayloadAction<careTeamProvider[]>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchCareTeamForMessaging.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const careTeamForMessagingSelctor = (state: { careTeamForMessaging: State }): State =>
  state.careTeamForMessaging;

export const careTeamForMessaging_ = createSelector(careTeamForMessagingSelctor, (state) => state.data);

export default careTeamForMessagingSlice.reducer;
