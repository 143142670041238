import { ECheckInQuestionnairePayload } from 'models';
import { tokenAuthApiInstance } from 'utils/auth';

import { getSaveFormIOQuestionnaireEndpoint } from '../constants';

/* eslint-disable @typescript-eslint/no-explicit-any */
export const saveFormIOQuestionnaire = async (
  practiceCalendarID: string,
  isFormIO: boolean,
  savePayload: Array<ECheckInQuestionnairePayload>
): Promise<any> => {
  const response = await tokenAuthApiInstance
    .authAxios()
    .post(getSaveFormIOQuestionnaireEndpoint(practiceCalendarID, isFormIO), savePayload);
  return response.data;
};
