import { tokenAuthApiInstance } from 'utils/auth';

import { FORM_IO_SAVED_DATA_GET_ENDPOINT } from '../constants';

export type EcheckinSavedFormDataTypeResponse = {
  PatientQuestionnaireID: string;
  QuestionnaireType: string;
  FormUrl: string;
  FormName: string;
  FormTitle: string;
  FormID: string;
  FormData: string | Record<string, unknown>;
  CompletedDate: null;
  PatientID: null;
  FormTemplateID: string;
};

export const getEcheckinSavedFormsData = async (
  payload: Record<string, string>
): Promise<EcheckinSavedFormDataTypeResponse[]> => {
  const { practiceCalendarId, IsFormIO = 'true' } = payload;
  const response = await tokenAuthApiInstance
    .authAxios([practiceCalendarId, IsFormIO])
    .get(FORM_IO_SAVED_DATA_GET_ENDPOINT);
  return response.data;
};
