import { tokenAuthApiInstance } from 'utils/auth';

import {
  VISIT_RECORDS_ENDPOINT,
  VISIT_RECORDS_MEASURE_ENDPOINT,
  VISIT_RECORDS_PROGRESS_NOTE_ENDPOINT,
} from '../constants';

export type VisitRecordModel = {
  PatientEncounterID: string;
  PatientID: string;
  VisitType: string;
  VisitDate: string;
  VisitName: string;
  rowcount: number;
  IsBH: boolean;
};

export type VisitRecordsResponse = VisitRecordModel[];

export type ProgressNoteDetails = {
  patientencounterid: string;
  encounterdate: string;
  slidingfeescaleprogramname: string;
  ICDCode: string;
  ICDTitle: string;
  icd10code: string;
  icd10title: string;
};

export type SectionDetails = {
  sectioncode: number;
  sectionname: string;
  sectiondisplayname: string;
  sectiontext: string;
};

export type PatientGoals = {
  patientgoalid: string;
  goal: string;
  goaldescription: string;
  comments: string;
  progress: string;
  opendate: string;
  targetdate: string;
  isdelete: number;
};

export type PatientDetails = {
  PatientName: string;
  dateofbirth: string;
  MRN: string;
  MedicaidId: string;
  Gender: string;
};

export type InterventionsData = {
  Intervention: string;
  Problem: string;
  InsertDate: string;
  LastEditDate: string;
  ProgressStatus: string;
};

export type ProviderDetails = {
  ProviderID: string;
};

export type VisitRecordsProgressNote = {
  ProgressNoteDetails: Array<ProgressNoteDetails>;
  SectionDetails: Array<SectionDetails>;
  PatientGoals: Array<PatientGoals>;
  PatientEncounterGoals: Array<PatientGoals>;
  PatientDetails: Array<PatientDetails>;
  InterventionsData: Array<InterventionsData>;
  ProviderDetails: Array<ProviderDetails>;
};

export type Measure = {
  PatientMeasureID: string;
  MeasureType: string;
  MeasuredDate: string;
  MeasuredBy: string;
  PatientEncounterID: string;
  MeasureValue1: number;
  MeasureUnit1: string;
  MeasureValue2: number;
  MeasureUnit2: string;
  MeasurePercentile: number;
  ProviderName: string;
};

export type VisitRecordsMeasureResponse = Array<Measure>;

export const getVisitRecords = async (rowsPerPage: number, visitTypes: string): Promise<VisitRecordsResponse> => {
  const response = await tokenAuthApiInstance
    .authAxios([rowsPerPage.toString(), visitTypes])
    .get(VISIT_RECORDS_ENDPOINT);
  return response.data;
};

export const getVisitRecordsProgressNote = async (
  payload: Record<string, string>
): Promise<VisitRecordsProgressNote> => {
  const { patientEncounterID, userID } = payload;
  const response = await tokenAuthApiInstance
    .authAxios([patientEncounterID, userID])
    .get(VISIT_RECORDS_PROGRESS_NOTE_ENDPOINT);
  return response.data;
};

export const getVisitRecordsMeasure = async (payload: Record<string, string>): Promise<VisitRecordsMeasureResponse> => {
  const { patientEncounterID, userID } = payload;
  const response = await tokenAuthApiInstance
    .authAxios([patientEncounterID, userID])
    .get(VISIT_RECORDS_MEASURE_ENDPOINT);
  return response.data;
};
