import { tokenAuthApiInstance } from 'utils/auth';

import { MEDICATIONS_SUMMARY } from '../constants';

export type MedicationSummaryModel = {
  AddlSig: string;
  DiscontinuedDate: string;
  IsDiscontinued: boolean;
  MedicationName: string;
  OrderedDate: string;
  PatientID: string;
  PatientMedicationID: string;
  PrescriberName: string;
  QuantityPrescribed: string;
  Refills: string;
  SIG: string;
  ProviderID: string;
};

export type MedicationSummaryResponse = MedicationSummaryModel[];

export const medicationsSummary = async (isDiscontinued = false): Promise<MedicationSummaryResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(MEDICATIONS_SUMMARY(isDiscontinued));
  return response.data;
};
