import { Button, Card, CardActions, IconButton } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import Appointments from './../Appointments/Appointments';
import Header from './../InnerHeader';

const eCheck: FunctionComponent = () => {
  const history = useHistory();
  function scheduleAppointment() {
    history.push('./scheduleAppointmentByPhone');
  }
  return (
    <div className="wrapper">
      <Header className="header" heading="Appointments" />
      <Appointments />
      <footer>
        <Card className="cardBlock">
          <CardActions className="card-footerbg-primary">
            <IconButton aria-label="payment">
              <Add />
            </IconButton>
            <Button id="complete-checkin" size="small" fullWidth onClick={scheduleAppointment}>
              Schedule An Appointment
            </Button>
          </CardActions>
        </Card>
      </footer>
    </div>
  );
};

export default eCheck;
