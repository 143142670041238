import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { postReqAppointmentId } from 'services';

export type State = ApiState<string, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const postRequestCancelAppointment = createAsyncThunk(
  'post/reqCancelAppointments',
  async (reqId: string) => await postReqAppointmentId(reqId)
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: '',
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
export const reqCancelAppointmentsSlice = createSlice({
  name: 'reqCancelAppointments',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    resetRequestStatus: (state) => {
      state.data = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postRequestCancelAppointment.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(postRequestCancelAppointment.fulfilled, (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(postRequestCancelAppointment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const reqCancelAppointmentStateSelector_ = (state: { reqCancelAppointments: State }): State =>
  state.reqCancelAppointments;

export const reqCancelAppointment_ = createSelector(reqCancelAppointmentStateSelector_, (state) => state.data);
export const reqCancelAppointmentHasLoaded = createSelector(
  reqCancelAppointmentStateSelector_,
  (state) => state.hasLoaded
);

export default reqCancelAppointmentsSlice.reducer;
