import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { careTeamProvider, getCareTeamProviders } from 'services/careTeam-config';

export type State = ApiState<careTeamProvider[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchCareTeamProvider = createAsyncThunk(
  'get/careTeamItemsProviderDetails',
  async (payLoad: Record<string, string>) => await getCareTeamProviders({ ...payLoad })
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const careTeamProviderSlice = createSlice({
  name: 'careTeamProviderDetails',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCareTeamProvider.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCareTeamProvider.fulfilled, (state, action: PayloadAction<careTeamProvider[]>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchCareTeamProvider.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const careTeamProviderDetailsSelector_ = (state: { careTeamProviderDetails: State }): State =>
  state.careTeamProviderDetails;

export const careTeamProviderDetails_ = createSelector(careTeamProviderDetailsSelector_, (state) => state.data);

export default careTeamProviderSlice.reducer;
