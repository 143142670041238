import { Typography } from '@material-ui/core';
import { BackgroundProgressComponent } from 'components/common/backdrop-progress/backdrop-progress.component';
import { DialogComponent } from 'components/common/dialogue/dialogue.component';
import useDialogueHook from 'components/hooks/dialogue-hook';
import { AuthContext } from 'context/authContext';
import { TokenContext } from 'context/tokenContext';
import moment from 'moment';
import React, { FunctionComponent, lazy, Suspense, useContext, useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { Route, Switch, useHistory } from 'react-router-dom';
import { unmountKioskLogin } from 'reducers/kiosk-login.slice';
import {
  KIOSK_CONFIRMED_APPOINTMENT_ROUTE,
  KIOSK_ECHECKIN_APPOINTMENT_ROUTE,
  KIOSK_ECHECKIN_ROUTE,
  KIOSK_LOGIN_ROUTE,
  KIOSK_MAIN_ROUTE,
  KIOSK_MENU_ROUTE,
  REACT_APP_KIOSK_TIME_OUT_MS,
} from 'services';
import { tokenAuthApiInstance } from 'utils/auth';
import { Logger } from 'utils/logger';

import NotAuthorizedPage from './components/not-authorized';

const CpxKioskLogin = lazy(() => import('kiosk/components/login/login.component'));
const CpxKioskMain = lazy(() => import('kiosk/components/main/main.component'));
const CpxKioskMenu = lazy(() => import('kiosk/components/menu/menu.component'));
const CpxKioskeCheckIn = lazy(() => import('kiosk/components/eCheckIn/kiosk-echeckin.container'));
const CpxKioskEcheckinAppointments = lazy(() => import('kiosk/components/eCheckIn/scheduled-appointments.component'));
const CpxKioskEcheckinConfirmedComponent = lazy(() => import('kiosk/components/eCheckIn/echeckin-confirmed.component'));

export type KioskAppComponentProps = {
  isAuthenticated: boolean;
  isLoading: boolean;
  byPassAuth: boolean;
};

export const KioskAppComponent: FunctionComponent<KioskAppComponentProps> = ({
  isAuthenticated,
  isLoading,
  byPassAuth,
}) => {
  const { accessToken, idToken } = useContext(AuthContext);

  const KIOSK_SESSION_TIMEOUT = REACT_APP_KIOSK_TIME_OUT_MS as number;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [timeOutTime, setTimeOutTime] = useState(KIOSK_SESSION_TIMEOUT);
  const { pathname } = useLocation();
  const timerRef = useRef(10000);
  const history = useHistory();
  const dispatch = useDispatch();

  const { open: isTimerOn, setOpen: setIsTimerOn, onDialogAction, actionType, setActionType } = useDialogueHook(
    timerAction
  );

  useEffect(() => {
    (async () => {
      try {
        if (accessToken && accessToken.length > 0) {
          tokenAuthApiInstance.token = accessToken;
        }
        if (idToken && idToken.length > 0) {
          tokenAuthApiInstance.idToken = idToken;
        }
      } catch (error) {
        Logger.error(error);
      }
    })();
  }, [accessToken, idToken]);

  function timerAction() {
    history.push(KIOSK_LOGIN_ROUTE);
    setIsTimerOn(false);
    setTimerMilliSecs(10000);
    dispatch(unmountKioskLogin());
  }

  const [timerMilliSecs, setTimerMilliSecs] = useState(10000);

  // reset timer
  /* eslint-disable-next-line */
  const onAction = (event: any) => {
    try {
      start();
      reset();
    } catch (err) {
      if (err) {
        Logger.error(`reset idleTimer onAction ${JSON.stringify(err)}`);
      }
    }
  };

  // reset timer
  /* eslint-disable-next-line */
  const onActive = (event: any) => {
    try {
      start();
    } catch (err) {
      if (err) {
        Logger.error(`reset idleTimer onActive ${JSON.stringify(err)}`);
      }
    }
  };

  const { start, reset } = useIdleTimer({
    timeout: timeOutTime,
    onIdle: () => {
      // sometimes pathname is getting wrong so keeping in settimeout
      setTimeout(() => {
        if (location.pathname !== KIOSK_LOGIN_ROUTE) {
          setIsTimerOn(true);
        }
      });
    },
    onActive,
    onAction,
    debounce: 0,
    crossTab: true,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    // name: 'kiosk-idle-timer',
  });

  // Reset the idleTimer on changing timeOutTime value
  useEffect(() => {
    try {
      if (timeOutTime) {
        reset();
      }
    } catch (err) {
      if (err) {
        Logger.error(`reset idleTimer when timeOutTime value changed ${JSON.stringify(err)}`);
      }
    }
  }, [timeOutTime]);

  useEffect(() => {
    if (timerMilliSecs === 0) {
      clearInterval(timerRef.current);
      setIsTimerOn(false);
      history.push(KIOSK_LOGIN_ROUTE);
      setTimerMilliSecs(10000);
      dispatch(unmountKioskLogin());
    }
  }, [timerMilliSecs]);

  useEffect(() => {
    if (timerMilliSecs > 0 && isTimerOn) {
      timerRef.current = (setInterval(() => {
        setTimerMilliSecs(timerMilliSecs - 1000);
      }, 1000) as unknown) as number;
    }

    return () => {
      clearInterval(timerRef.current);
    };
  }, [isTimerOn, timerMilliSecs]);

  useEffect(() => {
    if (actionType === 'Cancel') {
      start();
      setIsTimerOn(false);
      clearInterval(timerRef.current);
      setTimerMilliSecs(10000);
      setActionType(undefined);
    }
  }, [actionType]);

  return (
    <div>
      <BackgroundProgressComponent open={isLoading} />
      <Suspense fallback={<p className="text-center">loading...</p>}>
        {!isAuthenticated && !byPassAuth && pathname.includes('kiosk', 0) && (
          <div className="center">
            <NotAuthorizedPage />
          </div>
        )}
        <Switch>
          <Route exact path={KIOSK_LOGIN_ROUTE}>
            {isAuthenticated && <CpxKioskLogin />}
          </Route>
          <Route path={KIOSK_MAIN_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxKioskMain />
            </TokenContext.Provider>
          </Route>
          <Route path={KIOSK_ECHECKIN_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxKioskeCheckIn />
            </TokenContext.Provider>
          </Route>
          <Route path={KIOSK_MENU_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxKioskMenu />
            </TokenContext.Provider>
          </Route>
          <Route path={KIOSK_ECHECKIN_APPOINTMENT_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxKioskEcheckinAppointments />
            </TokenContext.Provider>
          </Route>
          <Route path={KIOSK_CONFIRMED_APPOINTMENT_ROUTE}>
            <TokenContext.Provider value={{ token: tokenAuthApiInstance.token }}>
              <CpxKioskEcheckinConfirmedComponent />
            </TokenContext.Provider>
          </Route>
        </Switch>
      </Suspense>
      <DialogComponent
        className="custom-dialog-component-styles timer-dialog"
        color="primary"
        open={isTimerOn}
        actions={[
          { action: 'Cancel', text: `I'm Here`, color: 'primary' },
          {
            action: 'Update',
            text: 'Logout',
            color: 'primary',
          },
        ]}
        onAction={onDialogAction}
        fullWidth={true}
        scroll="paper"
        dividers={true}
      >
        <div>
          <Typography variant="h4" component="h2">
            Are you still there ?
          </Typography>
          <Typography variant="h4" component="h2">
            {`If not we will close the session in ${moment
              .utc(moment.duration(timerMilliSecs, 'milliseconds').asMilliseconds())
              .format('mm:ss')}`}
          </Typography>
        </div>
      </DialogComponent>
    </div>
  );
};

export default KioskAppComponent;
