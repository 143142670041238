import { tokenAuthApiInstance } from 'utils/auth';

import { LAB_RESULTS_DETAILS_ENDPOINT, LAB_RESULTS_ENDPOINT } from './constants';

export type LabResultSummary = {
  LabOrderID: string;
  PatientID: string;
  LabId: number;
  SenderLab: string;
  ServiceNames: string;
  LabOrderDate: string;
  ResultsDate: string;
  RowNo: number;
  rowcount: number;
  OrderPhysician: string;
  OrderingPhysicianID?: string;
};

export type LabResultDetails = {
  LabResponseID: string;
  ServiceName: string;
  CompendiumName: string;
  ResultValue: string;
  ResultUnits: string;
  ResultRange: string;
  ResultNotes: string;
  IsAbnormal: number;
  ProfileComponentCode: string;
  OrderComments: string;
  Parent: number;
  ServiceSeqNo: number;
  SeqNo: number;
};

export const getLabResultsSummary = async (Payload: Record<string, string>): Promise<LabResultSummary[]> => {
  const { rowsPerPage, labResultsPreference } = Payload;
  const response = await tokenAuthApiInstance.authAxios([rowsPerPage, labResultsPreference]).get(LAB_RESULTS_ENDPOINT);
  return response.data;
};

export const getLabResultsDetails = async (Payload: Record<string, string>): Promise<LabResultDetails[]> => {
  const { labOrderID, senderLab } = Payload;
  const response = await tokenAuthApiInstance.authAxios([labOrderID, senderLab]).get(LAB_RESULTS_DETAILS_ENDPOINT);
  return response.data;
};
