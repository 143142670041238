import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { FilterFieldOption } from 'components/common/form';
import { ApiState } from 'models';
import { getStates, StatesResponse } from 'services';

export type State = ApiState<FilterFieldOption[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchStates = createAsyncThunk('get/states', async () => await getStates());

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const statesSlice = createSlice({
  name: 'states',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchStates.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchStates.fulfilled, (state, action: PayloadAction<StatesResponse>) => {
      state.isLoading = false;
      state.data = action.payload.map(({ StateCode, StateName }) => ({ id: StateCode, label: StateName }));
      state.hasLoaded = true;
    });

    builder.addCase(fetchStates.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { states: State }): State => state.states;

export const states_ = createSelector(stateSelector_, (state) => state.data);

export const statesIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const statesHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export default statesSlice.reducer;
