import _ from 'lodash';
import { tokenAuthApiInstance } from 'utils/auth';
import { Logger } from 'utils/logger';

import { PRACTICE_FEATURES_ENDPOINT } from './constants';

export type FeatureDetails = {
  id: number;
  Key: string;
  Name: string;
  Description: string;
  ReleaseState: string;
  Hidden: boolean;
  Category?: string;
};

export type FeatureDetailsResponse = Array<FeatureDetails>;

export const getFeatureDetails = async (): Promise<FeatureDetailsResponse> => {
  try {
    const response = await tokenAuthApiInstance.authAxios().get(PRACTICE_FEATURES_ENDPOINT);

    const { featureItems } = response.data;
    // add id field for data-grid
    return _.map(featureItems, (f, i) => _.extend({ id: i }, f));
    /* eslint-disable @typescript-eslint/no-explicit-any */
  } catch (error) {
    if (error) {
      const message = `Got error in getting the feature details ${JSON.parse(error.toString())}`;
      Logger.error(message);
    }
    throw error;
  }
};
