import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { EcheckinSavedFormDataTypeResponse, getEcheckinSavedFormsData } from 'services';

export type State = ApiState<EcheckinSavedFormDataTypeResponse[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchEcheckinFormIoSavedData = createAsyncThunk(
  'get/eCheckinFormIoSavedData',
  async (payload: Record<string, string>) => await getEcheckinSavedFormsData(payload)
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const eCheckinFormIoSavedDataSlice = createSlice({
  name: 'eCheckinFormIoSavedData',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    clearECheckinFormIoSavedData: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEcheckinFormIoSavedData.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchEcheckinFormIoSavedData.fulfilled,
      (state, action: PayloadAction<EcheckinSavedFormDataTypeResponse[]>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.hasLoaded = true;
      }
    );

    builder.addCase(fetchEcheckinFormIoSavedData.rejected, (state, action) => {
      state.hasLoaded = true;
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { eCheckinFormIoSavedData: State }): State => state.eCheckinFormIoSavedData;

export const eCheckinSavedFormIoDetails_ = createSelector(stateSelector_, (state) => state.data);

export const eCheckinSavedFormIoDetailsHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const eCheckinSavedFormIoDetailsIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const { clearECheckinFormIoSavedData } = eCheckinFormIoSavedDataSlice.actions;

export default eCheckinFormIoSavedDataSlice.reducer;
