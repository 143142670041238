import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getHealthInfoDocuments, UploadedDocumentResponse } from 'services';

export type State = ApiState<UploadedDocumentResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchHealthInfoDocuments = createAsyncThunk(
  'get/healthInfoDocuments',
  async (rowsPerPage: number) => await getHealthInfoDocuments(rowsPerPage)
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const healthInfoDocumentsSlice = createSlice({
  name: 'healthInfoDocuments',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchHealthInfoDocuments.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchHealthInfoDocuments.fulfilled, (state, action: PayloadAction<UploadedDocumentResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchHealthInfoDocuments.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { healthInfoDocuments: State }): State => state.healthInfoDocuments;

export const healthInfoDocuments_ = createSelector(stateSelector_, (state) => state.data);

export default healthInfoDocumentsSlice.reducer;
