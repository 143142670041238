import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { AlertDetailsResponse, getAlertDetails } from 'services';

export type State = ApiState<AlertDetailsResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchAlertDetails = createAsyncThunk('get/alertDetails', async () => await getAlertDetails());

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  //data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const alertDetailsSlice = createSlice({
  name: 'alertDetails',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAlertDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchAlertDetails.fulfilled, (state, action: PayloadAction<AlertDetailsResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchAlertDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { alertDetails: State }): State => state.alertDetails;

export const alertDetails_ = createSelector(stateSelector_, (state) => state.data);

export default alertDetailsSlice.reducer;
