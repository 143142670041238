import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProxyAccessListItem } from 'services';

export type CurrentPatientViewState = {
  switchedAccount: boolean;
  viewing: ProxyAccessListItem | undefined;
  mainAcct: ProxyAccessListItem | undefined;
};

export const initialState = {
  switchedAccount: false,
  viewing: undefined,
  mainAcct: undefined,
} as CurrentPatientViewState;

// to track which account user is viewing for proxy access
export const currentPatientViewSlice = createSlice({
  name: 'currentPatientView',
  initialState,
  reducers: {
    setUserSwitchedAccount: (state) => {
      state.switchedAccount = true;
    },
    resetUserSwitchedAccount: (state) => {
      state.switchedAccount = false;
    },
    setUserViewing: (state, action: PayloadAction<ProxyAccessListItem>) => {
      state.viewing = action.payload as ProxyAccessListItem;
    },
    setMainAccount: (state, action: PayloadAction<ProxyAccessListItem>) => {
      state.mainAcct = action.payload as ProxyAccessListItem;
    },
  },
});

export const stateSelector_ = (state: { currentPatientView: CurrentPatientViewState }): CurrentPatientViewState =>
  state.currentPatientView;

export const MainPatientAccount_ = createSelector(stateSelector_, (state) => state.mainAcct);

export const CurrentPatientView_ = createSelector(stateSelector_, (state) => state.viewing);

export const hasUserSwitchAccount_ = createSelector(stateSelector_, (state) => state.switchedAccount);

export const {
  setUserSwitchedAccount,
  resetUserSwitchedAccount,
  setUserViewing,
  setMainAccount,
} = currentPatientViewSlice.actions;

export default currentPatientViewSlice.reducer;
