import { tokenAuthApiInstance } from 'utils/auth';

import { RequestAppointments } from './appointment-details.api';
import { REFUSED_APPOINTMENTS_ENDPOINT } from './constants';

//to fetch refused appointment requests
export const getRefusedAppointments = async (rowsPerPage: number): Promise<RequestAppointments> => {
  const response = await tokenAuthApiInstance.authAxios([rowsPerPage.toString()]).get(REFUSED_APPOINTMENTS_ENDPOINT);
  return response.data;
};
