import './not-authorized.component.styles.scss';

import { Typography } from '@material-ui/core';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

export const NotAuthorizedPage: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div id="error" className="errorMsg">
      <ErrorOutlineIcon className="errorIcon" aria-label={t('error.authorizationError')} />
      <Typography align="left" variant="h4">
        {t('error.authorizationError')}
      </Typography>
    </div>
  );
};
