import { Button, Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
export const CpxSelfRescheduleConfirmation: FunctionComponent = () => {
  const { t } = useTranslation();
  return (
    <div>
      <p className="marT0">
        {t('ScheduleAppointments.selfReschedQuespart1')} <b>{'AppointmentType'}</b>{' '}
        {t('ScheduleAppointments.Quespart3')} <b>{'Date'}</b> {t('ScheduleAppointments.at')} <b>{'Location'}</b>?
      </p>
      <Grid container justify="flex-start" spacing={2}>
        <Grid item>
          <Button variant="contained" color={'default'}>
            {t('ScheduleAppointments.No3')}
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color={'secondary'}>
            {t('ScheduleAppointments.Yes3')}
          </Button>
        </Grid>
      </Grid>
      <br />
    </div>
  );
};
