import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getQuestionnaireList, QuestionnaireListType } from 'services';

export type State = ApiState<QuestionnaireListType[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchselectedQuestionnaireList = createAsyncThunk(
  'get/questionnaireList',
  async () => await getQuestionnaireList()
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const questionnaireListSlice = createSlice({
  name: 'QuestionnaireList',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchselectedQuestionnaireList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchselectedQuestionnaireList.fulfilled,
      (state, action: PayloadAction<QuestionnaireListType[]>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.hasLoaded = true;
      }
    );

    builder.addCase(fetchselectedQuestionnaireList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { questionnaireList: State }): State => state.questionnaireList;

export const questionnaireList_ = createSelector(stateSelector_, (state) => state.data);

export const questionnaireListHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const questionnaireListIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export default questionnaireListSlice.reducer;
