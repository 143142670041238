import { tokenAuthApiInstance } from 'utils/auth';

import { CCAToken_ENDPOINT } from '../constants';

export type tokenResponse = {
  accessToken: string;
};

export const getCCAToken = async (): Promise<tokenResponse> => {
  const response = await tokenAuthApiInstance.authAxios(undefined, 'v5').get(CCAToken_ENDPOINT);
  return response.data;
};
