import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActions,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Done, ExpandMore } from '@material-ui/icons';
import React from 'react';

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function Confirm(): any {
  return (
    <React.Fragment>
      <br />
      <Typography variant="h5" gutterBottom>
        Review and complete eCheck-In
      </Typography>
      <Grid container>
        <Grid item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="Personal Info" id="Personal Info">
              <Typography>Personal Info</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography></Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="Insurance" id="Insurance">
              <Typography>Insurance</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography></Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="Questions" id="Questions">
              <Typography>Questions</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography></Typography>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="Consents" id="Consents">
              <Typography>Consents</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography></Typography>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <Box className="text-right">
            <FormControlLabel
              id="review-ok"
              control={<Checkbox color="primary" value="yes" />}
              label="This information is correct"
            />
          </Box>
        </Grid>
      </Grid>
      <Card variant="outlined" className="cardBlock">
        <CardActions className="card-footerbg-primary">
          <IconButton aria-label="payment">
            <Done />
          </IconButton>
          <Button id="complete-checkin" size="small" fullWidth className="iconFadeOut">
            Complete eCheck-In
          </Button>
        </CardActions>
      </Card>
    </React.Fragment>
  );
}
