import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getFutureAppointmentQuestionnaires } from 'services';

import { QuestionnaireResponse } from '../../services/questionnaire-wizard/questionnaire-list.api';

export type State = ApiState<QuestionnaireResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchFutureAppointmentQuestionnaire = createAsyncThunk(
  'get/futureAppointmentQuestionnaire',
  async (Payload: Record<string, string>) => await getFutureAppointmentQuestionnaires({ ...Payload })
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const futureAppointmentQuestionnaireSlice = createSlice({
  name: 'scheduleLocations',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    unmountFutureAppointmentQuestionnaire: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFutureAppointmentQuestionnaire.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchFutureAppointmentQuestionnaire.fulfilled,
      (state, action: PayloadAction<QuestionnaireResponse>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.hasLoaded = true;
      }
    );

    builder.addCase(fetchFutureAppointmentQuestionnaire.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { futureAppointmentQuestionnaire: State }): State =>
  state.futureAppointmentQuestionnaire;

export const futureAppointmentQuestionnaire_ = createSelector(stateSelector_, (state) => state.data);

export const futureAppointmentQuestionnaireLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const futureAppointmentQuestionnaireHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const { unmountFutureAppointmentQuestionnaire } = futureAppointmentQuestionnaireSlice.actions;

export default futureAppointmentQuestionnaireSlice.reducer;
