import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TOAST_ALERT_SEVERITY } from 'utils/constants';
import { Logger } from 'utils/logger';

export type ToastMessage = {
  message: string;
  severity: string;
};

export type ToastState = { details: Array<ToastMessage> };

export const initialState = {
  details: [],
} as ToastState;

export const toastSlice = createSlice({
  name: 'toastMessages',
  initialState,
  reducers: {
    addMessage: (state, action: PayloadAction<ToastMessage>) => {
      const {
        payload: { message, severity },
      } = action;
      switch (severity) {
        case TOAST_ALERT_SEVERITY.ERROR:
          Logger.error(message);
          break;
        case TOAST_ALERT_SEVERITY.WARNING:
          Logger.warn(message);
          break;
        default:
          Logger.info(message);
      }
      state.details.push(action.payload);
    },
    clearMessages: (state) => {
      state.details = [];
    },
  },
});

export const stateSelector_ = (state: { toastMessages: ToastState }): ToastState => state.toastMessages;

export const toastMessages_ = createSelector(stateSelector_, (state) => state.details);

export const { addMessage, clearMessages } = toastSlice.actions;

export default toastSlice.reducer;
