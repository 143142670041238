import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import {
  ACCOUNT_PREFERENCES,
  ACCOUNT_SETTINGS,
  APPOINTMENT_ROUTE,
  BILLING_ROUTE,
  CANCEL_REQUEST_APPOINTMENTS,
  CANCEL_SCHEDULED_APPOINTMENTS,
  CARE_TEAM,
  DISCON_MEDICATIONS_ROUTE,
  DOCUMENTS_ROUTE,
  E_CHECKIN,
  ECHECKIN_APPOINTMENT_ROUTE,
  KIOSK_ECHECKIN_APPOINTMENT_ROUTE,
  KIOSK_ECHECKIN_ROUTE,
  KIOSK_LOGIN_ROUTE,
  KIOSK_MAIN_ROUTE,
  KIOSK_MENU_ROUTE,
  MANUAL_CANCEL_SCHEDULED_APPOINTMENTS,
  MEDICATIONS_ROUTE,
  MESSAGES_ROUTE,
  PAY_BALANCE,
  QUESTIONNAIRE_ROUTE,
  RE_SCHEDULED_APPOINTMENTS,
  REQUEST_ACTIVE_CODE,
  SCHEDULED_APPOINTMENTS,
  SCHEDULED_APPOINTMENTS_PHONE,
  SELF_SCHEDULED_APPOINTMENTS,
  VISIT_RECORDS_ROUTE,
} from 'services/routes';

import { TELEMETRY_SERVICE } from './utils/constants';
import { HostEnv } from './utils/environment';

class TelemetryService {
  constructor() {
    this.reactPlugin = new ReactPlugin();
    this.reactPlugin.priority = 181;
    this.adjustPageName = this.adjustPageName.bind(this);
  }

  initialize(reactPluginConfig) {
    // get the instrumentation key for each associated environment
    let INSTRUMENTATION_KEY = '';
    if (HostEnv.isProd()) {
      INSTRUMENTATION_KEY = TELEMETRY_SERVICE.PROD;
    } else if (HostEnv.isStage()) {
      INSTRUMENTATION_KEY = TELEMETRY_SERVICE.STAGE;
    } else {
      // not supported in other environments
      return;
    }
    // create the new app insight instance
    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: INSTRUMENTATION_KEY,
        maxBatchInterval: 1000,
        disableFetchTracking: false,
        enableCorsCorrelation: true,
        autoTrackPageVisitTime: true,
        extensions: [this.reactPlugin],
        extensionConfig: {
          [this.reactPlugin.identifier]: reactPluginConfig,
        },
        enableAutoRouteTracking: true,
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
    this.appInsights.addTelemetryInitializer((item) => {
      item.tags.push({ 'ai.cloud.role': 'MyHealth' });
      // To check the telemetry item’s type:
      const { baseType, baseData: telemetryItem } = item;
      if (baseType === 'PageviewData' || baseType === 'PageviewPerformanceData') {
        // change the page name
        telemetryItem.name = this.adjustPageName(telemetryItem);
        // add screen info
        telemetryItem.properties = this.addScreenInfo(telemetryItem);
      }
    });
  }

  getReactPlugin() {
    return this.reactPlugin;
  }

  adjustPageName(item) {
    if (item) {
      const { name, uri } = item;

      if (uri.indexOf(E_CHECKIN) !== -1) {
        return 'eCheck-In';
      }

      if (uri.indexOf(ECHECKIN_APPOINTMENT_ROUTE) !== -1) {
        return 'eCheck-In through Appointments';
      }

      if (uri.indexOf(APPOINTMENT_ROUTE) !== -1) {
        return 'Get Appointments';
      }

      if (uri.indexOf(SCHEDULED_APPOINTMENTS) !== -1) {
        return 'Schedule Appointment';
      }

      if (uri.indexOf(RE_SCHEDULED_APPOINTMENTS) !== -1) {
        return 'Reschedule Appointment';
      }

      if (uri.indexOf(SCHEDULED_APPOINTMENTS_PHONE) !== -1) {
        return 'Schedule Appointment by Phone';
      }

      if (uri.indexOf(CANCEL_SCHEDULED_APPOINTMENTS) !== -1) {
        return 'Cancel Appointment';
      }

      if (uri.indexOf(CANCEL_REQUEST_APPOINTMENTS) != -1) {
        return 'Cancel Requested Appointment';
      }

      if (uri.indexOf(MANUAL_CANCEL_SCHEDULED_APPOINTMENTS) != -1) {
        return 'Cancel Manual Scheduled Appointment';
      }

      if (uri.indexOf(BILLING_ROUTE) != -1) {
        return 'Billing';
      }

      if (uri.indexOf(PAY_BALANCE) != -1) {
        return 'Balance';
      }

      if (uri.indexOf(CARE_TEAM) != -1) {
        return 'Care Team';
      }

      if (uri.indexOf(QUESTIONNAIRE_ROUTE) != -1) {
        return 'Questionnaire';
      }

      if (uri.indexOf(MESSAGES_ROUTE) != -1) {
        return 'Messages';
      }

      if (uri.indexOf(ACCOUNT_PREFERENCES) != -1) {
        return 'Account Preferences';
      }

      if (uri.indexOf(ACCOUNT_SETTINGS) != -1) {
        return 'Account Settings';
      }

      if (uri.indexOf(REQUEST_ACTIVE_CODE) != -1) {
        return 'Request Activation Code';
      }

      if (uri.indexOf(MEDICATIONS_ROUTE) != -1) {
        return 'Medications';
      }

      if (uri.indexOf(DISCON_MEDICATIONS_ROUTE) != -1) {
        return 'Discontinued Medications';
      }

      if (uri.indexOf(VISIT_RECORDS_ROUTE) != -1) {
        return 'Visit Records';
      }

      if (uri.indexOf(DOCUMENTS_ROUTE) != -1) {
        return 'Documents';
      }

      if (uri.indexOf(KIOSK_ECHECKIN_APPOINTMENT_ROUTE) != -1) {
        return 'Kiosk eCheck-In through Appointments';
      }

      if (uri.indexOf(KIOSK_ECHECKIN_ROUTE) != -1) {
        return 'Kiosk eCheck-In';
      }

      if (uri.indexOf(KIOSK_LOGIN_ROUTE) != -1) {
        return 'Kiosk Login Page';
      }

      if (uri.indexOf(KIOSK_MAIN_ROUTE) != -1) {
        return 'Kiosk Landing Page';
      }

      if (uri.indexOf(KIOSK_MENU_ROUTE) != -1) {
        return 'Kiosk Menu Page';
      }

      if (uri.indexOf(SELF_SCHEDULED_APPOINTMENTS) !== -1) {
        return 'Self Schedule Appointment';
      }

      if (uri === '/') {
        return 'Dashboard';
      }

      return name;
    }

    return '';
  }

  addScreenInfo(item) {
    if (item) {
      const { properties } = item;
      return {
        ...properties,
        ScreenWidth: window.screen.width,
        ScreenHeight: window.screen.height,
        DevicePixelRatio: window.devicePixelRatio,
      };
    }
    return {};
  }
}

export let ai = new TelemetryService();
