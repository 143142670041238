import _ from 'lodash';
import { tokenAuthApiInstance } from 'utils/auth';
import { Logger } from 'utils/logger';

import { PROXY_ACCESS_LIST } from './constants';

export type ProxyAccessListItem = {
  PatientID: string;
  FirstName: string;
  MiddleName?: string;
  LastName: string;
  Suffix?: string;
  DateOfBirth?: string;
  Gender?: string;
};

export type ProxyAccessListResponse = Array<ProxyAccessListItem>;

export const getProxyAccessList = async (): Promise<ProxyAccessListResponse> => {
  try {
    const response = await tokenAuthApiInstance.authAxios().get(PROXY_ACCESS_LIST);
    const { data } = response;
    const result = _.orderBy(data, ['FirstName', 'LastName'], 'asc');
    return result;
  } catch (error) {
    Logger.error(error);
    throw error;
  }
};
