import { tokenAuthApiInstance } from 'utils/auth';

import { SELF_SCHEDULE_LOCATIONS } from '../constants';

export type SelfScheduleLocation = {
  SchResourceID: string;
  SchResourceName: string;
  LocationID: string;
  LocationName: string;
};
export type SelfScheduleLocationResponse = SelfScheduleLocation[];

export const getSelfScheduleLocations = async (appointmentType: string): Promise<SelfScheduleLocationResponse> => {
  // appointment type can contain special characters
  const encodedApptType = encodeURIComponent(appointmentType);
  const response = await tokenAuthApiInstance.authAxios([encodedApptType]).get(SELF_SCHEDULE_LOCATIONS);
  return response.data;
};
