import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getUserProfilePhoto, UserProfilePhoto } from 'services/profile-photo.api';

export type State = ApiState<UserProfilePhoto, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchUserprofilePhoto = createAsyncThunk('get/profilePhoto', async () => await getUserProfilePhoto());

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: '',
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const userProfilePhotoSlice = createSlice({
  name: 'profilePhoto',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchUserprofilePhoto.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchUserprofilePhoto.fulfilled, (state, action: PayloadAction<UserProfilePhoto>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchUserprofilePhoto.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const userProfilePhotoSelector_ = (state: { profilePhoto: State }): State => state.profilePhoto;

export const profilePhoto_ = createSelector(userProfilePhotoSelector_, (state) => state.data);

export const userProfilePhotoSelectorIsLoading_ = createSelector(userProfilePhotoSelector_, (state) => state.isLoading);

export const userProfilePhotoHasSelected_ = createSelector(userProfilePhotoSelector_, (state) => state.hasLoaded);

export default userProfilePhotoSlice.reducer;
