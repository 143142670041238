import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button, Card, CardActions, CardContent, IconButton, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';

import Header from '../InnerHeader';
const CancelAppointment: FunctionComponent = () => {
  return (
    <div className="wrapper">
      <Header className="header" heading="Cancel Appointment" />
      <main className="body-wrapper">
        <Box display="flex" alignItems="center" justifyContent="center">
          <FontAwesomeIcon icon={['far', 'calendar-times']} className="ApptTypeIcon" size="5x" />
        </Box>
        <p>
          We are currently canceling Appointments only over the phone. <br />
          <br />
          Please click below to call your Providers office to cancel an appointment.
        </p>
        <br />
        <Card variant="outlined" className="cardBlock">
          <CardContent>
            <Box display="flex" alignItems="center">
              <Box>
                {' '}
                <FontAwesomeIcon icon="hospital" size="4x" className="HospitalIcon" />
              </Box>
              <Box className="padL10">
                <Typography variant="h5">Primary Care Location 1</Typography>
                <p className="mar0">
                  123 West St, Cary NC - 27519 <br />
                  Tel: (919) 111-2222
                </p>
              </Box>
            </Box>
          </CardContent>
          <CardActions className="card-footerbg-primary">
            <IconButton aria-label="call-primaryLoc">
              <FontAwesomeIcon icon={['fas', 'phone-alt']} size="xs" />
            </IconButton>
            <Button id="call-primaryLoc" size="small" fullWidth>
              Call
            </Button>
          </CardActions>
        </Card>
        <Card variant="outlined" className="cardBlock">
          <CardContent>
            <Box display="flex" alignItems="center">
              <Box>
                {' '}
                <FontAwesomeIcon icon="hospital" size="4x" className="HospitalIcon" />
              </Box>
              <Box className="padL10">
                <Typography variant="h5">Urgent Care Location 2</Typography>
                <p className="mar0">
                  ABC East St, Cary NC - 27519
                  <br />
                  Tel: (919) 333-4444
                </p>
              </Box>
            </Box>
          </CardContent>
          <CardActions className="card-footerbg-primary">
            <IconButton aria-label="call-secondaryLoc">
              <FontAwesomeIcon icon={['fas', 'phone-alt']} size="xs" />
            </IconButton>
            <Button id="call-secondaryLoc" size="small" fullWidth>
              Call
            </Button>
          </CardActions>
        </Card>
        <br />
        <Box className="pad20">
          <hr />
          <small>
            Self-scheduling of Appointments including rescheduling and cancellations will be added to MyHealth very
            soon. Please check back later.
          </small>
        </Box>
      </main>
    </div>
  );
};

export default CancelAppointment;
