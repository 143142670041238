import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { FormData, getFormData } from 'services';

export type State = ApiState<FormData | undefined, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchFormData = createAsyncThunk(
  'get/formData',
  async (Payload: Record<string, string>) => await getFormData({ ...Payload })
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const fetchFormDataSlice = createSlice({
  name: 'formData',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    clearFormStates: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFormData.pending, (state) => {
      state.isLoading = true;
      state.hasLoaded = false;
    });

    builder.addCase(fetchFormData.fulfilled, (state, action: PayloadAction<FormData | undefined>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchFormData.rejected, (state, action) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { formData: State }): State => state.formData;

export const formData_ = createSelector(stateSelector_, (state) => state.data);

export const formDataLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const formDataHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const { clearFormStates } = fetchFormDataSlice.actions;

export default fetchFormDataSlice.reducer;
