import { tokenAuthApiInstance } from 'utils/auth';

import { SCHEDULE_APPOINTMENT_TYPES } from '../constants';

export type ScheduleAppointmentTypesResponse = string[];

export const getScheduleAppointmentTypes = async (): Promise<ScheduleAppointmentTypesResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(SCHEDULE_APPOINTMENT_TYPES);
  return response.data;
};
