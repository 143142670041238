import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getRequestedAppointments, RequestAppointments } from 'services';

export type State = ApiState<RequestAppointments, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchRequestAppointmentDetails = createAsyncThunk(
  'get/requestAppointmentconfigs',
  async (configPayLoad: Record<string, string>) => await getRequestedAppointments({ ...configPayLoad })
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const requestAppointmentDetailsSlice = createSlice({
  name: 'requestAppointmentDetails',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchRequestAppointmentDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchRequestAppointmentDetails.fulfilled, (state, action: PayloadAction<RequestAppointments>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchRequestAppointmentDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { requestAppointmentDetails: State }): State => state.requestAppointmentDetails;

export const requestAppointmentDetails_ = createSelector(stateSelector_, (state) => state.data);

export default requestAppointmentDetailsSlice.reducer;
