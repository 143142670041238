import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { BillingDetailsResponse, getBillingDetails } from 'services';

export type State = ApiState<BillingDetailsResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchBillingDetails = createAsyncThunk(
  'get/billingDetails',
  async (phoenixPatientID: number) => await getBillingDetails(phoenixPatientID)
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const billingDetailsSlice = createSlice({
  name: 'billingDetails',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBillingDetails.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchBillingDetails.fulfilled, (state, action: PayloadAction<BillingDetailsResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchBillingDetails.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { billingDetails: State }): State => state.billingDetails;

export const billingDetails_ = createSelector(stateSelector_, (state) => state.data);

export default billingDetailsSlice.reducer;
