import { tokenAuthApiInstance } from 'utils/auth';

import { SELF_SCHEDULE_CALENDAR_ENDPOINT } from '../constants';

export type CalendarGetPayload = {
  schResourceID: string;
  locationID: string;
  appointmentType: string;
};

//fetch available calendar dates for self scheduling
export const getSelfScheduleCalendarDates = async (payload: CalendarGetPayload): Promise<string[]> => {
  const { schResourceID, locationID, appointmentType } = payload;
  // appointment type can contain special characters
  const encodedApptType = encodeURIComponent(appointmentType);
  const response = await tokenAuthApiInstance
    .authAxios([schResourceID, encodedApptType, locationID])
    .get(SELF_SCHEDULE_CALENDAR_ENDPOINT);
  return response.data;
};
