import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState, SchedulePayload } from 'models';
import { createScheduleAppointment } from 'services';

import { schedulePayloadInitialState } from './schedule-payload-initialState';

export type State = ApiState<SchedulePayload, SerializedError>;

export type SchedulePayloadAction = {
  data: SchedulePayload;
};

export const addScheduleAppointment = createAsyncThunk(
  'post/scheduleAppointment',
  async (payload: SchedulePayload) => await createScheduleAppointment(payload)
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: schedulePayloadInitialState,
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const schedileAppPayloadSlice = createSlice({
  name: 'scheduleAppPayload',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    resetScheduleAppPayload: () => {
      return initialState;
    },
    updateScheduleAppPayload: (state, action: PayloadAction<SchedulePayloadAction>) => {
      state.data = { ...state.data, ...action.payload.data };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(addScheduleAppointment.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addScheduleAppointment.fulfilled, (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.data = { ...state.data, AppointmentRequestID: action.payload };
    });

    builder.addCase(addScheduleAppointment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { scheduleAppPayload: State }): State => state.scheduleAppPayload;

export const scheduleAppPayload_ = createSelector(stateSelector_, (state) => {
  return state.data;
});

export const scheduleAppSaveHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);
export const scheduleAppSaveIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const { resetScheduleAppPayload, updateScheduleAppPayload } = schedileAppPayloadSlice.actions;
export default schedileAppPayloadSlice.reducer;
