import { SelfSchedulePayload } from 'models';
import { tokenAuthApiInstance } from 'utils/auth';

import { SCHEDULE_APPOINTMENT_ENDPOINT } from '../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const createSelfScheduleAppointment = async (scheduleAppPayload: SelfSchedulePayload): Promise<any> => {
  const response = await tokenAuthApiInstance.authAxios().post(SCHEDULE_APPOINTMENT_ENDPOINT, scheduleAppPayload);
  return response.data;
};
