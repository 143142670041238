import { tokenAuthApiInstance } from 'utils/auth';

import { EHR_CONFIGURATIONS_ENDPOINT } from '../constants';

export type EHRConfigurations = {
  ConfigID: string;
  ConfigName: string;
  ConfigValue: string;
};

export type EHRConfigurationsResponse = Array<EHRConfigurations>;

export const getEHRConfigurations = async (): Promise<EHRConfigurationsResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(EHR_CONFIGURATIONS_ENDPOINT);
  return response.data;
};
