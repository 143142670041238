import { tokenAuthApiInstance } from 'utils/auth';
import { tokenAuthApiInstanceWithToken } from 'utils/auth';

import { COUNTRIES_ENDPOINT, REQUEST_ACTIVATION_CODE_COUNTRIES_ENDPOINT } from '../constants';

export type CountriesObjType = {
  CountryCode: string;
  CountryName: string;
};

export type CountriesResponse = CountriesObjType[];

export const getCountries = async (): Promise<CountriesResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(COUNTRIES_ENDPOINT);
  return response.data;
};

export const getRequestActivationCountries = async (token: string): Promise<CountriesResponse> => {
  const response = await tokenAuthApiInstanceWithToken(token)
    .authAxios(undefined, 'v5')
    .get(REQUEST_ACTIVATION_CODE_COUNTRIES_ENDPOINT);
  return response.data;
};
