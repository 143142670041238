import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getPracticeWorkingHours, WorkingHoursResponse } from 'services';

export type State = ApiState<WorkingHoursResponse, SerializedError>;

export const fetchWorkingHours = createAsyncThunk('get/workinghours', async () => await getPracticeWorkingHours());

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

const workingHours = createSlice({
  name: 'workingHours',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchWorkingHours.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchWorkingHours.fulfilled, (state, action: PayloadAction<WorkingHoursResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchWorkingHours.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      // set this or it'll go into an infinite loop
      state.hasLoaded = true;
    });
  },
});

export const stateSelector_ = (state: { workingHours: State }): State => state.workingHours;

export const workingHours_ = createSelector(stateSelector_, (state) => state.data);

export const workingHoursAreLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const workingHoursHaveLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export default workingHours.reducer;
