import { TELEHEALTH_LINK_ENDPOINT } from 'services/constants';
import { tokenAuthApiInstance } from 'utils/auth';

export type TelehealthLinkResponse = {
  Name: string;
  TwilioRoomSid: string;
  PatientUrl: string;
  IsDisconnected: boolean;
};

export type TelehealthLinkPayload = {
  PatientName: string;
  PatientId: string;
  StartDate: string;
  EndDate: string;
  GroupID: string;
  LocationID: string;
  CreatedByUserName: string;
  IsReactTelehealthAppEnabled: string;
  user_identity: string;
  room_name: string;
  user_type: string;
  room_type: string;
  token: string;
  create_conversation: boolean;
  isGroupSession?: boolean;
  SessionData: TelehealthLinkResponse;
};

export const getTelehealthLink = async (payload: TelehealthLinkPayload): Promise<TelehealthLinkResponse> => {
  const { isGroupSession = false } = payload;
  const response = await tokenAuthApiInstance
    .authAxios([isGroupSession.toString()])
    .post(TELEHEALTH_LINK_ENDPOINT, payload);
  return response.data;
};
