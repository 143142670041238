import { tokenAuthApiInstance } from 'utils/auth';

import { LAB_RESULTS_CONFIG } from './constants';

export type LabResultsConfigResponse = {
  showView: string;
};

export const getLabResultsConfig = async (): Promise<LabResultsConfigResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(LAB_RESULTS_CONFIG);
  const { testResultItems } = response.data;
  return testResultItems;
};
