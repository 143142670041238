import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getVisitRecordsConfig, VisitRecordsConfigResponse } from 'services';

export type State = ApiState<VisitRecordsConfigResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchVisitRecordsConfig = createAsyncThunk(
  'get/visitRecordsConfig',
  async () => await getVisitRecordsConfig()
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const visitRecordsConfigSlice = createSlice({
  name: 'visitRecordsConfig',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchVisitRecordsConfig.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchVisitRecordsConfig.fulfilled, (state, action: PayloadAction<VisitRecordsConfigResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchVisitRecordsConfig.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { visitRecordsConfig: State }): State => state.visitRecordsConfig;

export const visitRecordsConfig_ = createSelector(stateSelector_, (state) => state.data);

export const visitRecordsConfigIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const visitRecordsConfigHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export default visitRecordsConfigSlice.reducer;
