import { library } from '@fortawesome/fontawesome-svg-core';
import { far } from '@fortawesome/free-regular-svg-icons';
import { faAddressCard, faCalendarAlt, faCalendarPlus, faClock, faUser } from '@fortawesome/free-regular-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Apartment, ExpandMore, Help, Home, Mail, PhoneIphone } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import componentStyles from 'ux/mobile/components/componentStyles';
library.add(far, faUser, faCalendarPlus, faClock, faCalendarAlt, faAddressCard);
library.add(fas, faMapMarkerAlt);
import Header from './../InnerHeader';
type Props = {
  className?: string;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-force-tabpanel-${index}`}
      aria-labelledby={`scrollable-force-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
function a11yProps(index: any) {
  return {
    id: `scrollable-force-tab-${index}`,
    'aria-controls': `scrollable-force-tabpanel-${index}`,
  };
}
const useStyles = makeStyles(componentStyles);
const ScheduleAppointment: FunctionComponent<Props> = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  /* eslint-disable @typescript-eslint/no-explicit-any */
  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => {
    setValue(newValue);
  };
  return (
    <div className={classes.wrapper}>
      <Header className="header" heading={t('ScheduleAppointments.title')} />
      <main className="contentWrapper">
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
            aria-label="Schedule Appointment"
          >
            <Tab icon={<FontAwesomeIcon icon="user" size="lg" />} {...a11yProps(0)} />
            <Tab icon={<FontAwesomeIcon icon="calendar-plus" size="lg" />} {...a11yProps(1)} />
            <Tab icon={<FontAwesomeIcon icon="map-marker-alt" size="lg" />} {...a11yProps(2)} />
            <Tab icon={<FontAwesomeIcon icon="clock" size="lg" />} {...a11yProps(3)} />
            <Tab icon={<FontAwesomeIcon icon="calendar-alt" size="lg" />} {...a11yProps(4)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <Typography variant="h6" className={classes.textCenter}>
            WHAT KIND OF APPOINTMENT ARE YOU SCHEDULING?
          </Typography>
          <Card className={classes.cardBlock} variant="outlined">
            <CardContent>Follow-Up or Return Visit</CardContent>
          </Card>
          <Card className={classes.cardBlock} variant="outlined">
            <CardContent>Student Health</CardContent>
          </Card>
          <Card className={classes.cardBlock} variant="outlined">
            <CardContent>Annual Physical</CardContent>
          </Card>
          <Card className={classes.cardBlock} variant="outlined">
            <CardContent>
              Primary Care Same Day <br />
              <small className={classes.infoMain}>For new injury, illness or problem</small>
            </CardContent>
          </Card>
          <Card className={classes.cardBlock} variant="outlined">
            <CardContent>Virtual Urgent care</CardContent>
          </Card>
          <Card className={classes.cardBlock} variant="outlined">
            <CardContent>
              Other Appointment Types <br />
              <small className={classes.infoMain}>Request appointment for a type of visit not listed in here.</small>
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography variant="h6" className={classes.textCenter}>
            A COUPLE OF QUESTIONS
          </Typography>
          <Box className={classes.QuestionsBlock}>
            <Box className={classes.Question}>
              <span>*</span> In the last month have you had any close contact with someone suspected or known to have
              Coronavirus/COVID 19?
            </Box>
            <Box display="flex" justifyContent="space-evenly">
              <Button variant="contained" color="primary">
                Yes
              </Button>
              <Button variant="contained" color="primary">
                No
              </Button>
            </Box>
          </Box>
          <Box className={classes.QuestionsBlock}>
            <Box className={classes.Question}>
              <span>*</span> In the last 14 days, have you had a COVID-19 test and or are under quarantine?
            </Box>
            <Box display="flex" justifyContent="space-evenly">
              <Button variant="contained" color="primary">
                Yes
              </Button>
              <Button variant="contained" color="primary">
                No
              </Button>
            </Box>
          </Box>
          <Box className={classes.QuestionsBlock}>
            <Box className={classes.Question}>
              <span>*</span> Do you have any symptoms of fu-like illness or upper respiratory illness?
              <br />
              <small className={classes.infoMain}>
                {' '}
                New onset in the last 10 days of any of the following symptoms: Runny nose, sore throat, cough,
                shortness of breath, severe headache, fever, muscle aches, fatigue, loss of taste or smell or any GI
                symptoms (nausea, vomiting or diarrhea)?
              </small>
            </Box>
            <Box display="flex" justifyContent="space-evenly">
              <Button variant="contained" color="primary">
                Yes
              </Button>
              <Button variant="contained" color="primary">
                No
              </Button>
            </Box>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Typography variant="h6" className={classes.textCenter}>
            WHICH LOCATIONS WORK FOR YOU?
          </Typography>
          <Card className={classes.cardBlock} variant="outlined">
            <CardContent>
              Any location <br />
              <small className={classes.infoMain}>Schedule at any available location.</small>
            </CardContent>
          </Card>
          <Card className={classes.cardBlock} variant="outlined">
            <CardContent>
              Schedule at any available location. <br />
              <small className={classes.infoMain}>Schedule at any available location.</small>
            </CardContent>
          </Card>
          <Card className={classes.cardBlock} variant="outlined">
            <CardContent>
              Primary care <br />
              <small className={classes.infoMain}>Cary NC, xxx</small>
            </CardContent>
          </Card>
          <br />
          <Card className={classes.cardBlockBg} variant="outlined">
            <CardContent>
              <Box display="flex" alignItems="center">
                <Box>
                  <Help color="primary" />
                </Box>
                <Box>
                  Looking for a specifc provider?
                  <br />
                  <small className={classes.infoMain}>
                    <Link href="#" color="secondary">
                      Select from available providers
                    </Link>
                  </small>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <Typography variant="h6" className={classes.textCenter}>
            WHAT TIME WORKS FOR YOU?
          </Typography>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="Search Options" id="Search_Options">
              <Typography>Search Options</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    id="date"
                    label="Start search on"
                    type="date"
                    defaultValue="2021-05-24"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box>
                    Times
                    <Button variant="contained" color="primary" fullWidth className={classes.marB2}>
                      All Available Times
                    </Button>
                    <Button variant="contained" color="primary" fullWidth>
                      Filter Items
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <br />
          <Card variant="outlined">
            <CardContent>
              <Box>
                <Box className={classes.marB1}>Monday December 7, 2021</Box>
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  <Button variant="contained" color="primary" className={classes.timeBtn}>
                    10:00 AM
                  </Button>
                  <Button variant="contained" color="primary" className={classes.timeBtn}>
                    11:00 AM
                  </Button>
                  <Button variant="contained" color="primary" className={classes.timeBtn}>
                    1:00 PM
                  </Button>
                  <Button variant="contained" color="primary" className={classes.timeBtn}>
                    2:00 PM
                  </Button>
                  <Button variant="contained" color="primary" className={classes.timeBtn}>
                    3:00 PM
                  </Button>
                  <Button variant="contained" color="primary" className={classes.timeBtn}>
                    4:00 PM
                  </Button>
                </Box>
                <hr />
              </Box>
              <Box>
                <Box className={classes.marB1}>Monday December 7, 2021</Box>
                <Box display="flex" flexDirection="row" flexWrap="wrap">
                  <Button variant="contained" color="primary" className={classes.timeBtn}>
                    10:00 AM
                  </Button>
                  <Button variant="contained" color="primary" className={classes.timeBtn}>
                    11:00 AM
                  </Button>
                  <Button variant="contained" color="primary" className={classes.timeBtn}>
                    1:00 PM
                  </Button>
                  <Button variant="contained" color="primary" className={classes.timeBtn}>
                    2:00 PM
                  </Button>
                  <Button variant="contained" color="primary" className={classes.timeBtn}>
                    3:00 PM
                  </Button>
                  <Button variant="contained" color="primary" className={classes.timeBtn}>
                    4:00 PM
                  </Button>
                </Box>
                <hr />
              </Box>
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <Typography variant="h6" className={classes.textCenter}>
            VERIFY YOUR PERSONAL INFORMATION
          </Typography>
          <Card variant="outlined">
            <CardContent>
              <Box display="flex">
                <Box>
                  Contact Information <br />
                  <small>xxxxxxxxxxxxxx</small>
                </Box>
                <IconButton aria-label="Home" edge="end">
                  <Home />
                </IconButton>
              </Box>
              <Box>
                <small>
                  Going somewhere for a while?{' '}
                  <Link href="#" color="secondary">
                    Add aTemporary Address
                  </Link>
                </small>{' '}
              </Box>
              <Box>
                <List>
                  <ListItem>
                    <ListItemIcon>
                      <Home fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">xxxxxxxxxxxxx</Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <PhoneIphone fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">xxxxxxxxxx</Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Apartment fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                      xxxxxxxxxxxxxxxxxxxxx
                    </Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Mail fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                      xxxxxxxxxx@gmail.com
                    </Typography>
                  </ListItem>
                </List>
              </Box>
              <Box>
                <Button variant="contained" color="primary" fullWidth>
                  Edit
                </Button>
              </Box>
            </CardContent>
          </Card>
          <br />
          <Card variant="outlined">
            <CardHeader
              title="Details About Me"
              action={
                <IconButton aria-label="settings">
                  <FontAwesomeIcon icon="address-card" />
                </IconButton>
              }
            ></CardHeader>
            <CardContent>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <label>Gender</label> <br />
                    <small>Male</small>
                  </Grid>
                  <Grid item xs={6}>
                    <label>Gender Identity</label> <br />
                    <small>Not Entered</small>
                  </Grid>
                  <Grid item xs={6}>
                    <label>Sex Assigned at Birth </label> <br />
                    <small>Not Entered</small>
                  </Grid>
                  <Grid item xs={6}>
                    <label>Sexual Orientation </label> <br />
                    <small>Not Entered</small>
                  </Grid>
                  <Grid item xs={6}>
                    <label>Marital Status</label> <br />
                    <small>Not Entered</small>
                  </Grid>
                  <Grid item xs={6}>
                    <label>Religion</label> <br />
                    <small>Not Entered</small>
                  </Grid>
                </Grid>
              </CardContent>
            </CardContent>
          </Card>
          <br />
          <Button variant="contained" color="primary" fullWidth>
            THIS INFORMATION IS CORRECT
          </Button>
        </TabPanel>
      </main>
    </div>
  );
};

export default ScheduleAppointment;
