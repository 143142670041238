import { createAsyncThunk, createSelector, createSlice, SerializedError } from '@reduxjs/toolkit';
import { ApiState, ECheckInQuestionnairePayload } from 'models';
import { resetAll } from 'reducers';
import { saveFormIOQuestionnaire } from 'services';

const PayloadInitialState = {
  PatientQuestionnaireTypes: [
    {
      PatientQuestionnaireID: '',
      QuestionnaireType: '',
      FormUrl: '',
      FormName: '',
      FormTitle: '',
      FormID: '',
      FormData: '',
      FormType: '',
      FormTemplateID: '',
    },
  ],
  QuestionnaireAnswers: [],
  ConsentForms: [],
  ConsentFormElements: [],
};

export type State = ApiState<ECheckInQuestionnairePayload, SerializedError>;

export type QuestionnaireFormIOPayloadAction = {
  data: ECheckInQuestionnairePayload;
};
/* eslint-disable @typescript-eslint/no-explicit-any */
export const saveFormIOQuestionnaireForms = createAsyncThunk(
  'post/saveFormIOQuestionnaireForms',
  async (savePayload: any) => {
    const { practiceCalendarID, isFormIO = true, payload } = savePayload;
    return await saveFormIOQuestionnaire(practiceCalendarID, isFormIO, payload);
  }
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: PayloadInitialState,
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const questionnaireFormIOPayloadSlice = createSlice({
  name: 'questionnaireFormIOPayload',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    resetQuestionnaireFormIOPayload: () => {
      return initialState;
    },
    // updateScheduleAppPayload: (state, action: PayloadAction<QuestionnaireFormIOPayloadAction>) => {
    //   state.data = { ...state.data, ...action.payload.data };
    // },
  },

  extraReducers: (builder) => {
    builder.addCase(saveFormIOQuestionnaireForms.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(saveFormIOQuestionnaireForms.fulfilled, (state) => {
      state.isLoading = false;
      state.hasLoaded = true;
    });

    builder.addCase(saveFormIOQuestionnaireForms.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { questionnaireFormIOPayload: State }): State => state.questionnaireFormIOPayload;

export const questionnaireFormIOPayload_ = createSelector(stateSelector_, (state) => state.data);
export const questionnaireFormIOPayloadSaveHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);
export const questionnaireFormIOPayloadSaveIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

//export const { resetScheduleAppPayload, updateScheduleAppPayload } = questionnaireFormIOPayloadSlice.actions;
export default questionnaireFormIOPayloadSlice.reducer;
