import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Call, NearMe } from '@material-ui/icons';
import React, { FunctionComponent } from 'react';

import Header from './../InnerHeader';
const AppointmentType: FunctionComponent = () => {
  const [open, setOpen] = React.useState(false);
  const [openChangeAppt, setOpenChangeAppt] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleClickOpenReSchedule = () => {
    setOpenChangeAppt(true);
  };
  const handleChangeApptClose = () => {
    setOpenChangeAppt(true);
  };
  return (
    <div className="wrapper">
      <Header className="header" heading="Appointments" />
      <main className="body-wrapper">
        <Box className="text-center">
          <Typography variant="h4" color="secondary">
            Office Visit
          </Typography>
          <span className="textPrimary">Thursday, June 3, 2021</span>
        </Box>
        <br />
        <Card variant="outlined">
          <CardContent>
            <Box display="flex" alignContent="center" justifyContent="space-between">
              <Box className="visitInfoTime">
                <Typography variant="h5">Arrive by 9:05 AM</Typography>
                <p>Starts at 09:25AM</p>
                <p>20 minutes</p>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                className="pad10"
                onClick={handleClickOpenReSchedule}
              >
                <FontAwesomeIcon icon={['far', 'calendar-alt']} className="iconSecondary" />
                <p className="text-center">Reschedule or cancel</p>
                <Dialog
                  open={openChangeAppt}
                  onClose={handleChangeApptClose}
                  aria-labelledby="change-appointment"
                  aria-describedby="change-appointment-description"
                >
                  <DialogTitle>
                    <Typography variant="h4" color="secondary">
                      Change Appointment
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <List>
                        <ListItem>
                          <ListItemText primary="Reschedule Appt" />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Cancel Appt" />
                        </ListItem>
                      </List>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                className="pad10"
                onClick={handleClickOpen}
              >
                <FontAwesomeIcon icon={['far', 'calendar-plus']} className="iconSecondary" />
                <p className="text-center">Add to Calendar</p>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="add-to-calendar"
                  aria-describedby="add-to-calendar-description"
                >
                  <DialogTitle>
                    <Typography variant="h4" color="secondary">
                      Add to Calendar
                    </Typography>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      <List>
                        <ListItem>
                          <ListItemText primary="Include Details" />
                        </ListItem>
                        <ListItem>
                          <ListItemText primary="Include Time Only" />
                        </ListItem>
                      </List>
                    </DialogContentText>
                  </DialogContent>
                </Dialog>
              </Box>
            </Box>
            <hr />
            <Box display="flex" alignContent="center" justifyContent="space-between">
              <Box>
                <Typography variant="h4">Wait List Available</Typography>
                <p className="textPrimary">Get notifed if an earlier appointment becomes available</p>
              </Box>
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" className="pad10">
                <FontAwesomeIcon icon={['far', 'clock']} className="iconSecondary" />
                <p className="text-center">Get on Wait List</p>
              </Box>
            </Box>
          </CardContent>
        </Card>
        <br />
        <Card>
          <CardContent>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box>
                <Typography variant="h5">
                  xxxxxxxxxxxxx <br /> xxxxxxxxxx, MD, <br /> xxxx Primary Care xxxx
                </Typography>
              </Box>
              <Box>
                <FontAwesomeIcon icon={['fas', 'user-circle']} className="iconSecondary" size="2x" />
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <Box>
                <Typography variant="h5">
                  3 xxxxxxxxxxxxxxxx Lane Stre 100
                  <br /> Cary NC 27519
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <NearMe color="secondary" />
                <small>Map</small>
              </Box>
            </Box>
            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography variant="h5">9 xxxxxxxxxx 00</Typography>
              </Box>
              <Box display="flex" flexDirection="column">
                <Call color="secondary" />
                <small>Call</small>
              </Box>
            </Box>
          </CardContent>
        </Card>
      </main>
    </div>
  );
};

export default AppointmentType;
