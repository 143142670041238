import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState, RequestActivationPayload } from 'models';
import { postSubmitActivationRequest } from 'services';

import { RequestActivationInitialState } from './requestActivationInitialState';

export type State = ApiState<Partial<RequestActivationPayload>, SerializedError>;

export type RequestSubmissionPayloadAction = {
  data: Partial<RequestActivationPayload> & Record<string, unknown>;
};

export const postRequestActivation = createAsyncThunk(
  'post/requestActivation',
  async (payload: Partial<RequestActivationPayload> & Record<string, unknown>) =>
    await postSubmitActivationRequest(payload)
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: RequestActivationInitialState,
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const requestActivationPayloadSlice = createSlice({
  name: 'requestActivationPayload',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    resetRequestActivationPayload: () => {
      return initialState;
    },
    updateRequestActivationPayload: (state, action: PayloadAction<RequestSubmissionPayloadAction>) => {
      state.data = { ...state.data, ...action.payload.data };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(postRequestActivation.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(postRequestActivation.fulfilled, (state, action: PayloadAction<boolean>) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.data = { ...state.data, IsRequestedSubmitted: action.payload };
    });

    builder.addCase(postRequestActivation.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { requestActivationPayload: State }): State => state.requestActivationPayload;

export const requestActivationPayload_ = createSelector(stateSelector_, (state) => {
  return state.data;
});

export const requestActivationSaveHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);
export const requestActivationSaveIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const { resetRequestActivationPayload, updateRequestActivationPayload } = requestActivationPayloadSlice.actions;
export default requestActivationPayloadSlice.reducer;
