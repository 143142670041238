import { tokenAuthApiInstance } from 'utils/auth';

import {
  GET_CCDA_XML_FILE_ENDPOINT,
  GET_DETAILED_VISIT_RECORD_ENDPOINT,
  VISIT_RECORD_SUMMARY_ENDPOINT,
} from '../constants';

export type VisitRecordSummary = {
  PatientEncounterID: string;
  SectionCode: number;
  SectionName: string;
  SectionText: string;
};

export const getVisitRecordSummary = async (patientEncounterID: string): Promise<VisitRecordSummary> => {
  const response = await tokenAuthApiInstance.authAxios().get(VISIT_RECORD_SUMMARY_ENDPOINT(patientEncounterID));
  return response.data;
};

export const getDetailedVisitRecord = async (isPDF: boolean): Promise<string> => {
  const response = await tokenAuthApiInstance.authAxios([isPDF.toString()]).get(GET_DETAILED_VISIT_RECORD_ENDPOINT);
  return response.data;
};

export const getCCDAXMLFileName = async (): Promise<string> => {
  const response = await tokenAuthApiInstance.authAxios().get(GET_CCDA_XML_FILE_ENDPOINT);
  return response.data;
};
