import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getProxyRequestConfig, ProxyRequestConfigType } from 'services';

export type ProxyRequestConfigState = ApiState<ProxyRequestConfigType, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchProxyRequestConfig = createAsyncThunk(
  'get/proxyRequestConfig',
  async (tempDef: string) => await getProxyRequestConfig(tempDef)
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: {},
  error: null,
} as ProxyRequestConfigState;

//https://redux-toolkit.js.org/api/createSlice
const proxyRequestConfigSlice = createSlice({
  name: 'proxyRequestConfig',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchProxyRequestConfig.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchProxyRequestConfig.fulfilled, (state, action: PayloadAction<ProxyRequestConfigType>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchProxyRequestConfig.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { proxyRequestConfig: ProxyRequestConfigState }): ProxyRequestConfigState =>
  state.proxyRequestConfig;

export const proxyRequestConfig_ = createSelector(stateSelector_, (state) => state.data);

export const proxyRequestConfigIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const proxyRequestConfigHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export default proxyRequestConfigSlice.reducer;
