import { FilterFieldOption } from 'components/common/form';
import { tokenAuthApiInstance } from 'utils/auth';

import { SEARCH_INSURANCE } from '../constants';

export type SearchInsuranceObjType = {
  InsurancePlanID: string;
  InsurancePlanName: string;
  InsuranceTypeID: string;
};

export type SearchInsuranceResponse = SearchInsuranceObjType[];

export const getInsurances = async (
  searchValue: string
): Promise<Array<FilterFieldOption & Record<string, unknown>>> => {
  const response = (await (await tokenAuthApiInstance.authAxios().get(SEARCH_INSURANCE(searchValue)))
    .data) as SearchInsuranceResponse;

  return response.map(({ InsurancePlanID, InsurancePlanName, InsuranceTypeID }) => ({
    id: InsurancePlanID,
    label: InsurancePlanName,
    InsuranceTypeID,
  }));
};
