import { tokenAuthApiInstance } from 'utils/auth';

import { careTeamProvider } from './careTeam-config';
import { CARE_TEAM_FOR_SEND_MESSAGE_ENDPOINT } from './constants';

export const getCareTeamForMessaging = async (Payload: Record<string, string>): Promise<careTeamProvider[]> => {
  const { careTeamProviders, messageCareTeam, memberIDs } = Payload;
  const response = await tokenAuthApiInstance
    .authAxios([careTeamProviders, messageCareTeam, memberIDs])
    .get(CARE_TEAM_FOR_SEND_MESSAGE_ENDPOINT);
  return response.data;
};
