export const RequestActivationInitialState = {
  ActivationCodeRequestID: '',
  PracticeID: '',
  FirstName: '',
  MiddleName: '',
  LastName: '',
  Gender: '',
  DateofBirth: '',
  CountryCode: '',
  CountryName: '',
  AddressLine1: '',
  AddressLine2: '',
  City: '',
  State: '',
  County: '',
  Zipcode: '',
  HomePhoneNumber: '',
  EmailAddress: '',
  VerifyEmailAddress: '',
  RequestStatus: '',
  IsDeleted: 0,
  UserID: '',
  IsRequestedSubmitted: false,
};
