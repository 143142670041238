import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { medicationsSummary, MedicationSummaryResponse } from 'services';

export type State = ApiState<MedicationSummaryResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchMedicationsSummary = createAsyncThunk(
  'get/medicationsSummary',
  async (isDiscontinued?: boolean) => await medicationsSummary(isDiscontinued)
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const medicationsSummarySlice = createSlice({
  name: 'medicationsSummary',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMedicationsSummary.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchMedicationsSummary.fulfilled, (state, action: PayloadAction<MedicationSummaryResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchMedicationsSummary.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { medicationsSummary: State }): State => state.medicationsSummary;

export const medicationSummaries_ = createSelector(stateSelector_, (state) => state.data);

export const summariesHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const summariesIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export default medicationsSummarySlice.reducer;
