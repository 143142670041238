/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { FilterFieldOption } from 'components/common/form';
import { ApiState } from 'models';
import { getReligions } from 'services';

export type State = ApiState<FilterFieldOption[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchReligions = createAsyncThunk('get/religions', async () => await getReligions());

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const religionsSlice = createSlice({
  name: 'religions',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReligions.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchReligions.fulfilled, (state, action: PayloadAction<string[]>) => {
      state.isLoading = false;
      state.data = action.payload.map((value) => ({ id: value, label: value }));
      state.hasLoaded = true;
    });

    builder.addCase(fetchReligions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { religions: State }): State => state.religions;

export const religions_ = createSelector(stateSelector_, (state) => state.data);

export const religionsIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const religionsHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export default religionsSlice.reducer;
