import { tokenAuthApiInstance } from 'utils/auth';
import { Logger } from 'utils/logger';

import { APPOINTMENTS_CONFIG_ENDPOINT } from './constants';
import { LanguageTextItem } from './schedule-wizard/schedule-appointment-configuration';

export type AppointmentItems = {
  appointmentItems: {
    arriveByInMins: number;
    numberOfFutureAppointments: number;
    numberOfPastAppointments: number;
    cancelAppointment: string;
    rescheduleAppointment: string;
    scheduleAppointmentInDays: number;
    newAppointment: string;
    showUpcomingAfterAppointmentsInMins: number;
    numberOfRequestedAppointments: number;
    cancelECheckedInAppointmentText: Array<LanguageTextItem>;
    rescheduleECheckedInAppointmentText: Array<LanguageTextItem>;
    numberOfSelfScheduledAppointments: number;
    numberOfApptsScheduledOnCalenderDay: number;
  };
};

export const getAppointmentConfigs = async (): Promise<AppointmentItems> => {
  try {
    const response = await tokenAuthApiInstance.authAxios().get(APPOINTMENTS_CONFIG_ENDPOINT);
    return response.data;
    /* eslint-disable @typescript-eslint/no-explicit-any */
  } catch (error) {
    if (error) {
      const message = `Got error in getting the Appointment configs ${JSON.parse(error.toString())}`;
      Logger.error(message);
    }
    throw error;
  }
};
