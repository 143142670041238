import { EventType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import _ from 'lodash';
import { JWTHelper } from 'utils';
import { b2cPolicies } from 'utils/b2c/policies';
import { Logger } from 'utils/logger';

export const isInteractionInProgress = (): boolean => {
  try {
    if (process.env.REACT_APP_CLIENT_ID) {
      const keyString = `msal.${process.env.REACT_APP_CLIENT_ID}.interaction.status`;
      const value = sessionStorage.getItem(keyString);
      if (value === 'interaction_in_progress') {
        return true;
      }
    }
    /* eslint-disable @typescript-eslint/no-explicit-any */
  } catch (err) {
    Logger.error(`isInteractionInProgress: ${JSON.stringify(err)}`);
  }
  return false;
};

// check if the access token has expired
export const hasTokenExpired = (accessToken: string): boolean => {
  if (!_.isEmpty(accessToken)) {
    const now = Math.round(new Date().getTime() / 1000);
    const jwt = JWTHelper.parse(accessToken);
    const { exp } = jwt;
    const expired = exp && exp < now ? true : false;
    return expired;
  }
  // if the accessToken is empty just return true
  return true;
};

export const isIE = (): boolean => {
  try {
    const { userAgentData } = window.navigator;
    // userAgentData only supported in Chrome, Edge, Opera, Chrome Android, Opera Android and https
    if (userAgentData) {
      if (userAgentData.platform === 'Windows') {
        if (!_.isEmpty(userAgentData.brands) && !_.isEmpty(userAgentData.brands[0].brand)) {
          /* eslint-disable @typescript-eslint/no-explicit-any */
          window.navigator.userAgentData.brands.forEach((b: any) => {
            const { brand } = b;
            // Check brand for Microsoft and browsers that are IE and Edge
            if (brand && brand.toLowerCase().includes('microsoft')) {
              return true;
            }
          });
        }
      }
    } else {
      const ua = window.navigator.userAgent;
      const msie = ua.indexOf('MSIE ') > -1;
      const msie11 = ua.indexOf('Trident/') > -1;
      const isEdge = ua.indexOf('Edge/') > -1;
      return msie || msie11 || isEdge;
    }
  } catch (error) {
    Logger.error(`Error in isIE() : ${JSON.stringify(error)}`);
  }
  return false;
};

export const AUTH_SCOPES = {
  OPENID: 'openid',
  OFFLINE_ACCESS: 'offline_access',
  PROFILE: 'profile',
  EMAIL: 'email',
  USERREAD: process.env.REACT_APP_CPX_AUTH_SCOPE_USERREAD || '',
  USERWRITE: process.env.REACT_APP_CPX_AUTH_SCOPE_USERWRITE || '',
};

export const AUTH_REQUESTS = {
  LOGIN: {
    scopes: [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE],
  },
  EMAIL: {
    scopes: [AUTH_SCOPES.EMAIL],
  },
  PROFILE_EDIT: {
    scopes: [AUTH_SCOPES.OPENID, AUTH_SCOPES.PROFILE],
  },
  TOKEN_REQUEST: {
    scopes: [AUTH_SCOPES.USERREAD, AUTH_SCOPES.USERWRITE],
  },
  REFRESH_TOKEN: {
    scopes: [process.env.REACT_APP_CLIENT_ID, AUTH_SCOPES.USERREAD, AUTH_SCOPES.USERWRITE],
  },
};

export const msalApp: PublicClientApplication = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || '',
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI,
    navigateToLoginRequestUrl: process.env.REACT_APP_NAVIGATE_TO_LOGIN_REQUEST_URL === 'true',
    knownAuthorities: [b2cPolicies.authorityDomain],
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: isIE(),
  },
  system: {
    navigateFrameWait: 0,
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            Logger.error(message);
            return;
          case LogLevel.Info:
            Logger.info(message);
            return;
          case LogLevel.Verbose:
            Logger.debug(message);
            return;
          case LogLevel.Warning:
            Logger.warn(message);
            return;
        }
      },
      piiLoggingEnabled: false,
    },
  },
});

/* eslint-disable @typescript-eslint/no-explicit-any */
msalApp.addEventCallback((event: any) => {
  // set the session storage flag that a redirect is in progress
  if (event.eventType === EventType.HANDLE_REDIRECT_START) {
    sessionStorage.setItem('StartRedirectLogin', 'true');
  }
  // set teh session storage flag that the redirect has finished
  if (event.eventType === EventType.HANDLE_REDIRECT_END) {
    sessionStorage.setItem('StartRedirectLogin', 'false');
  }
  // set active account after redirect
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
    const account = event.payload.account;
    msalApp.setActiveAccount(account);
  }
});

// Register Callbacks for Redirect flow
// This is called after the event of LOGIN_SUCCESS.  Need to set the active account again and
// reload the page.
msalApp
  .handleRedirectPromise()
  .then((response) => {
    if (response) {
      const active = msalApp.getAllAccounts();
      if (active.length > 0) {
        const firstAcct = active[0];
        const { homeAccountId } = firstAcct;
        // sign the user out if this account is coming from the password reset policy result
        if (homeAccountId && homeAccountId.toLowerCase().includes(b2cPolicies.names.forgotPassword.toLowerCase())) {
          msalApp.logoutRedirect();
          return;
        }
        // otherwise, set the active account and reload the page
        msalApp.setActiveAccount(active[0]);
        window.location.reload();
      }
    }
  })
  /* eslint-disable @typescript-eslint/no-explicit-any */
  .catch((error: any) => {
    if (error) {
      const { errorCode, errorMessage } = error;
      // if user cancels the 'forgot password' flow, then reload the page after the redirect happens
      if (errorCode === 'access_denied') {
        if (errorMessage.includes('AADB2C90091') || errorMessage.includes('AADB2C90118')) {
          Logger.info('User canceled password reset flow');
          window.location.reload();
        }
      } else {
        Logger.error(errorCode, errorMessage);
      }
    }
  });
