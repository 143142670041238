import { createSelector, createSlice } from '@reduxjs/toolkit';

export type State = { pendingRequests: number };

export const initialState = {
  pendingRequests: 0,
} as State;

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    addPendingRequest: (state) => {
      state.pendingRequests = ++state.pendingRequests;
    },
    removePendingRequests: (state) => {
      state.pendingRequests = state.pendingRequests > 0 ? --state.pendingRequests : 0;
    },
  },
});

export const stateSelector_ = (state: { loader: State }): State => state.loader;

export const isLoading_ = createSelector(stateSelector_, (state) => state.pendingRequests > 0);

export default loaderSlice.reducer;
