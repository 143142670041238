import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { ConsentFormResponse, getEHRConsentForms } from 'services';

export type State = ApiState<ConsentFormResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchEHRConsentForms = createAsyncThunk(
  'get/consentForms',
  async (patientFormTemplateIDs: string) => await getEHRConsentForms(patientFormTemplateIDs)
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const consentFormsSlice = createSlice({
  name: 'consentForms',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    clearConsentFormStates: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchEHRConsentForms.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchEHRConsentForms.fulfilled, (state, action: PayloadAction<ConsentFormResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchEHRConsentForms.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { consentForms: State }): State => state.consentForms;

export const consentFormsIsLoaded_ = createSelector(stateSelector_, (state) => state.isLoading);

export const consentFormsHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const consentForms_ = createSelector(stateSelector_, (state) => state.data);

export const { clearConsentFormStates } = consentFormsSlice.actions;

export default consentFormsSlice.reducer;
