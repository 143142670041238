import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from 'reducers';

export type ProxyRequestAccessType = {
  accessType: string;
};

export const initialState = {
  accessType: '',
} as ProxyRequestAccessType;

//https://redux-toolkit.js.org/api/createSlice
const proxyAccessTypeSlice = createSlice({
  name: 'proxyRequestAccessType',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    setAccessType: (state, action: PayloadAction<string>) => {
      state.accessType = action.payload as string;
    },
    clearAccessType: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { proxyRequestAccessType: ProxyRequestAccessType }): ProxyRequestAccessType =>
  state.proxyRequestAccessType;

export const proxyAccessType_ = createSelector(stateSelector_, (state) => state.accessType);

export const { setAccessType, clearAccessType } = proxyAccessTypeSlice.actions;

export default proxyAccessTypeSlice.reducer;
