/**
 * E2C application user flows and custom policies
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */

export type b2cPoliciesType = {
  names: {
    signUpSignIn: string;
    forgotPassword: string;
  };
  authorities: {
    signUpSignIn: {
      authority: string;
    };
    forgotPassword: {
      authority: string;
    };
  };
  authorityDomain: string;
};

export const b2cPolicies: b2cPoliciesType = {
  names: {
    signUpSignIn: process.env.REACT_APP_AUTHORITY_SIGNUP_SIGNIN_NAME || 'B2C_1_SignUpSignIn',
    forgotPassword: process.env.REACT_APP_AUTHORITY_RESET_CREDENTIALS_NAME || 'B2C_1_Reset_Password',
  },
  authorities: {
    signUpSignIn: {
      authority: process.env.REACT_APP_AUTHORITY_SIGNUP_SIGNIN || '',
    },
    forgotPassword: {
      authority: process.env.REACT_APP_AUTHORITY_RESET_CREDENTIALS || '',
    },
  },
  authorityDomain: process.env.REACT_APP_AUTHORITY_DOMAIN || '',
};
