import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { FilterFieldOption } from 'components/common/form';
import { ApiState } from 'models';
import { CountriesResponse, getCountries } from 'services';

export type State = ApiState<FilterFieldOption[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchCountries = createAsyncThunk('get/countries', async () => await getCountries());

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCountries.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCountries.fulfilled, (state, action: PayloadAction<CountriesResponse>) => {
      state.isLoading = false;
      state.data = action.payload.map(({ CountryCode, CountryName }) => ({ id: CountryCode, label: CountryName }));
      state.hasLoaded = true;
    });

    builder.addCase(fetchCountries.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const countrySelector_ = (state: { countries: State }): State => state.countries;

export const countries_ = createSelector(countrySelector_, (state) => state.data);

export const countriesIsLoading_ = createSelector(countrySelector_, (state) => state.isLoading);

export const countriesHasLoaded_ = createSelector(countrySelector_, (state) => state.hasLoaded);

export default countriesSlice.reducer;
