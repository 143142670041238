import { getUpcomingCancelReqAppointmentAPI } from 'services';
import { tokenAuthApiInstance } from 'utils/auth';

import {
  FUTURE_APPOINTMENTS_ENDPOINT,
  getAppointmentDetailsEndpoint,
  getCancelReqAppointmentAPI,
  getRequestedAppointmentsEndpoint,
  PAST_APPOINTMENTS_ENDPOINT,
  PRACTICE_LOCATIONS,
} from './constants';

export type UpcomingVisits = {
  CheckInTime: string;
  CheckOutTime: string;
  FromDate: string;
  LocationID: string;
  LocationName: string;
  PatientEmail: string;
  PatientID: string;
  PatientName: string;
  patientStatus: string;
  PatientPreferredContact: string;
  PatientStatus: string;
  PracticeCalendarID: string;
  PracticeID: string;
  ProviderID: string;
  ProviderName: string;
  ToDate: string;
  TypeOfVisit: string;
  AppointmentRequestID: string;
  FormName: string;
  submittedFormID: string;
  rowcount: number;
  CancelRequestStatus?: string;
  TelehealthLink?: string;
};
export type UpcomingCancelAppointment = {
  cancelType: string;
} & UpcomingVisits;

export type PastVisits = {
  CheckInTime: string;
  CheckOutTime: string;
  FromDate: string;
  LocationID: string;
  LocationName: string;
  PatientEmail: string;
  PatientID: string;
  PatientName: string;
  PatientPreferredContact: string;
  PatientStatus: string;
  PracticeCalendarID: string;
  PracticeID: string;
  ProviderID: string;
  ProviderName: string;
  patientStatus: string;
  ToDate: string;
  TypeOfVisit: string;
  rowcount: number;
};
export type AppointMentDetailsPage = {
  PastVisits: PastVisits[];
  UpcomingVisits: UpcomingVisits[];
};

export type scheduleAppointmentDetails = {
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  City: string;
  FaxNumber: string;
  IsActive: boolean;
  IsDeleted: boolean;
  LocationID: string;
  LocationName: string;
  PhoneNumber: string;
  State: string;
  Zipcode: string;
};

export type RequestAppointment = {
  AppointmentRequestID: string;
  PatientID: string;
  AppointmentType: string;
  LocationName: string;
  LocationID: string;
  PreferredDateTime1: string;
  PreferredDateTime2: string;
  PreferredDateTime3: string;
  FirstName: string;
  MiddleName: string;
  LastName: string;
  Suffix: string;
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  State: string;
  County: string;
  Zipcode: string;
  MobilePhone: string;
  HomePhone: string;
  EmailAddress: string;
  Gender: string;
  SexAssignedAtBirth: string;
  MaritalStatus: string;
  GenderIdentity: string;
  SexualOrientation: string;
  Religion: string;
  IsDeleted: boolean;
  RequestStatus: string;
  FormName: string;
  submittedFormID: string;
  rowcount: number;
  ReasonForRefused?: string;
};

export type AppointmentDetailsResponse = AppointMentDetailsPage;

export const getAppointmentDetails = async (
  configPayLoad: Record<string, string>
): Promise<AppointmentDetailsResponse> => {
  const {
    numberOfFutureAppointments,
    numberOfPastAppointments,
    showUpcomingAfterAppointmentsInMins,
    appointmentTypes,
  } = configPayLoad;
  // appointment types can contain special characters
  const encodedApptTypes = encodeURIComponent(appointmentTypes);

  const response = await tokenAuthApiInstance
    .authAxios()
    .get(
      getAppointmentDetailsEndpoint(
        numberOfPastAppointments,
        numberOfFutureAppointments,
        showUpcomingAfterAppointmentsInMins,
        encodedApptTypes
      )
    );
  return response.data;
};

export type ScheduleAppointmentDetailsResponse = Array<scheduleAppointmentDetails>;
export const getScheduleAppointmentDetailsByPhone = async (): Promise<ScheduleAppointmentDetailsResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(PRACTICE_LOCATIONS);
  return response.data;
};

export type ReScheduleAppointmentDetailsResponse = Array<scheduleAppointmentDetails>;

export const getReScheduleAppointmentDetailsByPhone = async (): Promise<ReScheduleAppointmentDetailsResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(PRACTICE_LOCATIONS);
  return response.data;
};
export type CancelScheduleAppointmentDetailsResponse = Array<scheduleAppointmentDetails>;

export const getCancelReScheduleAppointmentDetailsByPhone = async (): Promise<
  CancelScheduleAppointmentDetailsResponse
> => {
  const response = await tokenAuthApiInstance.authAxios().get(PRACTICE_LOCATIONS);
  return response.data;
};

export type RequestAppointments = Array<RequestAppointment>;
export const getRequestedAppointments = async (configPayLoad: Record<string, string>): Promise<RequestAppointments> => {
  const { rowsPerPage } = configPayLoad;
  const response = await tokenAuthApiInstance.authAxios([rowsPerPage]).get(getRequestedAppointmentsEndpoint);
  return response.data;
};

export const postReqAppointmentId = async (reqID: string): Promise<string> => {
  const response = await tokenAuthApiInstance.authAxios().post(getCancelReqAppointmentAPI(reqID));
  return `${response.status}`;
};
export const postReqCancelUpcomingAppointmentId = async (
  configPayLoad: Record<string, string | Record<string, string>>
): Promise<string> => {
  const { PracticeCalendarID, cancelType, payloadObj } = configPayLoad;
  const response = await tokenAuthApiInstance
    .authAxios()
    .post(getUpcomingCancelReqAppointmentAPI(PracticeCalendarID as string, cancelType as string), payloadObj || {});
  return `${response.status}`;
};

//to fetch past appointments
export const getPastAppointments = async (configPayLoad: Record<string, string>): Promise<PastVisits[]> => {
  const { minutes, rowsPerPage } = configPayLoad;
  const response = await tokenAuthApiInstance.authAxios([minutes, rowsPerPage]).get(PAST_APPOINTMENTS_ENDPOINT);
  return response.data;
};

//to fetch future appointments
export const getFutureAppointments = async (configPayLoad: Record<string, string>): Promise<UpcomingVisits[]> => {
  const { minutes, rowsPerPage } = configPayLoad;
  const response = await tokenAuthApiInstance.authAxios([minutes, rowsPerPage]).get(FUTURE_APPOINTMENTS_ENDPOINT);
  return response.data;
};
