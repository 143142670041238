import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import _ from 'lodash';
import { ApiState } from 'models';
import { Gender } from 'models/dropdowns';
import { resetAll } from 'reducers';
import { getPersonalInfo, getPersonalInfoWithPracticeCalendarId, PersonalInfo } from 'services';

export type State = ApiState<PersonalInfo, SerializedError>;

export type PersonalInfoStep = {
  eCheckinMobilePhone: string;
  eCheckinEmailAddress: string;
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  County: string;
  EmailAddress: string;
  FirstName: string;
  Gender: keyof typeof Gender;
  GenderID?: string;
  GenderIdentity: string;
  GenderIdentityID: number;
  HomePhoneNumber: string;
  LastName: string;
  MiddleName: string;
  MaritalStatus: string;
  MaritalStatusID: number;
  MobileNumber: string;
  Religion: string;
  SendSMSNotification: boolean;
  SendEmailNotification: boolean;
  SexAssignedAtBirth: string;
  SexualOrientation: string;
  SexualOrientationID: number;
  Suffix: string;
  State: string;
  Zipcode: string;
  UserID: string;
};

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchPersonalInfo = createAsyncThunk('get/personalInfo', async () => await getPersonalInfo());

export const fetchPersonalInfoWithPracticeCalendarId = createAsyncThunk(
  'get/personalInfo',
  async (practiceCalendarId: string) => await getPersonalInfoWithPracticeCalendarId(practiceCalendarId)
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const personalInfoSlice = createSlice({
  name: 'personalInfo',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    umountPersonalInfo: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPersonalInfo.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchPersonalInfo.fulfilled, (state, action: PayloadAction<PersonalInfo>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchPersonalInfo.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { personalInfo: State }): State => state.personalInfo;

export const personalInfo_ = createSelector(stateSelector_, (state) => state?.data);

export const personalInfoIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const personalInfoHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const personalInfoStep_ = createSelector(stateSelector_, (state): PersonalInfoStep | undefined => {
  if (!_.isEmpty(state.data)) {
    const {
      AddressLine1,
      AddressLine2,
      City,
      County,
      EmailAddress,
      FirstName,
      Gender,
      GenderID,
      GenderIdentity,
      GenderIdentityID,
      HomePhoneNumber,
      LastName,
      MiddleName,
      MaritalStatus,
      MaritalStatusID,
      MobileNumber,
      Religion,
      SendSMSNotification = false,
      SendEmailNotification = false,
      SexAssignedAtBirth,
      SexualOrientation,
      SexualOrientationID,
      State,
      Suffix,
      Zipcode,
      UserID,
    } = state.data;
    return {
      eCheckinMobilePhone: MobileNumber,
      eCheckinEmailAddress: EmailAddress,
      AddressLine1,
      AddressLine2,
      City,
      County,
      EmailAddress,
      FirstName,
      Gender,
      GenderID,
      GenderIdentity,
      GenderIdentityID,
      HomePhoneNumber,
      LastName,
      MiddleName,
      MaritalStatus,
      MaritalStatusID,
      MobileNumber,
      Religion,
      SendSMSNotification,
      SendEmailNotification,
      SexAssignedAtBirth,
      SexualOrientation,
      SexualOrientationID,
      State,
      Suffix,
      Zipcode,
      UserID,
    };
  }
  return undefined;
});

export const { umountPersonalInfo } = personalInfoSlice.actions;

export default personalInfoSlice.reducer;
