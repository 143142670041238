import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { FormIOLanguageResource, getFormIOTranslations } from 'services';

export type State = ApiState<FormIOLanguageResource | undefined, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchFormIOTranslations = createAsyncThunk(
  'get/formIOTranslations',
  async (language: string) => await getFormIOTranslations(language)
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const formIOTranslationsSlice = createSlice({
  name: 'formIOTranslations',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFormIOTranslations.pending, (state) => {
      state.isLoading = true;
      state.hasLoaded = false;
    });

    builder.addCase(
      fetchFormIOTranslations.fulfilled,
      (state, action: PayloadAction<FormIOLanguageResource | undefined>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.hasLoaded = true;
      }
    );

    builder.addCase(fetchFormIOTranslations.rejected, (state, action) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { formIOTranslations: State }): State => state.formIOTranslations;

export const formIOTranslations_ = createSelector(stateSelector_, (state) => state.data);

export const formIOTranslationsIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const formIOTranslationsHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export default formIOTranslationsSlice.reducer;
