import { tokenAuthApiInstance } from 'utils/auth';

import { MEDICATION_DETAILS } from '../constants';

export type MedicationDetails = {
  DispenseDate: string;
  DispensedByName: string;
  ExpirationDate: string;
  LotNumber: string;
  MedicationInventoryLogDispenseID: string;
  PrescriptionNumber: number;
  QuantityDispensed: number;
};

export type MedicationDetailsResponse = MedicationDetails[];

export const medicationsDetails = async (patientMedicationID: string): Promise<MedicationDetailsResponse> => {
  const response = await tokenAuthApiInstance.authAxios([patientMedicationID]).get(MEDICATION_DETAILS);
  return response.data;
};
