import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getKioskHealthDepartmentLogo, HealthLogo } from 'services/Kiosk/kioskHealthDepartmentLogo.api';

export type State = ApiState<HealthLogo, SerializedError>;

export const fetchKioskHealthDeptLogo = createAsyncThunk(
  'get/kioskHealthDeptLogo',
  async () => await getKioskHealthDepartmentLogo()
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: '',
  error: null,
} as State;

const kioskHealthDeptLogo = createSlice({
  name: 'kioskHealthDeptLogo',
  initialState,
  //inner is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchKioskHealthDeptLogo.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchKioskHealthDeptLogo.fulfilled, (state, action: PayloadAction<HealthLogo>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });
    builder.addCase(fetchKioskHealthDeptLogo.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { kioskHealthDeptLogo: State }): State => state.kioskHealthDeptLogo;

export const kioskHealthDeptLogo_ = createSelector(stateSelector_, (state) => state.data);

export default kioskHealthDeptLogo.reducer;
