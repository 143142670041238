import { tokenAuthApiInstance } from 'utils/auth';

import { APPOINTMENT_BOOKING_SLOTS_ENDPOINT } from '../constants';

export type AppointmentBookingSlotPayload = {
  payload: {
    AppointmentBookingSlotID: string;
    SchResourceID: string;
    SchResourceName: string;
    TypeOfVisit: string;
    LocationID: string;
    LocationName: string;
    FromDate: string;
    ToDate: string;
    BookingStatus: string;
    IsDeleted: boolean;
  };
  userID: string;
};

export const saveAppointmentBookingSlot = async (
  bookingSlotPayload: AppointmentBookingSlotPayload
): Promise<string> => {
  const { userID, payload } = bookingSlotPayload;
  const response = await tokenAuthApiInstance.authAxios([userID]).post(APPOINTMENT_BOOKING_SLOTS_ENDPOINT, payload);
  return response.data;
};
