import { tokenAuthApiInstance } from 'utils/auth';
import { tokenAuthApiInstanceWithToken } from 'utils/auth';

import { COUNTY, REQUEST_ACTIVATION_CODE_COUNTY_ENDPOINT } from '../constants';

export type CountiesObj = {
  City: string;
  CountiesID: string;
  County: string;
  CountyCode: string;
  StateCode: string;
  ZipCode: string;
};

export type CountyPayload = {
  state: string;
  token: string;
};

export type CountiesResponse = CountiesObj[];

export const getCounties = async (value: string): Promise<CountiesResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(COUNTY(value));
  return response.data;
};

export const getRequestActivationCounties = async (payload: CountyPayload): Promise<CountiesResponse> => {
  const { token, state } = payload;
  const response = await tokenAuthApiInstanceWithToken(token)
    .authAxios(undefined, 'v5')
    .get(REQUEST_ACTIVATION_CODE_COUNTY_ENDPOINT(state));
  return response.data;
};
