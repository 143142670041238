import { tokenAuthApiInstance } from 'utils/auth';
import { Logger } from 'utils/logger';

import { PRACTICE_HEALTH_SUMMARY_CONFIG_ENDPOINT } from './constants';

export type HealthSummaryConfigType = {
  immunizationView: string;
  displayHealthIssues: string[];
};

export type HealthSummaryConfigResponse = HealthSummaryConfigType;

export const getHealthSummaryConfig = async (): Promise<HealthSummaryConfigResponse> => {
  try {
    const response = await tokenAuthApiInstance.authAxios().get(PRACTICE_HEALTH_SUMMARY_CONFIG_ENDPOINT);
    const { healthSummaryItems } = response.data;
    // add id field for data-grid
    return healthSummaryItems;
  } catch (error) {
    Logger.error(error);
    throw error;
  }
};
