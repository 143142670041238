import { tokenAuthApiInstance } from 'utils/auth';

import { MESSAGE_CONFIG } from './constants';

export type MessageConfigResponse = {
  sendTo: string;
  memberIDs: Array<string>;
};

export const getMessageConfigItems = async (): Promise<MessageConfigResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(MESSAGE_CONFIG);
  const { messageItems } = response.data;
  return messageItems;
};
