import { tokenAuthApiInstance } from 'utils/auth';

import { USER_PROFILE_PHOTO } from './constants';

export type UserProfilePhoto = string;

export const getUserProfilePhoto = async (): Promise<UserProfilePhoto> => {
  const response = await tokenAuthApiInstance.authAxios().get(USER_PROFILE_PHOTO);
  return response.data;
};
