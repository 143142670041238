import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getMessageConfigItems, MessageConfigResponse } from 'services';

export type State = ApiState<MessageConfigResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchMessageItemsConfig = createAsyncThunk(
  'get/messageConfigItems',
  async () => await getMessageConfigItems()
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const messageConfigSlice = createSlice({
  name: 'messageItemsConfig',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMessageItemsConfig.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchMessageItemsConfig.fulfilled, (state, action: PayloadAction<MessageConfigResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchMessageItemsConfig.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const messageConfigSelctor = (state: { messageItemsConfig: State }): State => state.messageItemsConfig;

export const messsageItemsConfig_ = createSelector(messageConfigSelctor, (state) => state.data);

export default messageConfigSlice.reducer;
