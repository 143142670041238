import { tokenAuthApiInstance } from 'utils/auth';

import { SCHEDULE_LOCATIONS } from '../constants';

export type ScheduleLocation = {
  LocationID: string;
  LocationName: string;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  City: string;
  State: string;
  Zipcode: string;
  PhoneNumber: string;
  FaxNumber: string;
  IsActive: boolean;
  IsDeleted: boolean;
  SchResourceID?: string;
  SchResourceName?: string;
};
export type ScheduleLocationResponse = ScheduleLocation[];

export const anyLocation = {
  LocationID: '00000000-0000-0000-0000-000000000000',
  LocationName: 'Any Location',
  AddressLine1: '',
  AddressLine2: '',
  AddressLine3: '',
  City: '',
  State: '',
  Zipcode: '',
  PhoneNumber: '',
  FaxNumber: '',
  IsActive: false,
  IsDeleted: false,
};

export const getScheduleLocations = async (): Promise<ScheduleLocationResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(SCHEDULE_LOCATIONS);
  return [anyLocation, ...response.data];
};
