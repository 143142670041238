import { library } from '@fortawesome/fontawesome-svg-core';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  TextField,
  Typography,
} from '@material-ui/core';
import { Email, Home, PhoneIphone } from '@material-ui/icons';
import React from 'react';

library.add(faEdit);

/* eslint-disable @typescript-eslint/no-explicit-any */
export default function PersonalInfo(): any {
  return (
    <React.Fragment>
      <br />
      <Typography variant="h5" gutterBottom>
        How can we contact you for this visit?
      </Typography>
      <small>This contact information will only be used for this visit.</small>
      <Card className="cardBlock" variant="outlined">
        <CardHeader title="Visit Contact Information" className="card-header"></CardHeader>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                id="MobilePhone"
                name="MobilePhone"
                label="Mobile Phone"
                fullWidth
                autoComplete="phone"
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField id="Email" size="small" name="Email" label="Email" fullWidth autoComplete="email" />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox id="text-notify" color="primary" name="saveInfo" value="yes" className="checkbox-font" />
                }
                label="Send me text notifcations for this visit"
              />{' '}
              <br />
              <small>Carrier charges may apply</small>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Typography variant="h5" gutterBottom>
        Verify Your Personal Information
      </Typography>
      <Card className="cardBlock" variant="outlined">
        <CardHeader title="Personal Information" className="card-header"></CardHeader>
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <p>
                FirstName, LastName <br /> 123 Street, <br /> Cary, NC - 27513
              </p>
            </Grid>
            <Grid item xs={6}>
              <Box>
                <List className="contact-info">
                  <ListItem>
                    <ListItemIcon>
                      <Home fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">xxxxxxxxxxxxx</Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <PhoneIphone fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">xxxxxxxxxx</Typography>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Email fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit" noWrap>
                      xxxxxxxxxx@gmail.com
                    </Typography>
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className="card-footerbg-primary">
          <IconButton aria-label="personalinfo-edit">
            <FontAwesomeIcon icon="edit" className="iconFadeOut" size="xs" />
          </IconButton>
          <Button size="small" fullWidth className="iconFadeOut">
            Edit
          </Button>
        </CardActions>
      </Card>
      <Card className="cardBlock" variant="outlined">
        <CardHeader title="Details About Me" className="card-header"></CardHeader>
        <CardContent>
          <Grid container spacing={1} className="details">
            <Grid item xs={6}>
              <label>Legal Sex</label> <br />
              <span>Male</span>
            </Grid>
            <Grid item xs={6}>
              <label>Gender Identity</label> <br />
              <span>Not Entered</span>
            </Grid>
            <Grid item xs={6}>
              <label>Sex Assigned at Birth </label> <br />
              <span>Not Entered</span>
            </Grid>
            <Grid item xs={6}>
              <label>Sexual Orientation </label> <br />
              <span>Not Entered</span>
            </Grid>
            <Grid item xs={6}>
              <label>Marital Status</label> <br />
              <span>Not Entered</span>
            </Grid>
            <Grid item xs={6}>
              <label>Religion</label> <br />
              <span>Not Entered</span>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className="card-footerbg-primary">
          <IconButton aria-label="details-edit">
            <FontAwesomeIcon icon="edit" className="iconFadeOut" size="xs" />
          </IconButton>
          <Button size="small" fullWidth className="iconFadeOut">
            Edit
          </Button>
        </CardActions>
      </Card>
      <Box className="text-right">
        <FormControlLabel
          control={<Checkbox color="primary" name="saveInfo" value="yes" />}
          label="This information is correct"
        />
      </Box>
    </React.Fragment>
  );
}
