/* eslint-disable @typescript-eslint/no-unused-vars */
import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getScheduleQuestions, ScheduleQuestionField, ScheduleQuestionResponseObject } from 'services';

export type State = ApiState<ScheduleQuestionResponseObject, SerializedError>;
// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchScheduleQuestions = createAsyncThunk(
  'get/ScheduleQuestions',
  async (questionnaireTemplateIDs: string) => await getScheduleQuestions(questionnaireTemplateIDs)
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: {},
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const scheduleQuestionsSlice = createSlice({
  name: 'scheduleQuestions',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    unmountscheduleQuestions: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchScheduleQuestions.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      fetchScheduleQuestions.fulfilled,
      (state, action: PayloadAction<ScheduleQuestionResponseObject>) => {
        state.isLoading = false;
        state.data = action.payload;
        state.hasLoaded = true;
      }
    );

    builder.addCase(fetchScheduleQuestions.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { scheduleQuestions: State }): State => state.scheduleQuestions;

export const scheduleQuestions_ = createSelector(stateSelector_, (state) => state.data);

export const scheduleQuestionsOnQuestionName_ = createSelector(
  stateSelector_,
  (state): Record<string, Record<string, unknown>> =>
    state.data.questions.reduce(
      (accumulator, question) => ({ ...accumulator, [question.QuestionnaireTemplateQuestionID]: question }),
      {}
    )
);

export const scheduleQuestionsIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const scheduleQuestionsHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);

export const { unmountscheduleQuestions } = scheduleQuestionsSlice.actions;

export default scheduleQuestionsSlice.reducer;
