import { DocumentInfo } from 'services';
import { tokenAuthApiInstance } from 'utils/auth';

import { GET_SHARED_DOCUMENT_ENDPOINT, GET_SHARED_DOCUMENTS_LIST_ENDPOINT } from '../constants';

export type SharedDocument = {
  DocumentDate: string;
  DocumentFormat: string;
  DocumentScannedDate: string;
  PatientID: string;
  ScannedDocumentsID: string;
  ScannedDocumentName: string;
  rowcount: number;
  RowNumber: number;
};

export type SharedDocumentResponse = Array<SharedDocument>;

export const getSharedDocumentsList = async (rowsPerPage: string): Promise<SharedDocumentResponse> => {
  const response = await tokenAuthApiInstance.authAxios([rowsPerPage]).get(GET_SHARED_DOCUMENTS_LIST_ENDPOINT);
  return response.data;
};

export const getSharedDocument = async (payload: DocumentInfo): Promise<string> => {
  const response = await tokenAuthApiInstance.authAxios().post(GET_SHARED_DOCUMENT_ENDPOINT, payload);
  return response.data;
};
