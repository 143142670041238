import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { careTeamProvider, getCareTeamProviders } from 'services/careTeam-config';

export type State = ApiState<careTeamProvider[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchCareTeamAllProvider = createAsyncThunk(
  'get/careTeamItemsAllProviderDetails',
  async (payLoad: Record<string, string>) => await getCareTeamProviders({ ...payLoad })
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const careTeamAllProviderSlice = createSlice({
  name: 'careTeamAllProviderDetails',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchCareTeamAllProvider.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchCareTeamAllProvider.fulfilled, (state, action: PayloadAction<careTeamProvider[]>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchCareTeamAllProvider.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const careTeamProviderAllDetailsSelector_ = (state: { careTeamAllProviders: State }): State =>
  state.careTeamAllProviders;

export const careTeamAllProviderDetails_ = createSelector(careTeamProviderAllDetailsSelector_, (state) => state.data);
export const careTeamAllProviderDetailsHasLoaded_ = createSelector(
  careTeamProviderAllDetailsSelector_,
  (state) => state.hasLoaded
);
export const careTeamAllProviderDetailsIsLoading_ = createSelector(
  careTeamProviderAllDetailsSelector_,
  (state) => state.isLoading
);
export default careTeamAllProviderSlice.reducer;
