import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState, SelfSchedulePayload } from 'models';
import { createSelfScheduleAppointment } from 'services';

import { selfSchedulePayloadInitialState } from './schedule-payload-initialState';

export type State = ApiState<SelfSchedulePayload, SerializedError>;

export type SelfSchedulePayloadAction = {
  data: SelfSchedulePayload;
};

export const addSelfScheduleAppointment = createAsyncThunk(
  'post/selfScheduleAppointment',
  async (payload: SelfSchedulePayload) => await createSelfScheduleAppointment(payload)
);

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: selfSchedulePayloadInitialState,
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const selfScheduleAppPayloadSlice = createSlice({
  name: 'selfScheduleAppPayload',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {
    resetSelfScheduleAppPayload: () => {
      return initialState;
    },
    updateSelfScheduleAppPayload: (state, action: PayloadAction<SelfSchedulePayloadAction>) => {
      state.data = { ...state.data, ...action.payload.data };
    },
  },

  extraReducers: (builder) => {
    builder.addCase(addSelfScheduleAppointment.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(addSelfScheduleAppointment.fulfilled, (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.hasLoaded = true;
      state.data = { ...state.data, AppointmentBookingResponse: action.payload };
    });

    builder.addCase(addSelfScheduleAppointment.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { selfScheduleAppPayload: State }): State => state.selfScheduleAppPayload;

export const selfScheduleAppPayload_ = createSelector(stateSelector_, (state) => {
  return state.data;
});

export const selfScheduleAppSaveHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);
export const selfScheduleAppSaveIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export const { resetSelfScheduleAppPayload, updateSelfScheduleAppPayload } = selfScheduleAppPayloadSlice.actions;
export default selfScheduleAppPayloadSlice.reducer;
