import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { EHRFormResponse, getEHRConsentFormList } from 'services';

export type State = ApiState<EHRFormResponse, SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchEHRConsentFormList = createAsyncThunk(
  'get/consentFormList',
  async () => await getEHRConsentFormList()
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const consentFormListSlice = createSlice({
  name: 'consentFormList',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchEHRConsentFormList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchEHRConsentFormList.fulfilled, (state, action: PayloadAction<EHRFormResponse>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchEHRConsentFormList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const stateSelector_ = (state: { consentFormList: State }): State => state.consentFormList;

export const consentFormList_ = createSelector(stateSelector_, (state) => state.data);
export const consentFormListHasLoaded_ = createSelector(stateSelector_, (state) => state.hasLoaded);
export const consentFormListIsLoading_ = createSelector(stateSelector_, (state) => state.isLoading);

export default consentFormListSlice.reducer;
