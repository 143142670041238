import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { resetAll } from 'reducers';
import { getPastAppointments, PastVisits } from 'services';

export type State = ApiState<PastVisits[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchPastAppointments = createAsyncThunk(
  'get/pastappointments',
  async (configPayLoad: Record<string, string>) => await getPastAppointments({ ...configPayLoad })
);

export const initialState = {
  isLoading: false,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const pastAppointmentsSlice = createSlice({
  name: 'pastAppointments',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPastAppointments.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchPastAppointments.fulfilled, (state, action: PayloadAction<PastVisits[]>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchPastAppointments.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });

    builder.addCase(resetAll, () => initialState);
  },
});

export const stateSelector_ = (state: { pastAppointments: State }): State => state.pastAppointments;

export const pastAppointments_ = createSelector(stateSelector_, (state) => state.data);

export default pastAppointmentsSlice.reducer;
