import { createAsyncThunk, createSelector, createSlice, PayloadAction, SerializedError } from '@reduxjs/toolkit';
import { ApiState } from 'models';
import { getPracticesList, HealthDeptResponse } from 'services';

export type State = ApiState<HealthDeptResponse[], SerializedError>;

// https://redux-toolkit.js.org/api/createAsyncThunk
export const fetchPracticesList = createAsyncThunk('get/practices', async () => await getPracticesList());

export const initialState = {
  isLoading: true,
  hasLoaded: false,
  data: [],
  error: null,
} as State;

//https://redux-toolkit.js.org/api/createSlice
const practicesListSlice = createSlice({
  name: 'practices',
  initialState,
  //immer is behind the scenes to keep state immutable only the props you changes will be changed
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPracticesList.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(fetchPracticesList.fulfilled, (state, action: PayloadAction<HealthDeptResponse[]>) => {
      state.isLoading = false;
      state.data = action.payload;
      state.hasLoaded = true;
    });

    builder.addCase(fetchPracticesList.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
    });
  },
});

export const practiceSelector_ = (state: { practices: State }): State => state.practices;

export const practicesList_ = createSelector(practiceSelector_, (state) => state.data);

export const practicesListIsLoading_ = createSelector(practiceSelector_, (state) => state.isLoading);

export const practicesListHasLoaded_ = createSelector(practiceSelector_, (state) => state.hasLoaded);

export default practicesListSlice.reducer;
