import { tokenAuthApiInstance } from 'utils/auth';

import {
  REQUEST_ACTIVATION_CODE_STATES_WITH_COUNTRYCODE_ENDPOINT,
  STATES_WITH_COUNTRYCODE_ENDPOINT,
} from '../constants';
import { StatesResponse } from './states.api';

export const getStatesWithCountryCode = async (country: string): Promise<StatesResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(STATES_WITH_COUNTRYCODE_ENDPOINT(country));
  return response.data;
};

export const requestActivationGetStatesWithCountryCode = async (country: string): Promise<StatesResponse> => {
  const response = await tokenAuthApiInstance
    .authAxios(undefined, 'v5')
    .get(REQUEST_ACTIVATION_CODE_STATES_WITH_COUNTRYCODE_ENDPOINT(country));
  return response.data;
};
