import './../cpx-billing.component.styles.scss';

import { Button, CircularProgress } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InnerHeader from 'components/common/InnerHeader/InnerHeader-component';
import { TokenContext } from 'context/tokenContext';
import queryString from 'query-string';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { BILLING_ROUTE, getTransactionDetails } from 'services';
import { Logger } from 'utils/logger';

export const TransactionStatus: FunctionComponent = () => {
  const { token } = useContext(TokenContext);
  const { t } = useTranslation();
  const history = useHistory();

  const [transactionStatus, setTransactionStatus] = useState<'Success' | 'Failed' | 'Pending'>();

  const redirectToBillingWidget = () => {
    history.push({ pathname: BILLING_ROUTE });
  };

  //call transaction details api to update the status of the transaction
  const fetchTransactionDetails = async (transactionID: number) => {
    try {
      const response = await getTransactionDetails(transactionID);
      if (response) {
        const { PaymentStatus } = response;
        if (PaymentStatus === 'C') {
          setTransactionStatus('Success');
        } else if (PaymentStatus === 'D') {
          setTransactionStatus('Failed');
        } else {
          setTransactionStatus('Pending');
        }
      }
    } catch (error) {
      Logger.error(`Error occured while fetching transaction details, Error message: ${error}`);
    }

    //after displaying the transaction status, navigate to billing widget
    setTimeout(redirectToBillingWidget, 5000);
  };

  useEffect(() => {
    if (token) {
      //get current url query params
      const queryParams = queryString.parse(window.location.search);
      //obtain the transactionID from the query string which is used to update the transaction status
      const transactionID = queryParams?.transactionID;
      if (transactionID) fetchTransactionDetails(+transactionID);
    }
  }, [token]);

  return (
    <div className="wrapper">
      <InnerHeader className="header" heading={t('billing.billing')} />
      <main>
        {!transactionStatus && (
          <div className="center">
            <CircularProgress />
          </div>
        )}
        {transactionStatus === 'Success' && (
          <div className="trans-status">
            <CheckCircleIcon className="success" />
            <h2>{t('billing.thankYou')}!</h2>
            <p className="success">{t('billing.paymentDoneSuccessfully')}</p>
            <small>{t('billing.youWillBeRedirectedToHomePage')}</small>
            <Button variant="contained" color="primary" onClick={redirectToBillingWidget}>
              {t('billing.home')}
            </Button>
          </div>
        )}
        {transactionStatus === 'Failed' && (
          <div className="trans-status">
            <CancelIcon className="error" />
            <h2>{t('billing.ohNo')}!</h2>
            <p>{t('billing.somethingWentWrong')}</p>
            <small className="error">
              {t('billing.weAreNotAbleToProcess')}. <br /> {t('billing.pleaseTryAgain')}.
            </small>
            <Button variant="outlined" color="primary" onClick={redirectToBillingWidget}>
              {t('billing.tryAgain')}
            </Button>
          </div>
        )}
      </main>
      <br />
      <br />
      <br />
    </div>
  );
};
