import { tokenAuthApiInstance } from 'utils/auth';

import {
  COMPOSE_MESSAGE_ENDPOINT,
  DETAILS_MESSAGES_ENDPOINT,
  INBOX_MESSAGES_ENDPOINT,
  UNREAD_MESSAGES_COUNT_ENDPOINT,
} from './constants';

export type messageResponse = {
  ThreadID: string;
  MessageID: string;
  ParentMessageID: string;
  RecieverID: string;
  ReceiverName: string;
  SenderID: string;
  SenderName: string;
  MessageDate: string;
  PracticeID: string;
  Subject: string;
  IsRead: number;
  IsInbox: number;
  rowcount: number;
  UsersCount: number;
};

export type messagePayload = {
  senderID: string;
  RecieverIDs?: string;
  Subject?: string;
  MessageBody: string;
  ThreadID?: string;
};
export type messageDetailsMessagesResponse = {
  ThreadID: string;
  MessageID: string;
  ParentMessageID: string;
  SenderID: string;
  SenderName: string;
  ReceiverName: string;
  RecieverID: string;
  MessageDate_DateTime: string;
  MessageDate: string;
  PracticeID: string;
  IsRead: number;
  IsDeleted: number;
  IsRecieverDeleted: number;
  IsSenderDeleted: number;
  InsertedBy: string;
  InsertDate: string;
  LastEditedBy: string;
  LastEditDate: string;
  PatientPortalThread: number;
  Subject: string;
  MessageBody: string;
  IsInbox: number;
  rowcount: number;
};
export type Receiver = {
  RecieverID: string;
  DisplayName: string;
  IsActive: boolean;
};
export type messageDetailsResponse = {
  RecieverIDs: string;
  RecieverNames: string;
  Messages: messageDetailsMessagesResponse[];
  Receivers: Array<Receiver>;
};

export const getInboxMessages = async (userID: string, rowsPerPage: number): Promise<messageResponse[]> => {
  const response = await tokenAuthApiInstance.authAxios().get(INBOX_MESSAGES_ENDPOINT(userID, rowsPerPage));
  return response.data;
};

export const sendMessage = async (payload: Partial<messagePayload> & Record<string, unknown>): Promise<string[]> => {
  const response = await tokenAuthApiInstance.authAxios().post(COMPOSE_MESSAGE_ENDPOINT, payload);
  return response.data;
};

export const getDetailedMessage = async (query: string): Promise<messageDetailsResponse> => {
  const response = await tokenAuthApiInstance.authAxios().get(DETAILS_MESSAGES_ENDPOINT(query));
  return response.data;
};
export const getUnreadMessagesCount = async (userID: string): Promise<number> => {
  const response = await tokenAuthApiInstance.authAxios().get(UNREAD_MESSAGES_COUNT_ENDPOINT(userID));
  return response.data;
};
