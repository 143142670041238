import { tokenAuthApiInstance } from 'utils/auth';

import { SELF_SCHEDULE_SLOTS_ENDPOINT } from '../constants';

export type SlotsGetPayload = {
  schResourceID: string;
  locationID: string;
  appointmentType: string;
  appointmentDate: string;
};

//fetch available slots for self scheduling
export const getSelfScheduleAvailableSlots = async (payload: SlotsGetPayload): Promise<string[]> => {
  const { schResourceID, locationID, appointmentType, appointmentDate } = payload;
  // appointment type can contain special characters
  const encodedApptType = encodeURIComponent(appointmentType);
  const response = await tokenAuthApiInstance
    .authAxios([schResourceID, locationID, encodedApptType, appointmentDate])
    .get(SELF_SCHEDULE_SLOTS_ENDPOINT);
  return response.data;
};
