import { tokenAuthApiInstance } from 'utils/auth';

import { APPOINTMENTS_CREATED_IN_CALENDAR_DAY_ENDPOINT, APPOINTMENTS_CREATED_IN_ONE_DAY_ENDPOINT } from '../constants';

//to fetch number of appointments created in one day
export const getNumOfApptsCreatedInOneDay = async (patientUserID: string): Promise<number> => {
  const response = await tokenAuthApiInstance.authAxios([patientUserID]).get(APPOINTMENTS_CREATED_IN_ONE_DAY_ENDPOINT);
  return response.data;
};

//to fetch number of appointments created in calendar day
export const getNumOfApptsCreatedInCalendarDay = async (appointmentDate: string): Promise<number> => {
  const response = await tokenAuthApiInstance
    .authAxios([appointmentDate])
    .get(APPOINTMENTS_CREATED_IN_CALENDAR_DAY_ENDPOINT);
  return response.data;
};
