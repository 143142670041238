import { createSelector, createSlice } from '@reduxjs/toolkit';

export type tokenState = { expiredToken: boolean };

export const initialState = {
  expiredToken: false,
} as tokenState;

// this is to set the token expiration state
export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setTokenHasExpired: (state) => {
      state.expiredToken = true;
    },
    resetExpireState: (state) => {
      state.expiredToken = false;
    },
  },
});

export const stateSelector_ = (state: { tokenStatus: tokenState }): tokenState => state.tokenStatus;

export const hasTokenExpired_ = createSelector(stateSelector_, (state) => state.expiredToken);

export const { setTokenHasExpired, resetExpireState } = tokenSlice.actions;

export default tokenSlice.reducer;
